import { combineReducers } from 'redux';
import session from './session';
import globalErrorMessage from './error';

const reducers = combineReducers({
	session,
	globalErrorMessage,
});

export default reducers;
