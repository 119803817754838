import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import '../../App.css';
import i18n from '../../services/i18n';
import SettingsService from '../../services/SettingsService';
import FortnoxToken from './FortnoxToken';
import './Settings.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

class FortnoxSettings extends React.Component {
	_isMounted = false;
	_mustHaveScopes = ['companyinformation'];

	constructor(props) {
		super(props);
		this.state = {
			settings: this.props.settings,
			scopes: this.props.scopes,
			selectedScopes: this._mustHaveScopes,
			fortnoxAuthCode: '',
			addFortnoxTokenIsOpen: false,
			editingFortnoxToken: false,
			fortnoxAccessTokens: [],
			counter: 0,
		};
		this._revokeFortnoxAccessToken = this._revokeFortnoxAccessToken.bind(this);
		this._getFortnoxAccessToken = this._getFortnoxAccessToken.bind(this);
		this._openAddFortnoxToken = this._openAddFortnoxToken.bind(this);
		this._closeAddFortnoxToken = this._closeAddFortnoxToken.bind(this);
		this._renderModalButtons = this._renderModalButtons.bind(this);
		this._renderScopes = this._renderScopes.bind(this);
		this._renderSelectAllNoneButtons =
			this._renderSelectAllNoneButtons.bind(this);
		this._selectAllScopes = this._selectAllScopes.bind(this);
		this._deselectAllScopes = this._deselectAllScopes.bind(this);
		this._handleChange = this._handleChange.bind(this);
		this._renderFortnoxTokens = this._renderFortnoxTokens.bind(this);
	}

	//TODO: Fråga här efter oauth flödet om nyckel ska sättas

	componentDidMount() {
		this._isMounted = true;
		const search = this.props.location.search;
		const fortnoxAuthCode = new URLSearchParams(search).get('code');
		const state = new URLSearchParams(search).get('state');
		const error = new URLSearchParams(search).get('error');
		const errorDescription = new URLSearchParams(search).get(
			'error_description'
		);

		if (fortnoxAuthCode) {
			SettingsService.createFortnoxToken(
				{ fortnoxAuthCode: fortnoxAuthCode, state: state },
				this.props.session
			)
				.then(() => {
					if (!this._isMounted) {
						return;
					}
					this.props.history.push('/settings'); // Remove query params. Might exist a better way to do this
					this._getFortnoxAccessToken();
				})
				.catch((err) => {
					if (err.message && err.message.error_description) {
						this.props.setGlobalErrorMessage(
							i18n.t('error_modal_fortnox_error'),
							err.message.error_description,
							'error'
						);
					}
					this.props.history.push('/settings');
					this._getFortnoxAccessToken();
				});
		} else if (error) {
			this.props.setGlobalErrorMessage(
				i18n.t('error_modal_fortnox_error'),
				errorDescription,
				'error'
			);
			this._getFortnoxAccessToken();
		} else {
			this._getFortnoxAccessToken();
		}

		this._selectAllScopes();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_getFortnoxAccessToken() {
		SettingsService.getFortnoxAccessTokens(this.props.session)
			.then((fortnoxAccessTokens) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					fortnoxAccessTokens: fortnoxAccessTokens,
					editingFortnoxToken: false,
				});
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_revokeFortnoxAccessToken(event) {
		event.preventDefault();
		let tokenId = event.currentTarget.dataset.id;
		SettingsService.revokeFortnoxToken(this.props.session, tokenId)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				// TODO: animate removal
				this._getFortnoxAccessToken();
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_deleting_resource'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_openAddFortnoxToken(event) {
		let newState = { addFortnoxTokenIsOpen: true };
		// If editing then select all the scopes that the selected token had
		if (event.target.dataset.id) {
			for (let i = 0; i < this.state.fortnoxAccessTokens.length; i++) {
				// eslint-disable-next-line
				if (this.state.fortnoxAccessTokens[i].id == event.target.dataset.id) {
					newState.selectedScopes = this.state.fortnoxAccessTokens[i].scope
						.split(' ')
						.concat(this._mustHaveScopes);
					newState.editingFortnoxToken = true;
				}
			}
		}
		this.setState(newState);
	}

	_closeAddFortnoxToken() {
		this._selectAllScopes();
		this.setState({ addFortnoxTokenIsOpen: false });
	}

	incrementFortnoxTokenCount() {
		this.setState({ counter: this.state.counter + 1 });
	}

	_handleChange(event) {
		let scope = event.target.dataset.name;
		let selectedScopes = this.state.selectedScopes;
		let index = 0;

		if (event.target.checked) {
			if (selectedScopes.indexOf(scope) === -1) {
				selectedScopes.push(scope);
			}
		} else if (!this._mustHaveScopes.includes(scope)) {
			index = selectedScopes.indexOf(scope);

			if (index !== -1) {
				selectedScopes.splice(index, 1);
			}
		}

		this.setState({
			selectedScopes: selectedScopes,
		});
	}

	_selectAllScopes() {
		let scopes = [];
		for (const value of Object.values(this.state.scopes)) {
			if (scopes.indexOf(value) === -1) {
				scopes.push(value);
			}
		}
		this.setState({
			selectedScopes: scopes,
		});
	}

	_deselectAllScopes() {
		this.setState({
			selectedScopes: JSON.parse(JSON.stringify(this._mustHaveScopes)),
		});
	}

	_renderScopes() {
		let scopes = [];
		for (const value of Object.values(this.state.scopes)) {
			if (scopes.indexOf(value) === -1) {
				scopes.push(value);
			}
		}
		scopes.sort();

		let list = [];
		for (let i = 0; i < scopes.length; i++) {
			list.push(
				<label key={i}>
					<input
						type="checkbox"
						disabled={this._mustHaveScopes.includes(scopes[i])}
						onChange={this._handleChange}
						checked={this.state.selectedScopes.indexOf(scopes[i]) !== -1}
						data-name={scopes[i]}
					/>
					{' ' + scopes[i].charAt(0).toUpperCase() + scopes[i].slice(1)}
				</label>
			);
		}
		return list;
	}

	_renderSelectAllNoneButtons() {
		let selectAllButton = (
			<div className="select-all-btn">
				<input
					className="btn btn-s m-1 gradiant"
					type="button"
					value={i18n.t('settings_fortnox_scope_select_all')}
					onClick={this._selectAllScopes}
				/>
			</div>
		);
		let deselectAllButton = (
			<div className="deselect-all-btn">
				<input
					className="btn btn-s m-1 gradiant"
					type="button"
					value={i18n.t('settings_fortnox_scope_deselect_all')}
					onClick={this._deselectAllScopes}
				/>
			</div>
		);
		return (
			<>
				{selectAllButton}
				{deselectAllButton}
			</>
		);
	}

	_renderModalButtons() {
		const url = new URL('https://apps.fortnox.se/oauth-v1/auth');
		let scopes = [...new Set(this.state.selectedScopes)];
		const filteredScopes = scopes.filter((value) =>
			Object.values(this.state.scopes).includes(value)
		);

		url.searchParams.append('client_id', this.state.settings.fortnoxClientId);
		url.searchParams.append(
			'redirect_uri',
			encodeURI(this.state.settings.fortnoxOauthUri)
		);
		url.searchParams.append('state', 'asd'); //TODO: get from node
		url.searchParams.append('access_type', 'offline');
		url.searchParams.append('response_type', 'code');
		url.searchParams.append('scope', filteredScopes.join(' '));

		return (
			<div className="add-fortnox-token-btn">
				<a
					className="btn btn-s m-1 gradiant"
					href={url.href}
					style={{ marginTop: 5 }}
					onClick={this.incrementFortnoxTokenCount}
				>
					{this.state.editingFortnoxToken
						? i18n.t('settings_fortnox_edit_token')
						: i18n.t('settings_fortnox_add_token')}
				</a>
			</div>
		);
	}

	_renderAddFortnoxToken() {
		return (
			<Modal
				show={this.state.addFortnoxTokenIsOpen}
				onHide={this._closeAddFortnoxToken}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.state.editingFortnoxToken
							? i18n.t('settings_fortnox_edit_token')
							: i18n.t('settings_fortnox_add_token')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<>
							<h3>{i18n.t('settings_fortnox_choose_scope')}</h3>
							<a
								target="_blank"
								href="https://developer.fortnox.se/general/scopes/"
								rel='noopener noreferrer'
							>
								{i18n.t('settings_fortnox_scope_url')}
							</a>
							<div className="scope-div">
								{this._renderSelectAllNoneButtons()}
								{this._renderScopes()}
							</div>
						</>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<>{this._renderModalButtons()}</>
				</Modal.Footer>
			</Modal>
		);
	}

	_renderFortnoxTokens() {
		return this.state.fortnoxAccessTokens.map((token) => {
			return (
				<FortnoxToken
					data-id={token.id}
					key={token.id}
					token={token}
					onDelete={this._revokeFortnoxAccessToken}
					onEdit={this._openAddFortnoxToken}
				/>
			);
		});
	}

	_renderAddCompanyButton() {
		if (
			this.props.session.customertype > 0 ||
			this.state.fortnoxAccessTokens.length === 0
		) {
			return (
				<div className="form-group">
					<input
						className="btn btn-s m-1 gradiant"
						type="button"
						onClick={this._openAddFortnoxToken}
						style={{ marginTop: 20, marginBottom: 10 }}
						value={i18n.t('settings_fortnox_add_company')}
					/>
				</div>
			);
		}
		return null;
	}

	render() {
		let stuff = this._renderFortnoxTokens();
		return (
			<>
				{this._renderAddFortnoxToken()}
				{this._renderAddCompanyButton()}
				{stuff}
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FortnoxSettings);
