import handleMessage from './MessageHandler';
import config from '../config';

const websocketService = {};

websocketService._ws = null;

websocketService.getWS = (session) => {
	const ws = websocketService._ws;

	if (!ws || ws.readyState === 3) {
		websocketService._ws = new WebSocket(
			`${config.wss.uri}?session=${session}`
		);
	}

	return websocketService._ws;
};

websocketService.reconnectAttempts = 0;

websocketService.connect = (session) => {
	const ws = websocketService.getWS(session);

	ws.onopen = () => {
		console.log('WebSocket connected.');
		// Reset reconnectAttempts on connection opening
		websocketService.reconnectAttempts = 0;
	};

	ws.onmessage = (message) => {
		handleMessage(message);
	};

	ws.onclose = () => {
		const baseDelay = 1000;
		let delay = 0;
		const attempts = websocketService.reconnectAttempts;

		if (!attempts) {
			delay = baseDelay;
		} else if (attempts < 5) {
			delay = baseDelay * (attempts + 1);
		} else if (attempts > 4 && attempts < 14) {
			delay = baseDelay * (attempts - 1) * 5;
		} else {
			delay = 1000 * 60 * 5;
		}

		console.log(
			`WebSocket connection closed. Reconnecting in ${delay / 1000} second(s).`
		);
		setTimeout(function () {
			websocketService.reconnectAttempts += 1;
			websocketService.connect(session);
		}, delay);
	};
};

export default websocketService;
