import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import TeamService from '../../services/TeamService';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import { Modal } from 'react-bootstrap';

class InviteToast extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			getInvites: [],
			showToast: false,
		};
		this._openToast = this._openToast.bind(this);
		this._closeToast = this._closeToast.bind(this);
		this._acceptInvite = this._acceptInvite.bind(this);
		this._declineInvite = this._declineInvite.bind(this);
		this._whenShouldInvitesShow = this._whenShouldInvitesShow.bind(this);
		this._clickAcceptBtn = this._clickAcceptBtn.bind(this);
		this._clickDeclineBtn = this._clickDeclineBtn.bind(this);
		this._toastRenderMoreThanOneInvite =
			this._toastRenderMoreThanOneInvite.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		let that = this;

		if (this.props.session.length !== 0) {
			that._fetchUserInvites();
		}
		setTimeout(function () {
			that._whenShouldInvitesShow();
		}, 2000);
	}

	_whenShouldInvitesShow() {
		const { getInvites } = this.state;
		getInvites.forEach((inv) => {
			if (inv.invite === 1 && inv.ownerid !== this.props.session.userid) {
				this.setState({ showToast: true });
				this._renderInviteToast();
			} else {
				this.setState({ showToast: false });
			}
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_openToast() {
		this.setState({ showToast: true });
	}

	_closeToast() {
		this.setState({ showToast: false });
	}

	_fetchUserInvites() {
		let userid = this.props.session.userid;
		if (userid) {
			TeamService.getUserInvites(userid, this.props.session)
				.then((dataFromResponse) => {
					if (!this._isMounted) {
						return;
					}
					this.setState({
						isLoaded: true,
						getInvites: dataFromResponse,
					});
				})
				.catch((err) => {
					alert(err);
				});
		}
	}

	_declineInvite(teamid) {
		//when you decline an invite it deletes it
		TeamService.declineUserInvite(teamid, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
			})
			.catch((err) => {});
	}

	_acceptInvite(teamid) {
		//when you accept user inv, you change invitation flag to 0 on the team you accepted
		TeamService.acceptUserInvite(teamid, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
			})
			.catch((err) => {});
	}

	_toastRenderMoreThanOneInvite() {
		let getInvites = this.state.getInvites;
		let that = this;
		if (getInvites.length > 1) {
			this.setState({ showToast: true });
			this._renderInviteToast();
			//TODO: Make this fetch a promise '.then()'
			setTimeout(function () {
				that._fetchUserInvites();
			}, 300);
		} else {
			this.setState({ showToast: false });
		}
	}

	_clickAcceptBtn(event) {
		event.preventDefault();
		let that = this;
		let teamid = event.currentTarget.dataset.id;
		that._acceptInvite(teamid);
		//If there is another team to accept, continue showing toast
		this._toastRenderMoreThanOneInvite();
	}

	_clickDeclineBtn(event) {
		event.preventDefault();
		let that = this;
		let teamid = event.currentTarget.dataset.id;
		that._declineInvite(teamid);

		//If there is another team to accept, continue showing toast
		this._toastRenderMoreThanOneInvite();
	}

	_renderInviteToast() {
		return (
			<Modal show={this.state.showToast} onHide={this._closeToast} size="lg">
				<Modal.Header closeButton>{i18n.t('inv_modal_header')} </Modal.Header>
				<Modal.Body>
					<div className="inv-toast-body">
						{this.state.getInvites.map((inv) => (
							<div key={inv.teamid} className="inv-row-container">
								<p className="inv-toast-txt">
									{i18n.t('inv_modal_msg_part_1')} {inv.owneremail}
									{i18n.t('inv_modal_msg_part_2')} {inv.teamname} ({inv.teamid})
									?
								</p>

								<button
									className="btn btn-s m-1 gradiant accept"
									type="button"
									key="formbutton1"
									data-id={inv.teamid}
									onClick={this._clickAcceptBtn}
								>
									{i18n.t('yes')}
								</button>

								<button
									className="btn btn-s m-1 gradiantred decline"
									type="button"
									data-id={inv.teamid}
									onClick={this._clickDeclineBtn}
								>
									{i18n.t('no')}
								</button>
							</div>
						))}
					</div>
				</Modal.Body>
			</Modal>
		);
	}

	render() {
		return <div> {this._renderInviteToast()}</div>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteToast);
