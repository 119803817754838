/*var serverAddress = 'http://fgidev.newseed.se/resource/';

if (process.env.REACT_APP_API_URL) {
	serverAddress = process.env.REACT_APP_API_URL;
}*/

import config from '../config';

let serverAddress = config.site.url_backend;

const NetworkService = {
	getResource: function (url) {
		var promise = new Promise((resolve, reject) => {
			let request = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			};

			fetch(serverAddress + url, request)
				.then((response) => {
					return NetworkService.handleJsonResponse(response);
				})
				.then((responseJson) => {
					resolve(responseJson);
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	getResourceWithAuth: function (url, session) {
		var promise = new Promise((resolve, reject) => {
			//
			/*var config = {
                headers: { 'x-session': session.session, 'x-username': session.username }
            };*/

			let request = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-session': session.guid,
				},
			};

			fetch(serverAddress + url, request)
				.then((response) => {
					return NetworkService.handleJsonResponse(response);
				})
				.then((responseJson) => {
					resolve(responseJson);
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	putResourceWithAuth: function (url, data, session) {
		var promise = new Promise((resolve, reject) => {
			var headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			};

			if (session) {
				headers['x-session'] = session.guid;
			}

			let request = {
				method: 'PUT',
				headers: headers,
				body: JSON.stringify(data),
			};

			fetch(serverAddress + url, request)
				.then((response) => {
					return NetworkService.handleJsonResponse(response);
				})
				.then((responseJson) => {
					resolve(responseJson);
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	putNoDataResourceWithAuth: function (url, session) {
		var promise = new Promise((resolve, reject) => {
			var headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			};

			if (session) {
				headers['x-session'] = session.guid;
			}

			let request = {
				method: 'PUT',
				headers: headers,
			};

			fetch(serverAddress + url, request)
				.then((response) => {
					return NetworkService.handleJsonResponse(response);
				})
				.then((responseJson) => {
					resolve(responseJson);
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	postResourceWithAuth: function (url, data, session) {
		var promise = new Promise((resolve, reject) => {
			var headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			};

			if (session) {
				headers['x-session'] = session.guid;
			}

			let request = {
				method: 'POST',
				headers: headers,
				body: JSON.stringify(data),
			};

			fetch(serverAddress + url, request)
				.then((response) => {
					return NetworkService.handleJsonResponse(response);
				})
				.then((responseJson) => {
					resolve(responseJson);
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	handleJsonResponse: function (response) {
		var promise = new Promise((resolve, reject) => {
			response
				.json()
				.then((responseJson) => {
					if (response.ok) {
						resolve(responseJson);
					} else {
						reject(responseJson);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	handleEmptyResponse: function (response) {
		var promise = new Promise((resolve, reject) => {
			if (response.ok) {
				resolve({});
			} else {
				reject({});
			}
		});
		return promise;
	},
	deleteResourceWithAuth: function (url, session) {
		var promise = new Promise((resolve, reject) => {
			var headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			};

			if (session) {
				headers['x-session'] = session.guid;
			}

			let request = {
				method: 'DELETE',
				headers: headers,
				// body: JSON.stringify(data)
			};

			fetch(serverAddress + url, request)
				.then((response) => {
					return NetworkService.handleJsonResponse(response);
				})
				.then((responseJson) => {
					resolve(responseJson);
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	deleteArrayResourceWithAuth: function (url, data, session) {
		var promise = new Promise((resolve, reject) => {
			var headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			};
			if (session) {
				headers['x-session'] = session.guid;
			}
			let request = {
				method: 'DELETE',
				headers: headers,
				body: JSON.stringify(data),
			};
			fetch(serverAddress + url, request)
				.then((response) => {
					return NetworkService.handleJsonResponse(response);
				})
				.then((responseJson) => {
					resolve(responseJson);
				})
				.catch((error) => {
					reject(error);
				});
		});
		return promise;
	},
	/*postMultipart: function (url, data, session, callback) {
        var fd = new FormData();
        var key = "newImage";

        var headers = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        if (session) {
            headers.headers['x-session'] = session.session;
            headers.headers['x-username'] = session.username;
        }
        fd.append(key, data);

        axios.post(this.getServerPath() + url + key, fd, headers
        )
            .then(response => {
                callback(null, response.data);
            })
            .catch(function (error) {
                callback(error, error)
            });
    },*/
	/*getServerPath: function () {
        //return location.protocol;
        return location.protocol + "//" + location.host + ":3000/v1/";
    },
    getServerRoot: function () {
        //return location.protocol;
        return location.protocol + "//" + location.host + ":3000";
    },
    getAuthValue: function (user) {

        user.authorization = 'Basic ' + btoa(user.username + ":" + user.password);
        return user.authorization;
    }*/
};

export default NetworkService;
