const globalErrorMessage = (
	state = { errorDescription: '', errorMessage: '', type: '', errorObject: '' },
	action
) => {
	switch (action.type) {
		case 'SET_ERROR_MESSAGE':
			return {
				errorDescription: action.errorDescription,
				errorMessage: action.errorMessage,
				errorType: action.errorType,
				errorObject: action.errorObject,
			};
		default:
			return state;
	}
};

export default globalErrorMessage;
