import React from 'react';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { loading: false, currentActivePageId: 1 };
	}

	render() {
		return (
			<footer className="text-center p-3 mt-auto small">
				Copyright © 2021-
				{new Date().getFullYear()+" "}
				NewSeed IT Solutions
			</footer>
		);
	}
}

export default Footer;
