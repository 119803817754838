import eventBus from './EventBus';

export const messageTypes = {
	TASK_UPDATE: 'taskUpdate',
	TASK_CREATION: 'taskCreation',
	TASK_DELETION: 'taskDeletion',
	TASK_UPDATE_LEFT: 'tasksLeftToRun',
	EXECUTION_LOG_UPDATE: 'executionLogUpdate',
	EXECUTION_LOG_CREATION: 'executionLogCreation',
	ADMIN_UPDATE_USER: 'adminUpdateUser',
	TEAM_CREATE: 'teamCreate',
};

export default function handleMessage(message) {
	const { type, content } = JSON.parse(message.data);
	switch (type) {
		default: {
			eventBus.dispatch(type, content);
			break;
		}
	}
}
