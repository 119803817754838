import NetworkService from './NetworkService';

const AdminService = {
	getUsers: function (session) {
		return NetworkService.getResourceWithAuth('v1/admin/users', session);
	},
	updateCustomerType: function (userId, customerType, session) {
		let data = {
			customerType: customerType,
		};
		return NetworkService.putResourceWithAuth(
			'v1/admin/users/' + userId,
			data,
			session
		);
	},
	blockOrUnblockUser: function (userId, session) {
		return NetworkService.putNoDataResourceWithAuth(
			'v1/admin/blockuser/' + userId,

			session
		);
	},
	getExtractTasks: function (userId, session) {
		return NetworkService.getResourceWithAuth(
			'v1/admin/users/' + userId + '/extracttasklist/',
			session
		);
	},
	updateAcceptDate: function (userId, session) {
		return NetworkService.putNoDataResourceWithAuth(
			'v1/useracceptdate/' + userId,
			session
		);
	},
	getUserSpecificID: function (userId, session) {
		return NetworkService.getResourceWithAuth('v1/user/' + userId, session);
	},
};
export default AdminService;
