import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../services/i18n';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import '../extract/Extract.css';
import LanguageSettings from './LanguageSettings';
import SettingsService from '../../services/SettingsService';
import AdminService from '../../services/AdminService';

class UserSettings extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			userInfo: [],
			fortnoxTokens: [],
		};
	}

	_fetchUserFortnoxTokens() {
		SettingsService.getFortnoxAccessTokens(this.props.session)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					fortnoxTokens: dataFromResponse,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	_fetchUserInfo() {
		AdminService.getUserSpecificID(
			this.props.session.userid,
			this.props.session
		)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					userInfo: dataFromResponse,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState({ loading: false });
		this._fetchUserFortnoxTokens();
		this._fetchUserInfo();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}


	render() {
		return (
			<div className="container p-3">
				<div className="row">
					<h3>Användare</h3>
					<table style={{ marginBottom: 50 }}>
						<thead>
							<tr>
								<th className="gradiant">{i18n.t('admin_name')}</th>
								<th className="gradiant">{i18n.t('admin_email')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{this.props.session.name}</td>
								<td>{this.state.userInfo.email}</td>
							</tr>
						</tbody>
					</table>

					{/* 		<h3>Företag</h3>
					<table style={{ marginBottom: 50 }}>
						<thead>
							<tr>
								<th className="gradiant">{i18n.t('Företags namn')}</th>
								<th className="gradiant">{i18n.t('Organisations nummer')}</th>
								<th className="gradiant">{i18n.t('Redigera')}</th>
							</tr>
						</thead>
						<tbody>
							{this.state.fortnoxTokens.map((i) => (
								<tr>
									<td>{i.companyname}</td>
									<td>{i.organizationnumber}</td>
									<td className="text-center">
										<a href="/settings">
											<button
												className="btn btn-s m-1 gradiant"
												type="button"
												title={i18n.t('extract_title_edit')}
											>
												<FontAwesomeIcon icon={faPenSquare} />
											</button>
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table> */}

					<LanguageSettings></LanguageSettings>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
