import NetworkService from './NetworkService';

const TeamService = {
	createTeam: function (obj, session) {
		return NetworkService.postResourceWithAuth('v1/team', obj, session);
	},
	updateTeam: function (id, obj, session) {
		return NetworkService.putResourceWithAuth('v1/team/' + id, obj, session);
	},
	deleteTeam: function (id, session) {
		return NetworkService.deleteResourceWithAuth('v1/team/' + id, session);
	},
	getTeam: function (id, session) {
		return NetworkService.getResourceWithAuth('v1/team/' + id, session);
	},

	addUserToTeam: function (teamid, gmail, session) {
		return NetworkService.postResourceWithAuth(
			'v1/team/' + teamid + '/user',
			gmail,
			session
		);
	},

	addExtractTaskToTeam: function (teamid, extractid, session) {
		return NetworkService.postResourceWithAuth(
			'v1/team/' + teamid + '/extracttask',
			extractid,
			session
		);
	},

	//This is a post resource but deletion works as expected?
	deleteUserFromTeam: function (teamid, gmail, session) {
		return NetworkService.deleteArrayResourceWithAuth(
			'v1/team/' + teamid + '/user',
			gmail,
			session
		);
	},

	deleteExtractTaskFromTeam: function (teamid, extractid, session) {
		return NetworkService.deleteArrayResourceWithAuth(
			'v1/team/' + teamid + '/extracttask',
			extractid,
			session
		);
	},

	//userid is the id of the owner or a member in a team
	getTeam_x_User: function (userid, session) {
		return NetworkService.getResourceWithAuth(
			'v1/teamxuser/' + userid,
			session
		);
	},

	getTeamMembers: function (teamid, session) {
		return NetworkService.getResourceWithAuth(
			'v1/teammembers/' + teamid,
			session
		);
	},

	getTeamExtractTasks: function (teamid, session) {
		return NetworkService.getResourceWithAuth(
			'v1/teamextracttask/' + teamid,
			session
		);
	},

	//userid is the api.session userid
	getUserInvites: function (userid, session) {
		return NetworkService.getResourceWithAuth(
			'v1/userinvites/' + userid,
			session
		);
	},

	//teamid is the team to accept the invite
	acceptUserInvite: function (teamid, session) {
		return NetworkService.putNoDataResourceWithAuth(
			'v1/userinvites/' + teamid,
			session
		);
	},

	//teamid is the team to accept the invite
	declineUserInvite: function (teamid, session) {
		return NetworkService.deleteResourceWithAuth(
			'v1/userinvites/' + teamid,
			session
		);
	},

	//userid is the api.session userid
	getTeamOwnerDetails: function (userid, teamid, session) {
		return NetworkService.getResourceWithAuth(
			'v1/getteamownerdetails/' + userid + '/' + teamid,
			session
		);
	},
};
export default TeamService;
