import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import TeamService from '../../services/TeamService';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import ExtractService from '../../services/ExtractService';

//TODO: Add error messsage if user tries to submit without choosing a key

class AutoAssociateTokenModal extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			getInvites: [],
			showModal: true,
			selectedToken: -1,
			userDeclined: false,
		};
		this._openModal = this._openModal.bind(this);
		this._closeModal = this._closeModal.bind(this);
		this._autoAddToken = this._autoAddToken.bind(this);
		this._dontAutoAddToken = this._dontAutoAddToken.bind(this);
		this._renderSelectToken = this._renderSelectToken.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_openModal() {
		this.setState({ showModal: true });
	}

	_closeModal() {
		this.setState({ showModal: false });
	}

	_fetchUserInvites() {
		let userid = this.props.session.userid;
		if (userid) {
			TeamService.getUserInvites(userid, this.props.session)
				.then((dataFromResponse) => {
					if (!this._isMounted) {
						return;
					}
					this.setState({
						isLoaded: true,
						getInvites: dataFromResponse,
					});
				})
				.catch((err) => {
					alert(err);
				});
		}
	}

	_renderSelectToken() {
		const tokenDropDownArr = [];
		tokenDropDownArr.push({
			value: -1,
			label: i18n.t('resource_not_chosen'),
		});
		this.props.fortnoxAccessTokens.forEach((i) => {
			tokenDropDownArr.push({
				value: i.id,
				label: i.companyname,
			});
		});

		return (
			<div>
				<div>
					<label style={{ display: 'flex', marginTop: 10 }}>
						{i18n.t('auto_update_token_body_select')}
					</label>
					<Select
						id="selectToken"
						value={this.state.selectedToken}
						onChange={this._handleChangeSelectToken}
						data-name="selectToken"
						placeholder={i18n.t('resource_not_chosen')}
						options={tokenDropDownArr}
					/>
				</div>
			</div>
		);
	}

	_handleChangeSelectToken = (selectedToken) => {
		this.setState({
			selectedToken: selectedToken,
		});
	};

	_autoAddToken(tokenid) {
		//when you accept user inv, you change invitation flag to 0 on the team you accepted
		ExtractService.autoUpdateToken(tokenid, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
			})
			.catch((err) => {});
		this._closeModal();
	}

	_dontAutoAddToken(event) {
		this._closeModal();
	}

	_renderModal() {
		return (
			<Modal show={this.state.showModal} onHide={this._closeModal}>
				<Modal.Header closeButton>
					<h3>{i18n.t('error_modal_type_warning')}</h3>
				</Modal.Header>
				<Modal.Body>
					<div>
						<b>{i18n.t('auto_update_token_body_title')}</b>
						<div>{i18n.t('auto_update_token_body_msg')}</div>
						<div>{this._renderSelectToken()}</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-s m-1 gradiant"
						type="button"
						key="formbutton1"
						data-id=""
						onClick={() => this._autoAddToken(this.state.selectedToken.value)}
					>
						{i18n.t('auto_update_token_btn_yes')}
					</button>

					<button
						className="btn btn-s m-1 gradiantred"
						type="button"
						data-id=""
						onClick={this._dontAutoAddToken}
					>
						{i18n.t('auto_update_token_btn_no_not_now')}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}

	render() {
		return <div> {this._renderModal()}</div>;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AutoAssociateTokenModal);
