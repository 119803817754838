const session = (state = {}, action) => {
	switch (action.type) {
		case 'SET_SESSION':
			return {
				name: action.session.name,
				role: action.session.role,
				guid: action.session.guid,
				admin: action.session.admin,
				customertype: action.session.customertype,
				status: action.session.status,
				userid: action.session.userid,
				licenseAcceptDate: action.session.licenseAcceptDate,
			};
		default:
			return state;
	}
};

export default session;
