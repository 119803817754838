import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import AuthenticationService from '../services/Authentication';
import helper from '../services/helper';
import { mapStateToProps, mapDispatchToProps } from '../utils/prop-utils';
import config from '../config';

export function requireAuthentication(Component, roles) {
	class AuthenticatedComponent extends React.Component {
		/*componentWillMount() {
            this.checkAuth();
        }

        componentWillReceiveProps(nextProps) {
            this.checkAuth();
        }*/

		componentDidMount() {
			this.checkAuth();
		}

		componentDidUpdate(prevProps) {
			this.checkAuth();
		}

		checkAuth() {
			if (!this.props.session.guid) {
				if (this.getCookie('Session')) {
					AuthenticationService.fetchSession(this.getCookie('Session'))
						.then((response) => {
							this.props.setSession(response);
						})
						.catch((err) => {
							helper.deleteCookie('Session');
							//this.props.dispatch(push(`/login`));
							//this.props.dispatch(push(`/resource/authorize`));
							window.location.href = config.site.url_backend+'authorize';
						});
				} else {
					//let redirectAfterLogin = this.props.location.pathname;
					//                    this.props.dispatch(push(`/login`));
					//this.props.dispatch(push(`/resource/authorize`));
					window.location.href = config.site.url_backend+'authorize';
				}
			} /*else {
                this.checkRole();
            }*/
		}

		checkRole() {
			//Only redirect for now, no blocking of content stream
			if (roles) {
				let isAuth = false;
				for (var i = 0; i < roles.length; i++) {
					if (roles[i] === this.props.role) {
						isAuth = true;
					}
				}
				if (!isAuth) {
					this.props.dispatch(push(`/`));
				}
			}
		}

		getCookie(name) {
			var value = '; ' + document.cookie;
			var parts = value.split('; ' + name + '=');
			if (parts.length === 2) return parts.pop().split(';').shift();
		}

		render() {
			return (
				<div style={{ width: '100%' }}>
					{this.props.session.guid ? <Component {...this.props} /> : null}
				</div>
			);
		}
	}

	return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}
