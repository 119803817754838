import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import './JobHistoryData.css';
import i18n from '../../services/i18n';
import helper from '../../services/helper';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import ErrorModalLink from '../shared/ErrorModalLink';

class JobHistoryData extends React.Component {
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	_renderLogTable(log) {
		return (
			<table key={log.id} className="job-run-table-item">
				<thead>
					<tr >
						<th className="gradiant"></th>
						<th className="gradiant"></th>
					</tr>
				</thead>
				<tbody className="log-data">
					<tr>
						<td>{i18n.t('extract_log_value_id')}</td>
						<td>{log.id}</td>
					</tr>
					<tr>
						<td>{i18n.t('extract_log_value_jobid')}</td>
						<td>{log.jobid}</td>
					</tr>
					<tr>
						<td>{i18n.t('extract_log_value_starttime')}</td>
						<td>{helper.prettyDateFromJSON(log.starttime)}</td>
					</tr>
					<tr>
						<td>{i18n.t('extract_log_value_endtime')}</td>
						<td>{helper.prettyDateFromJSON(log.endtime)}</td>
					</tr>
					<tr>
						<td>{i18n.t('extract_log_value_status')}</td>
						<td>{log.status}</td>
					</tr>
					<tr>
						<td>{i18n.t('extract_log_value_totalpages')}</td>
						<td>{log.pagetotal}</td>
					</tr>
					<tr>
						<td>{i18n.t('extract_log_value_page')}</td>
						<td>{log.page}</td>
					</tr>
					<tr>
						<td>{i18n.t('extract_log_value_progress')}</td>
						<td>{log.progress}</td>
					</tr>
					{log.lasterror && log.lasterror !== 'No Error' && (
						<tr>
							<td>{i18n.t('extract_log_value_lasterror')}</td>
							<td>
								<ErrorModalLink error={log.lasterror} />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		);
	}

	render() {
		const executionLogs = this.props.data;
		return (
			<div>
				{executionLogs.map((log) => this._renderLogTable(log))}
			
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(JobHistoryData);
