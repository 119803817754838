import React from 'react';
import { connect } from 'react-redux';
import Authentication from '../../services/Authentication';
import i18n from '../../services/i18n';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { email: '', password: '' },
			getInvites: [],
		};
		this._handleChange = this._handleChange.bind(this);
	}

	_handleChange(event) {
		let user = this.state.user;
		user[event.target.name] = event.target.value;
		this.setState({
			user: user,
		});
	}

	_signIn(e) {
		e.preventDefault();
		if (this.state.user.username && this.state.user.password) {
			Authentication.login(this.state.user)
				.then((data) => {
					data.username = this.state.user.username;
					this.props.setSession(data);
					document.cookie = 'Session=' + this.props.session.guid + '; path=/';
					this.props.history.push('/');
					//fetch endpoint data
					//select * where userid = api.session.userid & inv = 1

					//do put request if you ''accept'' on toast btn inv change inv to 0
					//if declined, delete that invitation
				})
				.catch((e) => {
					this.props.setGlobalErrorMessage(
						i18n.t('error_modal_connecting_to_api'),
						JSON.stringify(e),
						'error'
					);
				});
		}
	}

	render() {
		return (
			<div className="container col-md-6 mt-5 vertical-center">
				<div className="page-header text-center"></div>
				<div className="jumbotron">
					<div className="jumbotron-content">
						<form onSubmit={(e) => this._signIn(e)}>
							<div className="form-group">
								<label name="email">{i18n.t('login_common_email')}:</label>
								<input
									type="text"
									name="username"
									value={this.state.user.username}
									className="form-control"
									placeholder={i18n.t('login_common_email')}
									onChange={this._handleChange}
									required
								/>
							</div>
							<div className="form-group">
								<label name="password">
									{i18n.t('login_common_password')}:
								</label>
								<input
									type="password"
									name="password"
									className="form-control"
									placeholder={i18n.t('login_common_password')}
									onChange={this._handleChange}
									required
								/>
							</div>
							<div className="form-group text-center">
								<button
									className="btn btn-secondary"
									type="submit"
									color="primary"
								>
									{i18n.t('login_button_signin')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
