const config = {
	wss: {
		uri: process.env.REACT_APP_WS_URI,
	},
	site: {
		url: process.env.REACT_APP_SITE_URI,
		url_backend: process.env.REACT_APP_BACKEND_URI,
	},
};

export default config;
