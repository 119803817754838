import React from 'react';
import { connect } from 'react-redux';
import {
	faTrashAlt,
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import i18n from '../../services/i18n';
import './FortnoxToken.css';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import ErrorModalLink from '../shared/ErrorModalLink';

class FortnoxToken extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			token: this.props.token,
			infoModalIsOpen: false,
			confirmDeletionModalIsOpen: false,
		};
		this._editToken = this.props.onEdit;
		this._revokeToken = this.props.onDelete;
		this._openInfoModal = this._openInfoModal.bind(this);
		this._closeInfoModal = this._closeInfoModal.bind(this);
		this._openConfirmDeletionModal = this._openConfirmDeletionModal.bind(this);
		this._closeConfirmDeletionModal =
			this._closeConfirmDeletionModal.bind(this);
	}

	_openInfoModal() {
		this.setState({ infoModalIsOpen: true });
	}

	_closeInfoModal() {
		this.setState({ infoModalIsOpen: false });
	}

	_openConfirmDeletionModal() {
		this.setState({
			confirmDeletionModalIsOpen: true,
		});
	}

	_closeConfirmDeletionModal() {
		this.setState({
			confirmDeletionModalIsOpen: false,
		});
	}

	_renderError() {
		const { errormessage: error } = this.state.token;
		return (
			<div className="mb-2">
				<h5>{i18n.t('settings_fortnox_table_header_error_message')}</h5>
				<ErrorModalLink error={error} />
			</div>
		);
	}

	_renderScopes() {
		const scopes = this.state.token.scope.split(' ');
		scopes.sort();

		return (
			<ul className="scopes">
				{scopes.map((scope, index) => (
					<li key={scope + '_' + index}>
						{scope.charAt(0).toUpperCase() + scope.slice(1)}
					</li>
				))}
			</ul>
		);
	}

	_renderConfirmDeleteTokenModal() {
		let token = this.state.token;
		return (
			<Modal
				show={this.state.confirmDeletionModalIsOpen}
				onHide={this._closeConfirmDeletionModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t('extract_log_value_token')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-md-12">
						{i18n.t('settings_revoke_fortnox_access_token')} ?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-s m-1 gradiantred"
						data-id={token.id}
						onClick={this._revokeToken}
					>
						{i18n.t('team_delete_btn')}
					</button>
					<button
						className="btn btn-s m-1 gradiant"
						onClick={this._closeConfirmDeletionModal}
					>
						{i18n.t('extract_button_stop_editing')}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}

	_renderModal() {
		let token = this.state.token;

		return (
			<Modal show={this.state.infoModalIsOpen} onHide={this._closeInfoModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{token.companyname} ({token.organizationnumber})
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{token.errormessage && this._renderError()}
					<div>
						<h5>{i18n.t('settings_fortnox_table_header_scope')}</h5>
						<div>{this._renderScopes()}</div>
					</div>
					<div className="edit-fortnox-token-btn">
						<button
							className="btn btn-s m-1 gradiant"
							data-id={token.id}
							onClick={this._editToken}
							style={{ marginTop: 5 }}
						>
							{i18n.t('settings_fortnox_edit_token')}
						</button>
					</div>
					<div className="remove-fortnox-token-btn">
						<button
							className="btn btn-s m-1 gradiantred"
							data-id={token.id}
							onClick={this._openConfirmDeletionModal}
							style={{ marginTop: 5 }}
						>
							<FontAwesomeIcon icon={faTrashAlt} />
						</button>
					</div>
				</Modal.Body>
			</Modal>
		);
	}

	render() {
		var errorNotice = null;
		let token = this.state.token;
		if (token.errormessage) {
			errorNotice = (
				<FontAwesomeIcon color="red" icon={faExclamationTriangle} />
			);
		}
		return (
			<div>
				{this._renderModal()}
				{this._renderConfirmDeleteTokenModal()}
				<div className="ticket-div" onClick={this._openInfoModal}>
					<div className="ticket-corner ticket-top-left"></div>
					<div className="ticket-corner ticket-top-right"></div>
					<div className="ticket-corner ticket-bottom-left"></div>
					<div className="ticket-corner ticket-bottom-right"></div>
					<div className="ticket-sides">
						<div className="ticket-content">
							<div>{token.companyname}</div>
							<div>
								{token.organizationnumber}
								{errorNotice}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(FortnoxToken);
