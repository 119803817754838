import i18n from './i18n';

const helper = {
	checkAccess: function (arr, res, read, write) {
		var scopeArray = arr.resources;
		let isExist = scopeArray.some(
			(o) => o.name === res && o.read >= read && o.write >= write
		);
		return isExist;
	},
	getCookie(name) {
		var value = '; ' + document.cookie;
		var parts = value.split('; ' + name + '=');
		if (parts.length === 2) return parts.pop().split(';').shift();
	},
	deleteCookie(name) {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	},
	prettySchedule(interval, subinterval) {
		let firstPart = i18n.t('scheduler_' + interval);
		let secondPart = this.prettySchedulePart2(interval, subinterval);

		return firstPart + ' ' + secondPart;
	},
	prettySchedulePart2(interval, subinterval) {
		if (interval === 'monthly') {
			return subinterval;
		} else if (interval === 'weekly') {
			return i18n.t('scheduler_weekday' + subinterval);
		} else if (interval === 'daily') {
			return (
				i18n.t('scheduler_hour_pre_string') +
				' ' +
				subinterval +
				' ' +
				i18n.t('scheduler_hour_post_string')
			);
		} else {
			return '';
		}
	},
	prettyDateFromJSON(jsonDate) {
		if (jsonDate) {
			let date = new Date(jsonDate);
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = date.getHours();
			let minutes = date.getMinutes();
			let seconds = date.getSeconds();
			return (
				year +
				'-' +
				(month < 10 ? '0' : '') +
				month +
				'-' +
				(day < 10 ? '0' : '') +
				day +
				' ' +
				(hour < 10 ? '0' : '') +
				hour +
				':' +
				(minutes < 10 ? '0' : '') +
				minutes +
				':' +
				(seconds < 10 ? '0' : '') +
				seconds
			);
		} else {
			return '';
		}
	},
};

export default helper;
