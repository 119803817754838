import NetworkService from './NetworkService';
import websocketService from './WebsocketService';

const AuthenticationService = {
	login: function (user, callback) {
		/*NetworkService.putResourceWithAuth("session/", loginadata, null, (err, response) => {
            if (err) {
                callback(err, null);
            } else {
                document.cookie = "Session=" + response.session;
                callback(null, response);
            }
        });*/

		var promise = new Promise((resolve, reject) => {
			NetworkService.postResourceWithAuth('v1/login', user, null)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
			/*fetch(serverAddress + "v1/login/", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					email: user.email,
					password: user.password,
				})
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("login_failed"))
					reject(error);
				});*/
		});
		return promise;
	},
	oauthGoogle: function (code, scope, authuser, prompt) {
		var promise = new Promise((resolve, reject) => {
			NetworkService.getResource(
				'oauth?code=' +
					code +
					'&scope=' +
					scope +
					'&authuser=' +
					authuser +
					'&prompt=' +
					prompt,
				null
			)
				.then((response) => {
					if (response.redirected) {
						window.location.href = response.redirected;
					} else {
						resolve(response);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
		return promise;
	},
	fetchSession: function (session) {
		var sess = { guid: session };
		const promise = NetworkService.getResourceWithAuth(
			'v1/session/' + session,
			sess
		);

		promise.then(() => {
			websocketService.connect(session);
		});
		return promise;
	},
	logout: function (session) {
		return NetworkService.deleteResourceWithAuth('v1/logout', session);
	},
	deleteCookie: function (name) {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	},
};

export default AuthenticationService;
