import React from 'react';
import helper from '../../services/helper';
import i18n from '../../services/i18n';

class UserTableBody extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
		//this._handleCustomerTypeChange = this._handleCustomerTypeChange.bind(this);
	}
	_renderCustomerType(userId) {
		const { _handleCustomerTypeChange } = this.props;

		return (
			<select
				data-userid={userId}
				value={this.props.userXcustomerType[userId]}
				className="form-control col-md-12 col-sm-12 col-lg-12"
				onChange={_handleCustomerTypeChange}
			>
				<option value="0">{i18n.t('admin_customer_type_basic')}</option>
				<option value="1">{i18n.t('admin_customer_type_advanced')}</option>
			</select>
		);
	}

	_renderBlockOrUnblockUserBtn(user) {
		if (typeof user.status !== 'undefined') {
			if (user.status === 'ok' || user.status === null) {
				return (
					<input
						className="btn btn-s m-1 gradiantred"
						type="button"
						onClick={() =>
							this.props._openConfirmBlockOrUnblockModal(user, {
								status: 'blocked',
							})
						}
						value={i18n.t('admin_block_btn')}
						style={{ marginLeft: 10 }}
					/>
				);
			} else if (user.status === 'blocked') {
				return (
					<input
						className="btn btn-s m-1 gradiantred"
						type="button"
						onClick={() =>
							this.props._openConfirmBlockOrUnblockModal(user, { status: 'ok' })
						}
						value={i18n.t('admin_unblock_btn')}
						style={{ marginLeft: 10 }}
					/>
				);
			}
		}
	}

	render() {
		const { items, _openExtractTaskListModal } = this.props;

		let userList = [];
		for (const u of items) {
			userList.push(
				<tr key={u.id}>
					<td>{u.name} <div style={{float: 'right', fontWeight: 'bold'}}>[{u.id}]</div></td>
					<td>{u.email}</td>
					<td>{this._renderCustomerType(u.id, u.customer_type)}</td>
					<td>{helper.prettyDateFromJSON(u.modified)}</td>
					<td>
						<input
							className="btn btn-s m-1 gradiant"
							data-userid={u.id}
							type="button"
							onClick={_openExtractTaskListModal}
							value={i18n.t('admin_list_extract_tasks')}
						/>
						{this._renderBlockOrUnblockUserBtn(u)}
					</td>
				</tr>
			);
		}

		return <>{userList}</>;
	}
}

export default UserTableBody;
