import {
	faTrashAlt,
	faExclamationTriangle,
	faPenSquare,
	faPlayCircle,
	faFolder,
	faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import { connect } from 'react-redux';
import ExtractService from '../../services/ExtractService';
import SettingsService from '../../services/SettingsService';
import i18n from '../../services/i18n';
import helper from '../../services/helper';
import './Extract.css';
import TeamService from '../../services/TeamService';
import ErrorModal from '../ErrorModal';
import AutoAssociateTokenModal from './AutoAssociateTokenModal';
import SelectTaskParams from './SelectTaskParams';
import Pagination from '../Pagination';

//TODO: Ta bort teamid från den nya servicen getOwnerDetails
import Select from 'react-select';
import { messageTypes } from '../../services/MessageHandler';
import eventBus from '../../services/EventBus';
import JobHistoryModal from './JobHistoryModal';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import taskStatuses from '../../constants/task-statuses';

class Extract extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			loadingResourceList: true,
			loadingCreateForm: true,
			extractableResources: null,
			resource: '-1',
			sheetname: '',
			jobname: '',
			list: [],
			editing: 0,
			schedule_interval: 'none',
			schedule_subinterval: -1,
			createOrUpdateModalIsOpen: false,
			jobHistoryModalIsOpen: false,
			showToast: false,
			confirmDeletionModalIsOpen: false,
			extractTaskToDelete: 0,
			jobHistoryId: 0,
			isLoaded: false,
			executionLogData: [],
			data: null,
			listOfJobNames: [],
			toastContent: '',
			resourceScopes: [],
			resourceWarning: '',
			resourceWarningName: '',
			taskInfoIfMember: [],
			teamMemberOrOwnerData: [],
			allTeamData: [],
			team_x_userData: [],
			teamid: -1,
			resourceNameToUpperCase: 'default',
			resourceSelect: { value: -1, label: i18n.t('none_selected') },
			getInvites: [],
			allResources: [],
			fortnoxAccessTokens: [],
			mayUseSchedule: false,
			financialYearSelect: {},
			fortnoxAccessToken: 0,
			errorShouldOpen: 'default',
			latestRunTaskId: -1,
			creatorOfExtractTaskUserId: this.props.session.userid,
			tasksLeftToday: [],
			financialYearsData: [],
			allTaskParams: [],
			taskParamsSelect: { value: -1, label: i18n.t('none_selected') },
			queryParam: 'default',
			secondTaskParamsSelect: { value: -1, label: i18n.t('none_selected') },
			secondQueryParam: null,
			currentPageOfYourOwnTasks: 1,
			tasksPerPage: 10,
			currentPageOfYourTeamTasks: 1,
		};
		this.updateTaskInList = this.updateTaskInList.bind(this);
		this.sender = this.sender.bind(this);
		this.addTask = this.addTask.bind(this);
		this.updateTasksLeftToRun = this.updateTasksLeftToRun.bind(this);
		this.removeTask = this.removeTask.bind(this);
		this._handleChange = this._handleChange.bind(this);
		this._createOrUpdateTask = this._createOrUpdateTask.bind(this);
		this._deleteTask = this._deleteTask.bind(this);
		this._confirmDeletion = this._confirmDeletion.bind(this);
		this._editTask = this._editTask.bind(this);
		this._manualRun = this._manualRun.bind(this);
		this._createTask = this._createTask.bind(this);
		this._updateTask = this._updateTask.bind(this);
		this._stopEditing = this._stopEditing.bind(this);
		this._closeCreateOrUpdateModal = this._closeCreateOrUpdateModal.bind(this);
		this._openCreateOrUpdateModal = this._openCreateOrUpdateModal.bind(this);
		this._openToast = this._openToast.bind(this);
		this._closeToast = this._closeToast.bind(this);
		this._closeConfirmDeletionModal =
			this._closeConfirmDeletionModal.bind(this);
		this._openConfirmDeletionModal = this._openConfirmDeletionModal.bind(this);
		this._editTeamTask = this._editTeamTask.bind(this);
		this._openJobHistoryModal = this._openJobHistoryModal.bind(this);
		this._closeJobHistoryModal = this._closeJobHistoryModal.bind(this);
		this.onChangePageOfYourOwnTasks =
			this.onChangePageOfYourOwnTasks.bind(this);
		this.onChangePageOfYourTeamTasks =
			this.onChangePageOfYourTeamTasks.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		var promises = [];
		promises.push(ExtractService.getExtractableResources(this.props.session));
		console.log(this.props.session, 'before gettin it');
		promises.push(ExtractService.getExtractTasks(this.props.session));

		let that = this;

		promises.push(SettingsService.getFortnoxAccessTokens(this.props.session));
		promises.push(ExtractService.getResourceScopes(this.props.session));

		Promise.all(promises)
			.then((values) => {
				if (this._isMounted) {
					let newState = {
						extractableResources: values[0],
						loadingCreateForm: false,
						loadingResourceList: false,
						list: values[1],
						fortnoxAccessTokens: values[2],
						resourceScopes: values[3],
						mayUseSchedule: this.props.session.customertype > 0,
					};
					if (newState.fortnoxAccessTokens.length === 0) {
						newState.showToast = true;
						newState.errorShouldOpen = 'fortnoxKeyError';
						newState.toastContent = i18n.t('extract_task_no_fortnox_token');
					}
					if (newState.fortnoxAccessTokens.length === 1) {
						newState.fortnoxAccessToken = newState.fortnoxAccessTokens[0].id;
					}
					this.setState(newState);
				}
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});

		eventBus.on(messageTypes.TASK_UPDATE, this.sender);
		eventBus.on(messageTypes.TASK_CREATION, this.addTask);
		eventBus.on(messageTypes.TASK_UPDATE_LEFT, this.updateTasksLeftToRun);
		eventBus.on(messageTypes.TASK_DELETION, this.removeTask);

		setTimeout(function () {
			that._fetchAllTeamSpecificInfo();
			that._fetchUserInvites();
		}, 1000);

		this._fetchTasksLeft();
		this._fetchAllTeam_x_User();
	}

	checkIfAnyTasksAreMissingToken() {
		let allTasks = this.state.list;
		if (allTasks.length > 0) {
			if (!allTasks.every(this.tokenExists)) {
				return (
					<AutoAssociateTokenModal
						fortnoxAccessTokens={this.state.fortnoxAccessTokens}
					/>
				);
			}
			return null;
		}
		return null;
	}

	tokenExists(allOfTheTaskObjects) {
		return allOfTheTaskObjects.fortnoxtoken !== null;
	}

	componentWillUnmount() {
		this._isMounted = false;

		eventBus.remove(messageTypes.TASK_UPDATE, this.sender);
		eventBus.remove(messageTypes.TASK_CREATION, this.addTask);
		eventBus.remove(messageTypes.TASK_UPDATE_LEFT, this.updateTasksLeftToRun);
		eventBus.remove(messageTypes.TASK_DELETION, this.removeTask);
	}

	sender = (data, sender) => {
		this.updateTaskInList(data, 'team');
	};

	/* This function updates the ExtractTask data for a specific extracttask id, 
	this update is then used by the websockets watching for a chance to update the data in ui */
	updateTaskInList = (data, sender) => {
		const taskId = data.id;
		const updates = data;
		const { allTeamData } = this.state;

		if (sender === 'team') {
			const newTeamData = allTeamData.map((t) => t);
			Object.keys(newTeamData).forEach((key) => {
				const taskIndex = newTeamData[key].extractTasks.findIndex(
					(task) => task.id === taskId
				);
				if (taskIndex > -1) {
					newTeamData[key].extractTasks[taskIndex] = {
						...newTeamData[key].extractTasks[taskIndex],
						...updates,
					};
					if (
						newTeamData[key].extractTasks[taskIndex].status ===
						taskStatuses.failed
					)
						this.setState({
							errorShouldOpen: 'runError',
							latestRunTaskId: newTeamData[key].extractTasks[taskIndex].id,
						});
				}
			});
			if (this._isMounted) {
				this.setState({ allTeamData: newTeamData });
			}
		}

		const taskList = [...this.state.list];
		const taskIndex = taskList.findIndex((task) => task.id === taskId);
		if (taskIndex > -1) {
			taskList[taskIndex] = { ...taskList[taskIndex], ...updates };
			if (taskList[taskIndex].status === taskStatuses.failed)
				this.setState({
					errorShouldOpen: 'runError',
					latestRunTaskId: taskList[taskIndex].id,
				});
			if (this._isMounted) {
				this.setState({
					list: taskList,
				});
			}
		}
	};

	renderTokenModal() {
		return <AutoAssociateTokenModal />;
	}

	renderErrorModal() {
		if (this.state.errorShouldOpen === 'runError')
			return (
				<ErrorModal
					errorMessage={i18n.t('error_modal_run_error_message')}
					taskId={this.state.latestRunTaskId}
				/>
			);
	}

	addTask = (data) => {
		const taskList = this.state.list;
		const isInList = taskList.some((t) => t.id === data.id);

		if (this._isMounted && !isInList) {
			this.setState({ list: [...taskList, data] });
		}
	};

	updateTasksLeftToRun = (data) => {
		if (this._isMounted) {
			this.setState({ tasksLeftToday: data });
		}
	};

	removeTask = (data) => {
		const taskList = this.state.list;
		const taskIndex = taskList.findIndex((t) => t.id === data.id);
		if (this._isMounted && taskIndex > -1) {
			taskList.splice(taskIndex, 1);
			this.setState({
				list: taskList,
			});
		}
	};

	_fetchTasksLeft() {
		let userid = this.props.session.userid;
		ExtractService.getTasksLeft(userid, this.props.session)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					tasksLeftToday: dataFromResponse,
				});
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_openCreateOrUpdateModal(event) {
		let teamid = event.target.dataset.id;
		let that = this;

		this.setState({
			createOrUpdateModalIsOpen: true,
			teamid: teamid,
		});
		//todo: make this promise based instead
		setTimeout(function () {
			that._fetchFinancialYearsForToken();
		}, 200);
	}

	_closeCreateOrUpdateModal() {
		this._stopEditing();
		this.setState({
			teamid: -1,
			//creatorOfExtractTaskUserId: this.props.session.userid,
		});
	}

	//Currently not being used
	_openToast() {
		this.setState({ showToast: true });
	}

	_closeToast() {
		this.setState({ showToast: false, toastContent: '' });
	}

	_openConfirmDeletionModal() {
		this.setState({ confirmDeletionModalIsOpen: true });
	}

	_closeConfirmDeletionModal() {
		this.setState({ confirmDeletionModalIsOpen: false });
	}

	_handleChangeSelectResource = (resourceSelect) => {
		let value = resourceSelect.value;

		this.setState(
			{
				resourceSelect: resourceSelect,
				resource: value,
			},
			this._setScopeWarningMessage
		);
	};

	_handleChangeSelectTaskParams(value, queryParamFromChild) {
		this.setState({
			taskParamsSelect: value,
			queryParam: queryParamFromChild,
		});
	}
	_handleChangeSelectSecondTaskParams(value, queryParamFromChild) {
		this.setState({
			secondTaskParamsSelect: value,
			secondQueryParam: queryParamFromChild,
		});
	}

	_fetchTaskParams(taskid) {
		return new Promise((resolve, reject) => {
			ExtractService.getTaskParamsFrontend(taskid, this.props.session)
				.then((dataFromResponse) => {
					if (!this._isMounted) {
						return;
					}
					this.setState({
						isLoaded: true,
						allTaskParams: dataFromResponse,
					});
					resolve();
				})
				.catch((err) => {
					console.log(err);
					this.props.setGlobalErrorMessage(
						i18n.t('error_modal_connecting_to_api'),
						JSON.stringify(err),
						i18n.t('error_modal_type_error')
					);
					reject();
				});
		});
	}
	_fetchFinancialYearsForToken() {
		return new Promise(() => {
			SettingsService.getFinancialYears(
				this.props.session,
				this.state.fortnoxAccessToken
			)
				.then((dataFromResponse) => {
					if (!this._isMounted) {
						return;
					}
					this.setState({
						isLoaded: true,
						financialYearsData: dataFromResponse,
					});
				})
				.catch((err) => {
					this.props.setGlobalErrorMessage(
						i18n.t('error_modal_connecting_to_api'),
						JSON.stringify(err),
						i18n.t('error_modal_type_error')
					);
				});
		});
	}

	//TODO johanna: make it a promise to change state then fetch year
	async _handleChange(event) {
		let that = this;

		let value = event.target.value;
		let name = event.target.dataset.name;
		this.setState({ [name]: value });

		if (name === 'fortnoxAccessToken') {
			this.setState(
				{ [name]: value },
				this._setScopeWarningMessage,
				setTimeout(function () {
					that._fetchFinancialYearsForToken();
				}, 300)
			);
		} else {
			this.setState({ [name]: value });
		}
	}

	// Check if the user has the scope for the chosen resource
	_setScopeWarningMessage() {
		let fortnoxAccessToken = null;
		for (let i = 0; i < this.state.fortnoxAccessTokens.length; i++) {
			if (
				// eslint-disable-next-line
				this.state.fortnoxAccessToken == this.state.fortnoxAccessTokens[i].id
			) {
				fortnoxAccessToken = this.state.fortnoxAccessTokens[i];
				break;
			}
		}
		if (
			(fortnoxAccessToken !== null &&
				fortnoxAccessToken.scope
					.split(' ')
					.includes(this.state.resourceScopes[this.state.resource])) ||
			this.state.resource === '-1'
		) {
			// The user has the scope so remove warnings and stop
			this.setState({
				resourceWarning: '',
				resourceWarningName: '',
			});
		} else {
			// Warn the user that they do not have the needed scope for this resource
			this.setState({
				resourceWarning: i18n.t('extract_task_no_fortnox_token_for_scope'),
				resourceWarningName: this.state.resourceScopes[this.state.resource],
				//errorShouldOpen: true,
			});
		}
	}

	_addTaskParams(taskid, obj) {
		//TODO: Put an condition here to avoid sending http request when
		//user has not selected any additional params (check state)
		//johanna
		ExtractService.createTaskParams(taskid, obj, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_creating_resource'),
					JSON.stringify(err),
					i18n.t('error_modal_type_error')
				);
			});
	}

	_createTask(obj) {
		//TODO johanna: make query_param a state instead
		// (right now the only qp we support is financialyear)
		let taskParamObj = null;
		if (
			this.state.queryParam &&
			this.state.taskParamsSelect &&
			this.state.taskParamsSelect.value !== -1
		) {
			taskParamObj = {
				query_param: this.state.queryParam,
				param_value: this.state.taskParamsSelect.value,
				label: this.state.taskParamsSelect.label,
			};
		}

		let secondTaskParamObj = null;
		if (
			this.state.secondQueryParam &&
			this.state.secondTaskParamsSelect.value !== -1
		) {
			secondTaskParamObj = {
				query_param: this.state.secondQueryParam,
				param_value: this.state.secondTaskParamsSelect.value,
				label: this.state.secondTaskParamsSelect.label,
			};
		}

		ExtractService.createExtractTask(obj, this.props.session)
			.then((createdTask) => {
				if (!this._isMounted) {
					return;
				}
				if (taskParamObj) {
					this._addTaskParams(createdTask.id, taskParamObj);
				}
				if (secondTaskParamObj) {
					this._addTaskParams(createdTask.id, secondTaskParamObj);
				}

				this.setState({ list: [], loadingResourceList: true });
				this._stopEditing();
				return ExtractService.getExtractTasks(this.props.session);
			})
			.then((list) => {
				if (!this._isMounted) {
					return; //TODO: eventuellt ta bort detta o return;
				}

				this.setState({ list: list, loadingResourceList: false });
			})
			.then((t) => {
				this._fetchAllTeamSpecificInfo();
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_creating_resource'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_createTaskForTeam(obj) {
		return ExtractService.createExtractTask(obj, this.props.session);
	}

	_updateTaskParams(taskid, obj) {
		ExtractService.updateTaskParams(taskid, obj, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_creating_resource'),
					JSON.stringify(err),
					i18n.t('error_modal_type_error')
				);
			});
	}

	_updateTask(id, obj) {
		let taskParamObj = null;

		if (this.state.queryParam && this.state.taskParamsSelect) {
			taskParamObj = {
				query_param: this.state.queryParam,
				param_value: this.state.taskParamsSelect.value,
				label: this.state.taskParamsSelect.label,
			};
		}

		let secondTaskParamObj = null;
		if (
			this.state.secondQueryParam &&
			this.state.secondTaskParamsSelect.value !== -1
		) {
			secondTaskParamObj = {
				query_param: this.state.secondQueryParam,
				param_value: this.state.secondTaskParamsSelect.value,
				label: this.state.secondTaskParamsSelect.label,
			};
		}

		ExtractService.updateExtractTask(id, obj, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}

				if (taskParamObj) {
					this._updateTaskParams(id, taskParamObj);
				}
				if (secondTaskParamObj) {
					this._updateTaskParams(id, secondTaskParamObj);
				}

				this.setState({ list: [], loadingResourceList: true });
				this._stopEditing();
				return ExtractService.getExtractTasks(this.props.session);
			})
			.then((list) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({ list: list, loadingResourceList: false });
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_updating_resource'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_createOrUpdateTask() {
		let sheetname = this.state.sheetname;
		let jobname = this.state.jobname;
		let fortnoxAccessToken = this.state.fortnoxAccessToken;
		if (!sheetname.length) {
			sheetname = i18n.t('extract_sheet_name');
		}
		if (!jobname.length) {
			jobname = i18n.t(
				'resource_' + this.state.extractableResources[this.state.resource]
			);
		}

		//HÄÄR enda obj vi skickar med
		let obj = {
			resource: this.state.resource,
			sheetname: sheetname,
			jobname: jobname,
			fortnoxtoken: fortnoxAccessToken,
			schedule_interval: this.state.schedule_interval,
			schedule_subinterval: this.state.schedule_subinterval,
		};

		this.setState(
			{ createOrUpdateModalIsOpen: false },
			this._setScopeWarningMessage
		);
		if (this.state.editing) {
			this._updateTask(this.state.editing, obj);
		} else if (typeof this.state.teamid !== 'undefined') {
			this._createTaskForTeam(obj)
				.then((task) => {
					let extractIdArray = {
						extractid: [task.id],
					};
					this._createTeamTask(this.state.teamid, extractIdArray);
				})
				.then((t) => {
					this._fetchAllTeamSpecificInfo();
				});
		} else {
			this._createTask(obj);
		}
	}

	_confirmDeletion(event) {
		event.preventDefault();
		let id = event.currentTarget.dataset.id;
		this.setState({
			confirmDeletionModalIsOpen: true,
			extractTaskToDelete: id,
		});
	}

	_deleteTask(alsoDeleteFromDrive) {
		ExtractService.deleteExtractTask(
			this.state.extractTaskToDelete,
			alsoDeleteFromDrive,
			this.props.session
		)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					list: [],
					loadingResourceList: true,
					confirmDeletionModalIsOpen: false,
					extractTaskToDelete: 0,
				});
				this._fetchAllTeamSpecificInfo();
				return ExtractService.getExtractTasks(this.props.session);
			})
			.then((list) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({ list: list, loadingResourceList: false });
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_deleting_resource'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_editTask(task) {
		this._fetchFinancialYearsForToken();

		let id = task.id;
		let stuff = null;
		for (let i = 0; i < this.state.list.length; i++) {
			// eslint-disable-next-line
			if (this.state.list[i].id == id) {
				stuff = this.state.list[i];
				break;
			}
		}

		this._fetchTaskParams(id)
			.then(() => {
				if (this.state.allTaskParams.length > 0) {
					//Todo johanna: make more dynamic instead if more params
					this.state.allTaskParams.forEach((i) => {
						if (i.query_param === 'sortby') {
							this.setState({
								secondTaskParamsSelect: {
									value: i.param_value,
									label: i.label,
								},
								secondQueryParam: i.query_param,
							});
						} else {
							this.setState({
								taskParamsSelect: {
									value: i.param_value,
									label: i.label,
								},
								queryParam: i.query_param,
							});
						}
					});
				}
			})
			.catch((err) => console.log(err, 'Could not fetch task params!'));

		if (id) {
			this.setState(
				{
					resource: stuff.resource,
					resourceSelect: {
						value: stuff.resource,
						label: i18n.t(
							'resource_' + this.state.extractableResources[stuff.resource]
						),
					},
					sheetname: stuff.sheetname,
					jobname: stuff.jobname,
					fortnoxAccessToken: stuff.fortnoxtoken,
					editing: id,
					schedule_interval: stuff.schedule_interval,
					schedule_subinterval: stuff.schedule_subinterval,
					createOrUpdateModalIsOpen: true,
					creatorOfExtractTaskUserId: stuff.userid,
				},
				/*Dont show scope msg if you arent task owner*/

				this._setScopeWarningMessage
			);
		}

		let that = this;
		//todo: make promise based instead
		setTimeout(function () {
			that._fetchFinancialYearsForToken();
		}, 300);
	}

	_editTeamTask(et, teamid) {
		let that = this;
		//Helper function that sets the state of this.state.schedule_interval, and this.state.schedule_subinterval,
		this._fetchTaskParams(et.id)
			.then(() => {
				if (this.state.allTaskParams.length > 0) {
					//Todo johanna: make more dynamic instead if more params
					this.state.allTaskParams.forEach((i) => {
						if (i.query_param === 'sortby') {
							this.setState({
								secondTaskParamsSelect: {
									value: i.param_value,
									label: i.label,
								},
								secondQueryParam: i.query_param,
							});
						} else {
							this.setState({
								taskParamsSelect: {
									value: i.param_value,
									label: i.label,
								},
								queryParam: i.query_param,
							});
						}
					});
				}
			})
			.catch((err) => console.log(err, 'Got in catch'));

		let result = that._prettyTeamSchedule(et.schedule);
		this.setState({
			resourceSelect: {
				value: et.resource,
				label: i18n.t(
					'resource_' + this.state.extractableResources[et.resource]
				),
			},
			sheetname: et.sheetname,
			jobname: et.jobname,
			resource: et.resource,
			schedule: et.schedule,
			schedule_interval: result.schedule_interval,
			schedule_subinterval: result.schedule_subinterval,
			editing: et.id,
			creatorOfExtractTaskUserId: et.userid,
			createOrUpdateModalIsOpen: true,
			teamid: teamid,
		});
	}
	_manualRun(task, sender) {
		this.setState({
			jobHistoryId: task.id,
			errorShouldOpen: 'default',
		});

		if (task.fortnoxtoken !== null) {
			this.updateTaskInList(
				{ id: task.id, status: taskStatuses.running },
				sender
			);
			this.updateTasksLeftToRun({
				nr_of_tasks_user_can_run_today:
					this.state.tasksLeftToday.nr_of_tasks_user_can_run_today - 1,
			});
			ExtractService.runTaskManually(task.id, this.props.session)
				.then(() => {
					if (!this._isMounted) {
						return;
					}
				})
				.catch((err) => {
					this.setState({ loadingResourceList: false });
					this.updateTaskInList({ id: task.id, status: 'failed' }, sender);
					this.props.setGlobalErrorMessage(
						i18n.t('error_modal_connecting_to_api'),
						JSON.stringify(err),
						'error'
					);
				});
		} else {
			this.props.setGlobalErrorMessage(
				i18n.t('error_modal_why'),
				i18n.t('error_modal_fortnox_token_not_set_for_task'),
				'error'
			);
		}
	}

	_stopEditing() {
		let fortnoxAccessToken = 0;
		if (this.state.fortnoxAccessTokens.length === 1) {
			fortnoxAccessToken = this.state.fortnoxAccessTokens[0].id;
		}
		this.setState({
			resource: -1,
			sheetname: '',
			jobname: '',
			editing: 0,
			fortnoxAccessToken: fortnoxAccessToken,
			schedule_interval: 'none',
			schedule_subinterval: -1,
			createOrUpdateModalIsOpen: false,
			resourceSelect: 'default',

			taskParamsSelect: {
				value: -1,
				label: i18n.t('resource_not_chosen'),
			},
			queryParam: 'default',
		});
	}

	_openJobHistoryModal(task) {
		this.setState({
			jobHistoryId: task.id,
			jobHistoryModalIsOpen: true,
		});
	}

	_closeJobHistoryModal() {
		this.setState({
			jobHistoryId: -1,
			jobHistoryModalIsOpen: false,
		});
	}

	_stopExtraction(task) {
		return ExtractService.stopExtraction(task.id, this.props.session);
	}

	_renderFortnoxAccessTokenSelect() {
		let options = this.state.fortnoxAccessTokens.map((token) => {
			return (
				<option key={token.id} value={token.id}>
					{token.companyname} ({token.organizationnumber})
				</option>
			);
		});
		options.unshift(
			<option key={0} value={0}>
				{i18n.t('extract_label_fortnox_token_not_selected')}
			</option>
		);
		return (
			<div className="form-group">
				<label style={{ display: 'flex' }} htmlFor="selectResource">
					{i18n.t('extract_label_choose_fortnox_token')}:
				</label>
				<select
					id="fortnox-token-selector"
					className="form-control col-md-12 col-sm-12 col-lg-12"
					value={this.state.fortnoxAccessToken}
					onChange={this._handleChange}
					data-name="fortnoxAccessToken"
				>
					{options}
				</select>
			</div>
		);
	}

	_renderExtractableResources() {
		let sortedResourceList = Object.entries(
			this.state.extractableResources
		).sort(function (a, b) {
			if (i18n.t('resource_' + b[1]) < i18n.t('resource_' + a[1])) {
				return 1;
			}
			return -1;
		});

		const dropDownArray = [];
		sortedResourceList.forEach((element) => {
			dropDownArray.push({
				value: element[0],
				label: i18n.t('resource_' + element[1]),
			});
		});

		return (
			<div className="form-group">
				<label style={{ display: 'flex' }} htmlFor="selectResource">
					{i18n.t('extract_label_resource')}:
				</label>
				<Select
					isDisabled={this.state.editing}
					id="selectResource"
					value={this.state.resourceSelect}
					onChange={this._handleChangeSelectResource}
					data-name="resource"
					options={dropDownArray}
					className="select-resource-drop-down"
				/>
				{
					<div style={{ float: 'right' }}>
						<p>
							{' '}
							{(() => {
								if (
									typeof this.state.extractableResources[
										this.state.resource
									] !== 'undefined'
								) {
									let getEnglishTranslation = i18n.getResource(
										'en',
										'translation',
										'resource_' +
											this.state.extractableResources[this.state.resource]
									);

									let resourceWithoutSpaces = getEnglishTranslation.replace(
										/\s/g,
										''
									);

									return (
										<a
											className="anchor-link-color"
											href={
												'https://apps.fortnox.se/apidocs#tag/' +
												resourceWithoutSpaces +
												'Resource'
											}
											target="_blank"
											rel="noopener noreferrer"
										>
											?
										</a>
									);
								}
								return null;
							})()}{' '}
						</p>
					</div>
				}
				<p style={{ color: 'red', clear: 'both', margin: 0 }}>
					{this.state.resourceWarning}
				</p>
				<p style={{ color: 'red', fontWeight: 'bold', margin: 0 }}>
					{this.state.resourceWarningName}
				</p>
			</div>
		);
	}

	_renderIntervalScheduler() {
		if (!this.state.mayUseSchedule) {
			return null;
		}

		return (
			<div className="form-group">
				<label htmlFor="selectInterval">
					{i18n.t('scheduler_choose_interval')}:
				</label>
				<select
					id="selectInterval"
					className="form-control col-md-6 col-sm-12 col-lg-4"
					value={this.state.schedule_interval}
					onChange={this._handleChange}
					data-name="schedule_interval"
				>
					<option value="none"> {i18n.t('scheduler_none')}</option>
					<option value="daily"> {i18n.t('scheduler_daily')}</option>
					<option value="weekly"> {i18n.t('scheduler_weekly')}</option>
					<option value="monthly"> {i18n.t('scheduler_monthly')}</option>
				</select>
			</div>
		);
	}

	_renderSubintervalScheduler() {
		if (!this.state.mayUseSchedule) {
			return null;
		}

		let options = [];
		options.push(
			<option value="-1" key="-1">
				{' '}
				{i18n.t('scheduler_choose_subinterval')}
			</option>
		);

		if (this.state.schedule_interval === 'none') {
			return null;
		}
		if (this.state.schedule_interval === 'monthly') {
			for (let i = 1; i <= 31; i++) {
				options.push(
					<option value={i} key={i}>
						{' '}
						{i}
					</option>
				);
			}
		} else if (this.state.schedule_interval === 'weekly') {
			for (let i = 1; i <= 7; i++) {
				options.push(
					<option value={i} key={i}>
						{' '}
						{i18n.t('scheduler_weekday' + i)}
					</option>
				);
			}
		} else if (this.state.schedule_interval === 'daily') {
			for (let i = 0; i <= 23; i++) {
				options.push(
					<option value={i} key={i}>
						{' '}
						{i18n.t('scheduler_hour_pre_string')}
						{i}
						{i18n.t('scheduler_hour_post_string')}
					</option>
				);
			}
		}
		return (
			<div className="form-group">
				<label htmlFor="selectSubInterval">
					{i18n.t('scheduler_choose_subinterval')}:
				</label>
				<select
					id="selectSubInterval"
					className="form-control col-md-6 col-sm-12 col-lg-4"
					value={this.state.schedule_subinterval}
					onChange={this._handleChange}
					data-name="schedule_subinterval"
				>
					{options}
				</select>
			</div>
		);
	}

	_renderFormButtons() {
		// TODO: make pretty
		let buttons = [];
		let primaryButtonLabel = 'extract_button_schedule';
		if (this.state.editing) {
			primaryButtonLabel = 'extract_button_save';
			buttons.push(
				<input
					className="btn btn-s m-1 gradiant"
					type="button"
					key="formbutton1"
					onClick={this._createOrUpdateTask}
					value={i18n.t(primaryButtonLabel)}
				/>
			);
			buttons.push(
				<input
					className="btn btn-s m-1 gradiantred"
					type="button"
					key="formbutton2"
					onClick={this._stopEditing}
					value={i18n.t('extract_button_stop_editing')}
					style={{ margin: 10 }}
				/>
			);
		} else {
			buttons.push(
				<input
					className="btn btn-s m-1 gradiant"
					type="button"
					key="formbutton3"
					onClick={this._createOrUpdateTask}
					value={i18n.t(primaryButtonLabel)}
					style={{ margin: 10 }}
				/>
			);
		}
		return <div className="button-group">{buttons}</div>;
	}

	_renderSheetNameBox() {
		return (
			<div className="form-group">
				<label className="col-form-label" htmlFor="documentName">
					{i18n.t('extract_label_sheet_name')}:
				</label>
				<input
					className="form-control col-md-8 col-sm-12 col-lg-8"
					id="documentName"
					type="text"
					onChange={this._handleChange}
					data-name="sheetname"
					placeholder={i18n.t('extract_sheet_name')}
					value={this.state.sheetname}
				/>
			</div>
		);
	}
	_renderJobNameBox() {
		return (
			<div className="form-group">
				<label className="col-form-label" htmlFor="jobName">
					{i18n.t('extract_label_job_name')}:
				</label>
				<input
					className="form-control col-md-8 col-sm-12 col-lg-8"
					id="jobName"
					type="text"
					onChange={this._handleChange}
					data-name="jobname"
					placeholder={i18n.t(
						'resource_' + this.state.extractableResources[this.state.resource]
					)}
					value={this.state.jobname}
				/>
			</div>
		);
	}

	_renderExtractTaskActionButtons(task) {
		const hasError =
			task.status === taskStatuses.failed || task.fortnoxtoken == null;
		const isExtracting =
			task.status === taskStatuses.running ||
			task.status === taskStatuses.queued;

		return (
			<td className="extract-action-btns">
				<button
					className="btn btn-s m-1 gradiant"
					type="button"
					title={i18n.t('extract_title_edit')}
					value={i18n.t('extract_title_edit')}
					data-id={task.id}
					onClick={() => this._editTask(task)}
				>
					<FontAwesomeIcon icon={faPenSquare} />
				</button>

				<button
					className="btn btn-s m-1 gradiant"
					title={i18n.t('extract_button_run_manually')}
					type="button"
					disabled={isExtracting}
					id={'manual-run-btn-' + task.id}
					onClick={() => this._manualRun(task, 'list')}
				>
					{isExtracting ? (
						<Spinner size="sm" variant="primary" animation="border" />
					) : (
						<>
							{hasError && (
								<FontAwesomeIcon
									className="mr-1"
									icon={faExclamationTriangle}
								/>
							)}
							<FontAwesomeIcon icon={faPlayCircle} />
						</>
					)}
				</button>
				<button
					className="btn btn-s m-1 gradiant"
					type="button"
					title={i18n.t('extract_button_job_history')}
					data-id={task.id}
					onClick={() => this._openJobHistoryModal(task)}
				>
					<FontAwesomeIcon icon={faFolder} />
				</button>

				<button
					type="button"
					className="btn btn-s m-1 gradiantred"
					title={i18n.t('extract_button_stop')}
					data-id={task.id}
					onClick={() => this._stopExtraction(task)}
				>
					<FontAwesomeIcon icon={faStopCircle} />
				</button>
				<button
					className="btn btn-s m-1 gradiantred"
					data-id={task.id}
					onClick={this._confirmDeletion}
					title={i18n.t('delete_task')}
				>
					<FontAwesomeIcon icon={faTrashAlt} />
				</button>
			</td>
		);
	}

	_renderExtractTaskListTable() {
		const rows = [];

		const indexOfLastPost =
			this.state.currentPageOfYourOwnTasks * this.state.tasksPerPage;
		const indexOfFirstPost = indexOfLastPost - this.state.tasksPerPage;
		const currentItems = this.state.list.slice(
			indexOfFirstPost,
			indexOfLastPost
		);

		let stylePosition = { position: 'absolute', bottom: -80, right: 10 };

		for (let task of currentItems) {
			const resourceName = i18n.t(
				`resource_${this.state.extractableResources[task.resource]}`
			);
			const readableSchedule = helper.prettySchedule(
				task.schedule_interval || 'none',
				task.schedule_subinterval
			);
			const lastRunDate = task.lastrun ? new Date(task.lastrun) : null;
			const lastRun = lastRunDate
				? helper.prettyDateFromJSON(lastRunDate)
				: i18n.t('extract_task_not_run');

			rows.push(
				<tr key={'task-row-' + task.id}>
					<td>{task.jobname || resourceName}</td>
					<td>{resourceName}</td>
					<td>
						<a
							className="anchor-link-color"
							href={
								'https://docs.google.com/spreadsheets/d/' +
								task.sheetid +
								'/view'
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							{task.sheetname}
						</a>
					</td>
					{this.state.mayUseSchedule && <td>{readableSchedule}</td>}
					<td title={task.errorMessage}>{lastRun}</td>
					{this._renderExtractTaskActionButtons(task)}
				</tr>
			);
		}

		return (
			<div className="table-card">
				<table>
					<thead>
						<tr>
							<th className="gradiant">{i18n.t('extract_label_job_name')}</th>
							<th className="gradiant">{i18n.t('extract_label_resource')}</th>
							<th className="gradiant">{i18n.t('extract_label_sheet_name')}</th>
							{this.state.mayUseSchedule && (
								<th className="gradiant">{i18n.t('extract_label_schedule')}</th>
							)}
							<th className="gradiant">{i18n.t('extract_label_lastrun')}</th>
							<th className="gradiant"></th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</table>
				<Pagination
					itemsPerPage={this.state.tasksPerPage}
					totalItems={this.state.list.length}
					currentPage={this.state.currentPageOfYourOwnTasks}
					paginate={this.onChangePageOfYourOwnTasks}
					stylePosition={stylePosition}
				/>
			</div>
		);
	}

	onChangePageOfYourOwnTasks(page) {
		// update state with new page of items
		this.setState({ currentPageOfYourOwnTasks: page });
	}

	_renderCreateExtractTask() {
		if (this.state.loadingCreateForm) {
			return null;
		}
		let formTitle = 'extract_title_create';
		if (this.state.editing) {
			formTitle = 'extract_title_edit';
		}

		return (
			<Modal
				show={this.state.createOrUpdateModalIsOpen}
				onHide={this._closeCreateOrUpdateModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t(formTitle)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-md-12">
						<div>
							{/*Dont edit fortnox token if you arent the owner of task*/}
							{this.state.creatorOfExtractTaskUserId !==
							this.props.session.userid ? (
								<p style={{ color: 'red' }}>
									{i18n.t('team_dont_edit_job_token')}
								</p>
							) : (
								this._renderFortnoxAccessTokenSelect()
							)}
						</div>

						<>{this._renderExtractableResources()}</>

						<SelectTaskParams
							selectedTaskParams={this.state.taskParamsSelect}
							secondSelectedTaskParams={this.state.secondTaskParamsSelect}
							handleChange={this._handleChangeSelectTaskParams.bind(this)}
							handleSecondChange={this._handleChangeSelectSecondTaskParams.bind(
								this
							)}
							resource={this.state.extractableResources[this.state.resource]}
							financialYears={this.state.financialYearsData}
						/>
						<br></br>
						<>{this._renderIntervalScheduler()}</>
						<>{this._renderSubintervalScheduler()}</>
						<>{this._renderJobNameBox()}</>
						<>{this._renderSheetNameBox()}</>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<>{this._renderFormButtons()}</>
				</Modal.Footer>
			</Modal>
		);
	}

	_renderJobHistoryModal() {
		if (this.state.loadingCreateForm) {
			return null;
		}
		return (
			<JobHistoryModal
				show={this.state.jobHistoryModalIsOpen}
				onHide={this._closeJobHistoryModal}
				taskId={this.state.jobHistoryId}
			></JobHistoryModal>
		);
	}

	_renderConfirmDeletion() {
		let buttons = [];
		let formTitle = 'extract_title_confirm_delete';
		let message = 'extract_task_confirm_deletion';
		buttons.push(
			<input
				className="btn btn-s m-1 gradiantorange"
				type="button"
				key="formbutton1"
				onClick={() => {
					this._deleteTask(false);
				}}
				value={i18n.t('extract_button_confirm_delete')}
			/>
		);
		buttons.push(
			<input
				className="btn btn-s m-1 gradiantred"
				type="button"
				key="formbutton2"
				onClick={() => {
					this._deleteTask(true);
				}}
				value={i18n.t('extract_button_confirm_deletion_of_spreadsheet')}
				style={{ marginLeft: 15 }}
			/>
		);

		return (
			<Modal
				show={this.state.confirmDeletionModalIsOpen}
				onHide={this._closeConfirmDeletionModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t(formTitle)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-md-12">
						<>{i18n.t(message)}</>
						<div className="button-group" style={{ paddingTop: 30 }}>
							{buttons}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						className="btn btn-s m-1 gradiant"
						type="button"
						key="formbutton3"
						onClick={this._closeConfirmDeletionModal}
						value={i18n.t('extract_button_cancel_delete')}
						style={{ float: 'right' }}
					/>
				</Modal.Footer>
			</Modal>
		);
	}

	_renderExtractTaskList() {
		if (this.state.loadingResourceList) {
			return <Spinner variant="primary" animation="border" />;
		}
		return (
			<div className="form-group col-md-12">
				{this._renderExtractTaskListTable()}
			</div>
		);
	}

	_renderNotifications() {
		return (
			<Toast
				show={this.state.showToast}
				onClose={this._closeToast}
				style={{
					position: 'absolute',
					top: '20%',
					left: 0,
					right: 0,
					marginLeft: 'auto',
					marginRight: 'auto',
					backgroundColor: 'rgba(255,255,255,1)',
				}}
			>
				<Toast.Header style={{ justifyContent: 'end' }}></Toast.Header>
				<Toast.Body>{this.state.toastContent}</Toast.Body>
			</Toast>
		);
	}

	_renderContent() {
		var jobNameList = [];
		var i;
		for (i = 0; i < this.state.list.length; ++i) {
			jobNameList.push(this.state.list[i].jobname);
		}

		return (
			<div>
				{this._renderCreateExtractTask()}
				{this._renderJobHistoryModal()}
				{this._renderConfirmDeletion()}
				<input
					className="btn btn-s m-1 gradiant"
					type="button"
					key="formbutton1"
					onClick={this._openCreateOrUpdateModal}
					style={{ float: 'right' }}
					value={i18n.t('extract_button_schedule')}
				/>
				<h3> {i18n.t('extract_title_list')}</h3>
				{this.props.session.customertype === 0 ? (
					<p style={{ float: 'right', marginRight: 10, marginTop: 10 }}>
						{i18n.t('you_have_x_tasks_left')}{' '}
						{this.state.tasksLeftToday.nr_of_tasks_user_can_run_today}
						{i18n.t('you_have_x_tasks_left_today')}
					</p>
				) : null}
				{this._renderExtractTaskList()}
				{/*this._renderNotifications()*/}
				<br></br>
				{this._renderTeamTables()}
				{this.renderErrorModal()}
				{this.checkIfAnyTasksAreMissingToken()}
				<div>
					{(() => {
						if (this.state.errorShouldOpen === 'fortnoxKeyError') {
							return <ErrorModal errorMessage={this.state.toastContent} />;
						}
						return null;
					})()}
				</div>
			</div>
		);
	}

	render() {
		return <form id="page-content-wrapper">{this._renderContent()}</form>;
	}

	///** */
	//** FUNCTIONS THAT HAS TO DO WITH DISPLAYING TEAMS & THEIR LOGIC BELOW*/
	///** */

	_fetchAllTeam_x_User() {
		let userid = this.props.session.userid;
		TeamService.getTeam_x_User(userid, this.props.session)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					team_x_userData: dataFromResponse,
				});
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_fetchUserInvites() {
		let userid = this.props.session.userid;
		if (userid) {
			//TODO: TEAMID IS ONLY FOR TESTING
			TeamService.getTeamOwnerDetails(userid, 413, this.props.session)
				.then((dataFromResponse) => {
					if (!this._isMounted) {
						return;
					}
					this.setState({
						isLoaded: true,
						getInvites: dataFromResponse,
					});
				})
				.catch((err) => {
					this.props.setGlobalErrorMessage(
						i18n.t('error_modal_connecting_to_api'),
						JSON.stringify(err),
						'error'
					);
				});
		}
	}

	//Fetches team, members of team, extracttasks of team info
	_fetchAllTeamSpecificInfo() {
		let dict = [];
		let promises = [];
		if (this.state.team_x_userData.length !== 0) {
			this.state.team_x_userData.forEach((t) => {
				promises.push(
					TeamService.getTeamExtractTasks(t.id, this.props.session)
				);
				promises.push(TeamService.getTeamMembers(t.id, this.props.session));
				//promises.push(TeamService.getTeamOwnerDetails(userid, t.id, this.props.session));
				promises.push(t);
				promises.push(t.id);
			});
		}
		Promise.all(promises)
			.then((dataFromResponse) => {
				for (let i = 0; i < dataFromResponse.length; i += 4) {
					dict[dataFromResponse[i + 3]] = {};
					dict[dataFromResponse[i + 3]].extractTasks = dataFromResponse[i];
					dict[dataFromResponse[i + 3]].members = dataFromResponse[i + 1];
					dict[dataFromResponse[i + 3]].team = dataFromResponse[i + 2];
					//dict[dataFromResponse[i + 3]].owner = dataFromResponse[i + 3];
				}
				if (!this._isMounted) {
					return;
				}
				this.setState({
					allTeamData: dict,
				});
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_renderCreateExtractTaskForTeam() {
		if (this.state.loadingCreateForm) {
			return null;
		}
		let formTitle = 'extract_title_create';
		if (this.state.editing) {
			formTitle = 'extract_title_edit';
		}
		return (
			<Modal
				show={this.state.createOrUpdateModalIsOpen}
				onHide={this._closeCreateOrUpdateModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t(formTitle)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-md-12">
						<>{this._renderExtractableResources()}</>
						<>{this._renderIntervalScheduler()}</>
						<>{this._renderSubintervalScheduler()}</>
						<>{this._renderJobNameBox()}</>
						<>{this._renderSheetNameBox()}</>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-success"
						type="button"
						key="formbutton1"
						onClick={this._createOrUpdateTask}
					>
						{i18n.t('extract_title_create')}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}

	_createTeamTask(teamid, extractIdArray) {
		TeamService.addExtractTaskToTeam(teamid, extractIdArray, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this._fetchAllTeamSpecificInfo();
			})

			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_creating_resource'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_prettyTeamSchedule(schedule) {
		let words = schedule.split(' ');
		if (words.length < 5) {
			return { schedule_interval: 'none', schedule_subinterval: 0 };
		}
		if (words[5] !== '*' && words[5] !== '?') {
			return { schedule_interval: 'weekly', schedule_subinterval: words[5] };
		} else if (words[3] !== '*' && words[3] !== '?') {
			return { schedule_interval: 'monthly', schedule_subinterval: words[3] };
		} else {
			return { schedule_interval: 'daily', schedule_subinterval: words[2] };
		}
	}

	onChangePageOfYourTeamTasks(page) {
		// update state with new page of items
		this.setState({ currentPageOfYourTeamTasks: page });
	}

	//För varje team du är med i (eller ägare i, render en team bricka)
	_renderTeamTables() {
		let allInfo = this.state.allTeamData;
		let reverseInfo = allInfo.reverse();

		const indexOfLastPost = this.state.currentPageOfYourTeamTasks * 3;
		const indexOfFirstPost = indexOfLastPost - 3;
		const currentItems = reverseInfo.slice(indexOfFirstPost, indexOfLastPost);

		const buttonStyle = {
			marginTop: 5,
			marginRight: 5,
			padding: 7,
		};

		return (
			<div className="container p-4" style={{ marginTop: 50 }}>
				{currentItems.map((t, index) => (
					<div key={'item_' + index}>
						<button
							className="btn btn-s m-1 gradiant"
							type="button"
							key="formbutton1"
							data-id={t.team.id}
							onClick={this._openCreateOrUpdateModal}
							style={{ float: 'right', marginTop: 20 }}
						>
							{' '}
							{i18n.t('extract_button_schedule')}
						</button>
						<div className="row">
							<h4 className="extract-team-name-title">{t.team.teamname}</h4>
							<br></br>
							<p>
								{' '}
								Owner:{' '}
								{(() => {
									const owner = t.members.find(
										({ id }) => id === t.team.ownerid
									);
									return owner.email;
								})()}
							</p>

							<table key={t.team.id} className="team-table-item">
								<thead>
									<tr>
										<th className="gradiant">
											{i18n.t('extract_label_job_name')}
										</th>
										<th className="gradiant">
											{i18n.t('extract_label_resource')}
										</th>
										<th className="gradiant">
											{i18n.t('extract_label_sheet_name')}
										</th>
										<th className="gradiant">
											{i18n.t('extract_label_schedule')}
										</th>
										<th className="gradiant">
											{i18n.t('extract_label_lastrun')}
										</th>
										<th className="gradiant"></th>
									</tr>
								</thead>
								<tbody>
									{t.extractTasks.map((et) => (
										<tr key={et.id}>
											<td>{et.jobname}</td>
											<td>
												{(() => {
													if (this.state.extractableResources !== null) {
														return i18n.t(
															'resource_' +
																this.state.extractableResources[et.resource]
														);
													}
												})()}
												{}
											</td>
											<td>
												<a
													className="anchor-link-color"
													href={
														'https://docs.google.com/spreadsheets/d/' +
														et.sheetid +
														'/view'
													}
													target="_blank"
													rel="noopener noreferrer"
												>
													{et.sheetname}
												</a>
											</td>

											<td>
												{(() => {
													//Render the schedule
													if (et.schedule === '') {
														return i18n.t('scheduler_none');
													}
													let result = this._prettyTeamSchedule(et.schedule);

													let readableSchedule = helper.prettySchedule(
														result.schedule_interval,
														result.schedule_subinterval
													);
													return readableSchedule;
												})()}
											</td>
											<td>
												{(() => {
													if (et.lastrun === null) {
														return i18n.t('extract_task_not_run');
													}
													return et.lastrun;
												})()}
											</td>
											<td>
												<button
													type="button"
													className="btn btn-s m-1 gradiant"
													title={i18n.t('extract_title_edit')}
													data-id={et.id}
													data-teamid={t.team.id}
													onClick={() => this._editTeamTask(et, t.team.id)}
												>
													<FontAwesomeIcon icon={faPenSquare} />
												</button>
												<button
													className="btn btn-s m-1 gradiant"
													type="button"
													title={i18n.t('extract_button_run_manually')}
													data-id={et.id}
													id={'manual-arun-btn-' + et.id}
													onClick={() => this._manualRun(et, 'team')}
												>
													{(() => {
														if (
															et.status === taskStatuses.running ||
															et.status === taskStatuses.queued
														) {
															return (
																<Spinner
																	size="sm"
																	variant="primary"
																	animation="border"
																/>
															);
														} else if (et.status === taskStatuses.failed)
															return (
																<div>
																	<FontAwesomeIcon
																		icon={faExclamationTriangle}
																	/>
																</div>
															);
														else return <FontAwesomeIcon icon={faPlayCircle} />;
													})()}
												</button>
												<button
													className="btn btn-s m-1 gradiant"
													type="button"
													data-id={et.id}
													onClick={() => this._openJobHistoryModal(et)}
													style={buttonStyle}
													title={i18n.t('extract_button_job_history')}
												>
													<FontAwesomeIcon icon={faFolder} />
												</button>
												<button
													className="btn btn-s m-1 gradiantred"
													type="button"
													title="Stop"
													data-id={et.id}
													onClick={() => this._stopExtraction(et)}
													style={buttonStyle}
												>
													<FontAwesomeIcon icon={faStopCircle} />
												</button>
												<button
													className="btn btn-s m-1 gradiantred"
													data-id={et.id}
													onClick={this._confirmDeletion}
													style={buttonStyle}
												>
													<FontAwesomeIcon icon={faTrashAlt} />
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{/* 		<Pagination
							itemsPerPage={this.state.tasksPerPage}
							totalItems={t.extractTasks.length}
							currentPage={this.state.currentPageOfYourTeamTasks}
							paginate={this.onChangePageOfYourTeamTasks}
							stylePosition={stylePosition}
						/> */}
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Extract);
