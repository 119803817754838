import React from 'react';
import { connect } from 'react-redux';
import Authentication from '../../services/Authentication';
import i18n from '../../services/i18n';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

class GoogleOauth extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this._isMounted = true;
        const search = this.props.location.search;
        const googleOauthCode = new URLSearchParams(search).get('code');
        const scope = new URLSearchParams(search).get('scope');
        const authuser = new URLSearchParams(search).get('authuser');
        const prompt = new URLSearchParams(search).get('prompt');
        const state = new URLSearchParams(search).get('state');
        const error = new URLSearchParams(search).get('error');
        const errorDescription = new URLSearchParams(search).get(
            'error_description'
        );

        if (googleOauthCode) {
            Authentication.oauthGoogle(googleOauthCode, scope, authuser, prompt).then((data) => {
                this.props.setSession(data);
                document.cookie = 'Session=' + this.props.session.guid + '; path=/';
                this.props.history.push('/');
            }).catch(e => {
                //this.props.history.push('/');
            });
            /*
            SettingsService.createFortnoxToken(
                { fortnoxAuthCode: fortnoxAuthCode, state: state },
                this.props.session
            )
                .then(() => {
                    if (!this._isMounted) {
                        return;
                    }
                    this.props.history.push('/settings'); // Remove query params. Might exist a better way to do this
                    this._getFortnoxAccessToken();
                })
                .catch((err) => {
                    if (err.message && err.message.error_description) {
                        this.props.setGlobalErrorMessage(
                            i18n.t('error_modal_fortnox_error'),
                            err.message.error_description,
                            'error'
                        );
                    }
                    this.props.history.push('/settings');
                    this._getFortnoxAccessToken();
                });*/
        } else if (error) {
            this.props.setGlobalErrorMessage(
                i18n.t('error_modal_fortnox_error'),
                errorDescription,
                'error'
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleOauth);
