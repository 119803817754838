import React from 'react';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import i18n from '../../services/i18n';
import SettingsService from '../../services/SettingsService';
import JobHistoryData from './JobHistoryData';
import eventBus from '../../services/EventBus';
import { messageTypes } from '../../services/MessageHandler';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

class JobHistoryModal extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			executionLogs: [],
		};
		this.updateExecutionLog = this.updateExecutionLog.bind(this);
		this.addExecutionLog = this.addExecutionLog.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		eventBus.on(messageTypes.EXECUTION_LOG_UPDATE, this.updateExecutionLog);
		eventBus.on(messageTypes.EXECUTION_LOG_CREATION, this.addExecutionLog);
	}

	componentWillUnmount() {
		this._isMounted = false;

		eventBus.remove(messageTypes.EXECUTION_LOG_UPDATE, this.updateExecutionLog);
		eventBus.remove(messageTypes.EXECUTION_LOG_CREATION, this.addExecutionLog);
	}

	getSnapshotBeforeUpdate(prevProps) {
		return { didOpen: this.props.show && prevProps.show !== this.props.show };
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot.didOpen) {
			this.setState({
				loading: true,
			});
			try {
				const executionLogs = await SettingsService.getExecutionLog(
					this.props.taskId,
					this.props.session
				);
				if (this._isMounted) {
					this.setState({
						executionLogs: executionLogs.reverse(),
						loading: false,
					});
				}
			} catch (error) {
				console.error(error);
			}
		}
	}

	updateExecutionLog(data) {
		const id = data.id;
		const updates = data;
		const executionLogs = [...this.state.executionLogs];
		const executionLogIndex = executionLogs.findIndex((l) => l.id === id);
		if (executionLogIndex > -1) {
			executionLogs[executionLogIndex] = {
				...executionLogs[executionLogIndex],
				...updates,
			};
			if (this._isMounted) {
				this.setState({ executionLogs });
			}
		}
	}

	addExecutionLog(data) {
		const executionLogs = this.state.executionLogs;
		const isSameJob = this.props.taskId === data.jobid;
		const isInList = executionLogs.some((l) => l.id === data.id);

		if (this._isMounted && isSameJob && !isInList) {
			this.setState({
				executionLogs: [data, ...executionLogs],
			});
		}
	}

	render() {
		return (
			<div>
				<Modal show={this.props.show} onHide={this.props.onHide} scrollable>
					<Modal.Header closeButton>
						<Modal.Title>{i18n.t('extract_job_history_title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.loading ? (
							<div className="center">
								<Spinner variant="primary" animation="border" />
							</div>
						) : (
							<div className="col-md-12">
								{this.state.executionLogs.length ? (
									<JobHistoryData data={this.state.executionLogs} />
								) : (
									<div>{i18n.t('execution_log_no_history')}</div>
								)}
							</div>
						)}
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(JobHistoryModal);
