import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import Select from 'react-select';

import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

const FilterInvoicesList = [
	{ value: 'cancelled', label: i18n.t('filter_cancelled') },
	{ value: 'fullypaid', label: i18n.t('filter_fyllypaid') },
	{ value: 'unpaid', label: i18n.t('filter_unpaid') },
	{ value: 'unpaidoverdue', label: i18n.t('filter_unpaid_overdue') },
	{ value: 'unbooked', label: i18n.t('filter_unbooked') },
];

const FilterOffersList = [
	{ value: 'cancelled', label: i18n.t('filter_cancelled') },
	{ value: 'expired', label: i18n.t('filter_expired') },
	{ value: 'ordercreated', label: i18n.t('filter_order_created') },
	{ value: 'ordernotcreated', label: i18n.t('filter_order_not_created') },
];

const FilterOrdersList = [
	{ value: 'cancelled', label: i18n.t('filter_cancelled') },
	{ value: 'expired', label: i18n.t('filter_expired') },
	{ value: 'invoicecreated', label: i18n.t('filter_invoice_created') },
	{ value: 'invoicenotcreated', label: i18n.t('filter_invoice_not_created') },
];

const FilterTaxReductionsList = [
	{ value: 'invoices', label: i18n.t('filter_invoices') },
	{ value: 'orders', label: i18n.t('filter_orders') },
	{ value: 'offers', label: i18n.t('filter_offers') },
];

const FilterArticlesOrCustomersList = [
	{ value: 'active', label: i18n.t('filter_active') },
	{ value: 'inactive', label: i18n.t('filter_inactive') },
];

const FilterContractsList = [
	{ value: 'active', label: i18n.t('filter_active') },
	{ value: 'inactive', label: i18n.t('filter_inactive') },
	{ value: 'finished', label: i18n.t('filter_finished') },
];

const SortByInvoicesList = [
	{ value: 'total', label: i18n.t('sortby_total') },
	{ value: 'customername', label: i18n.t('sortby_customername') },
	{ value: 'customernumber', label: i18n.t('sortby_customernumber') },
	{ value: 'documentnumber', label: i18n.t('sortby_documentnumber') },
	{ value: 'invoicedate', label: i18n.t('sortby_invoicedate') },
	{ value: 'ocr', label: 'OCR' },
];

class SelectTaskParams extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			queryParam: 'default',
			secondQueryParam: 'default',
			paramValue: this.props.selectedTaskParams,
			secondParamValue: this.props.selectedSecondTaskParam,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		let r = this.props.resource;
		this.helperChangeQueryParamStateBasedOnResource(r);
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	helperChangeQueryParamStateBasedOnResource(r) {
		if (this.isResourceFinancialYears(r)) {
			this.setState({
				queryParam: 'financialyear',
			});
		} else if (
			this.isResourceOrders(r) ||
			this.isResourceOffers(r) ||
			this.isResourceContracts(r) ||
			this.isResourceTaxReductions(r) ||
			this.isResourceArticlesOrCustomers(r) ||
			this.isResourceInvoiceOrSupplierInvoice(r)
		) {
			this.setState({
				queryParam: 'filter',
			});
		}
	}

	componentDidUpdate(prevProps) {
		let r = this.props.resource;
		if (prevProps.resource !== this.props.resource) {
			this.helperChangeQueryParamStateBasedOnResource(r);
		}
	}

	isResourceInvoiceOrSupplierInvoice(r) {
		if (r === 'invoices' || r === 'supplierinvoices') {
			return true;
		}
		return false;
	}

	isResourceOffers(r) {
		if (r === 'offers') {
			return true;
		}
		return false;
	}

	isResourceContracts(r) {
		if (r === 'contracts') {
			return true;
		}
		return false;
	}

	isResourceOrders(r) {
		if (r === 'orders') {
			return true;
		}
		return false;
	}
	isResourceTaxReductions(r) {
		if (r === 'taxreductions') {
			return true;
		}
		return false;
	}
	isResourceInvoices(r) {
		if (r === 'invoices') {
			return true;
		}
		return false;
	}
	isResourceArticlesOrCustomers(r) {
		if (r === 'articles' || r === 'customers') {
			return true;
		}
		return false;
	}

	isResourceFinancialYears(r) {
		if (
			r === 'accounts' ||
			r === 'vouchers' ||
			(typeof r !== 'undefined' && r.startsWith('sie'))
		) {
			return true;
		}
		return false;
	}

	renderSelectSortBy() {
		const dropDownArr = [];
		dropDownArr.push({
			value: -1,
			label: 'Ingen',
		});

		SortByInvoicesList.forEach((i) => {
			dropDownArr.push({
				value: i.value,
				label: i.label,
			});
		});
		return (
			<div>
				<br />
				<label style={{ display: 'flex' }} htmlFor="selectTaskParams">
					{i18n.t('sortby')}
				</label>
				<Select
					id="selectTaskParams"
					value={this.props.secondSelectedTaskParams}
					onChange={(val) => this.props.handleSecondChange(val, 'sortby')}
					data-value={this.props.secondSelectedTaskParams}
					options={dropDownArr}
					className="selectTaskParams-drop-down"
				/>
			</div>
		);
	}

	render() {
		let r = this.props.resource;
		const dropDownArr = [];
		dropDownArr.push({
			value: -1,
			label: i18n.t('none_selected'),
		});

		if (
			this.isResourceFinancialYears(r) ||
			this.isResourceInvoiceOrSupplierInvoice(r) ||
			this.isResourceOrders(r) ||
			this.isResourceContracts(r) ||
			this.isResourceTaxReductions(r) ||
			this.isResourceOffers(r) ||
			this.isResourceArticlesOrCustomers(r)
		) {
			if (this.isResourceInvoiceOrSupplierInvoice(r)) {
				FilterInvoicesList.forEach((i) => {
					dropDownArr.push({
						value: i.value,
						label: i.label,
					});
				});
			} else if (this.isResourceOffers(r)) {
				FilterOffersList.forEach((i) => {
					dropDownArr.push({
						value: i.value,
						label: i.label,
					});
				});
			} else if (this.isResourceOrders(r)) {
				FilterOrdersList.forEach((i) => {
					dropDownArr.push({
						value: i.value,
						label: i.label,
					});
				});
			} else if (this.isResourceContracts(r)) {
				FilterContractsList.forEach((i) => {
					dropDownArr.push({
						value: i.value,
						label: i.label,
					});
				});
			} else if (this.isResourceArticlesOrCustomers(r)) {
				FilterArticlesOrCustomersList.forEach((i) => {
					dropDownArr.push({
						value: i.value,
						label: i.label,
					});
				});
			} else if (this.isResourceTaxReductions(r)) {
				FilterTaxReductionsList.forEach((i) => {
					dropDownArr.push({
						value: i.value,
						label: i.label,
					});
				});
			} else if (this.isResourceFinancialYears(r)) {
				this.props.financialYears.forEach((i) => {
					dropDownArr.push({
						value: i.financial_year_id,
						label:
							i18n.t('financial_years_from') +
							i.from_date +
							i18n.t('financial_years_to') +
							i.to_date,
					});
				});
			}
			return (
				<div>
					<label style={{ display: 'flex' }} htmlFor="selectTaskParams">
						{this.state.queryParam === 'financialyear'
							? i18n.t('select_year') + ':'
							: i18n.t('select_filter') + ':'}
					</label>
					<Select
						id="selectTaskParams"
						value={this.props.selectedTaskParams}
						onChange={(val, q) =>
							this.props.handleChange(val, this.state.queryParam)
						}
						data-value={this.props.selectedTaskParams}
						options={dropDownArr}
						className="selectTaskParams-drop-down"
					/>
					{this.isResourceInvoices(r) ? this.renderSelectSortBy() : <></>}
				</div>
			);
		} else return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTaskParams);
