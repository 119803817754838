export function mapStateToProps(state) {
	return {
		session: state.session,
	};
}

export function mapDispatchToProps(dispatch) {
	return {
		setSession: (session) =>
			dispatch({
				type: 'SET_SESSION',
				session: session,
			}),
		dispatch: (e) => dispatch(e),
		setGlobalErrorMessage: (
			errorDescription,
			errorMessage,
			type = '',
			errorObject
		) =>
			dispatch({
				type: 'SET_ERROR_MESSAGE',
				errorDescription,
				errorMessage,
				errorType: type,
				errorObject,
			}),
	};
}
