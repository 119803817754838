import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../App.css';
import AuthenticationService from '../services/Authentication';
import helper from '../services/helper';
import i18n from '../services/i18n';
import SettingsService from '../services/SettingsService';
import InviteModal from './Team/InviteModal';
import { Modal } from 'react-bootstrap';
import ErrorModal from './ErrorModal';
import { mapDispatchToProps } from '../utils/prop-utils';
import TermsOfServiceModal from './TermsOfServiceModal';

export function mapStateToProps(state) {
	return {
		session: state.session,
		globalErrorMessage: state.globalErrorMessage,
	};
}

class Navbar extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			confirmLogoutModalIsOpen: false,
		};
		this._openConfirmLogoutModal = this._openConfirmLogoutModal.bind(this);
		this._closeConfirmLogoutModal = this._closeConfirmLogoutModal.bind(this);
	}

	_toggleSidebar = () => {
		var wrapper = document.getElementById('wrapper');
		if (wrapper.classList.contains('toggled')) {
			wrapper.classList.remove('toggled');
		} else {
			wrapper.classList.add('toggled');
		}
	};

	_changeLanguage = (lang) => {
		document.cookie = 'language=' + lang + '; path=/';
		window.location.reload();
	};

	/*_closeSidebar = () => {       
        var wrapper = document.getElementById("wrapper");
        if(wrapper.classList.contains("toggled")){
            wrapper.classList.remove("toggled");
        }      
    }*/

	_checkIfNewUser() {
		return (
			<div>
				{(() => {
					if (
						typeof this.props.session.licenseAcceptDate == 'undefined' ||
						this.props.session.licenseAcceptDate == null
					) {
						return <TermsOfServiceModal />;
					}
					return null;
				})()}
			</div>
		);
	}

	_logout = () => {
		AuthenticationService.logout(this.props.session)
			.then(() => {
				window.location.href = '/';
			})
			.catch(() => {
				window.location.href = '/';
			});
	};

	_openConfirmLogoutModal() {
		this.setState({
			confirmLogoutModalIsOpen: true,
		});
	}
	_closeConfirmLogoutModal() {
		this.setState({
			confirmLogoutModalIsOpen: false,
		});
	}

	_renderUserBlocked() {
		return (
			<div>
				{(() => {
					if (this.props.session.status === 'blocked') {
						return (
							<ErrorModal errorMessage={i18n.t('error_modal_user_blocked')} />
						);
					}
					return null;
				})()}
			</div>
		);
	}

	_renderConfirmLogoutModal() {
		return (
			<Modal
				show={this.state.confirmLogoutModalIsOpen}
				onHide={this._closeConfirmLogoutModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t('common_sign_out')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-md-12">{i18n.t('navbar_confirm_logout')}</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-s m-1 gradiantred" onClick={this._logout}>
						{i18n.t('common_sign_out')}
					</button>
					<button
						className="btn btn-s m-1 gradiant"
						onClick={this._closeConfirmLogoutModal}
					>
						{i18n.t('navbar_cancel_logout')}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}

	_setLanguage = () => {
		if (
			this.props.session &&
			Object.entries(this.props.session).length &&
			!helper.getCookie('language')
		) {
			SettingsService.getSettings(this.props.session)
				.then((settings) => {
					if (
						this._isMounted &&
						i18n.language !== settings.languagePreference
					) {
						i18n.changeLanguage(settings.languagePreference);
						this._changeLanguage(settings.languagePreference);
					}
				})
				.catch((err) => {
					this.props.setGlobalErrorMessage(
						i18n.t('error_modal_connecting_to_api'),
						JSON.stringify(err),
						'error'
					);
				});
		}
	};

	componentDidMount() {
		this._isMounted = true;
		this._setLanguage();
	}

	_renderError() {
		const errorDescription = this.props.globalErrorMessage.errorDescription;
		const errorMessage = this.props.globalErrorMessage.errorMessage;
		const type = this.props.globalErrorMessage.errorType;
		const errorObject = this.props.globalErrorMessage.errorObject;
		return errorDescription?.length || errorObject ? (
			<ErrorModal
				errorDescription={errorDescription}
				errorMessage={errorMessage}
				errorType={type}
				errorObject={errorObject}
			/>
		) : null;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	renderNavbar() {
		//document.getElementById('root').addEventListener('click', this._closeSidebar());
		const errorDescription = this.props.globalErrorMessage.errorDescription;
		const errorMessage = this.props.globalErrorMessage.errorMessage;
		const type = this.props.globalErrorMessage.errorType;
		const errorObject = this.props.globalErrorMessage.errorObject;
		const adminMenu = this.props.session.admin ? (
			<li
				className="nav-item active"
				data-toggle="collapse"
				data-target="#navbarNav"
				aria-controls="navbarNav"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<Link to="/admin" className="nav-link">
					{i18n.t('common_admin')}
					<span className="sr-only">(current)</span>{' '}
				</Link>
			</li>
		) : null;
		return (
			<div>
				{errorDescription?.length || errorObject ? (
					<ErrorModal
						errorDescription={errorDescription}
						errorMessage={errorMessage}
						errorType={type}
						errorObject={errorObject}
					/>
				) : null}
				<nav className="navbar navbar-expand-lg sticky-top navbar-light bg-white border-bottom shadow-sm">
					<div className="container-fluid" style={{ color: 'black' }}>
						<Link to="/" className="navbar-brand">
							<img
								src={require('../images/loggaf2S.png')}
								alt="fortnox2google logo"
							/>
						</Link>
						{this.props.session.guid ? (
							<button
								className="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#navbarNav"
								aria-controls="navbarNav"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>
						) : null}
						<div className="collapse navbar-collapse" id="navbarNav">
							{this.props.session.guid ? (
								<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
									<li
										className="nav-item"
										data-toggle="collapse"
										data-target="#navbarNav"
										aria-controls="navbarNav"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<Link to="/" className="nav-link active">
											{i18n.t('navbar_start')}
										</Link>
									</li>
									{adminMenu}
									<li
										className="nav-item"
										data-toggle="collapse"
										data-target="#navbarNav"
										aria-controls="navbarNav"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<Link to="/extract" className="nav-link">
											{i18n.t('common_extract')}
											<span className="sr-only">(current)</span>{' '}
										</Link>
									</li>
									<li
										className="nav-item"
										data-toggle="collapse"
										data-target="#navbarNav"
										aria-controls="navbarNav"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<Link to="/settings" className="nav-link">
											{i18n.t('instructions_company_title')}
										</Link>
									</li>
									{/* 	<li
										className="nav-item"
										data-toggle="collapse"
										data-target="#navbarNav"
										aria-controls="navbarNav"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<Link to="/faq" className="nav-link">
											FAQ
										</Link>
									</li> */}
									<li className="nav-item dropdown">
										<span
											className="nav-link dropdown-toggle"
											id="dropdownMenuLink"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												className="bi bi-person"
												viewBox="0 0 16 16"
											>
												<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
											</svg>
										</span>

										<ul
											className="dropdown-menu dropdown-menu-right"
											aria-labelledby="dropdownMenuLink"
										>
											<li>
												<Link to="/usersettings" className="dropdown-item">
													{i18n.t('common_settings')}
												</Link>
											</li>

											<li>
												<button
													className="dropdown-item"
													onClick={this._openConfirmLogoutModal}
												>
													{i18n.t('common_sign_out')}
												</button>
											</li>
										</ul>
									</li>

									<div>
										{(() => {
											if (this.props.session.status === 'blocked') {
												return (
													<ErrorModal
														errorMessage={i18n.t('error_modal_user_blocked')}
													/>
												);
											}
											return null;
										})()}
									</div>
									{this._checkIfNewUser()}
									<InviteModal />
								</ul>
							) : null}
						</div>
					</div>
				</nav>
			</div>
		);
	}

	render() {
		return (
			<div>
				{this._renderError()}
				{this._renderConfirmLogoutModal()}
				{this._renderUserBlocked()}
				{this.renderNavbar()}
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
