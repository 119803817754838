import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Login from './components/login';
import DashBoard from './components/dashboard';
import Settings from './components/settings';
import Instructions from './components/instructions/Instructions';
import FAQ from './components/instructions/FAQ.js';
import About from './components/about';
import Admin from './components/admin';
import Extract from './components/extract';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TeamsPage from './components/Team/TeamsPage';
import UserSettings from './components/settings/UserSettings';
import GoogleOauth from './components/googleoauth';
import { mapStateToProps, mapDispatchToProps } from './utils/prop-utils';

import { connect, Provider } from 'react-redux';
import { requireAuthentication } from './middleware/AuthMiddleware';

import './App.css';
import './styles/_custom.scss';

class App extends Component {
	render() {
		return (
			<div className="d-flex flex-column min-vh-100">
				<Provider store={this.props.store}>
					<Router>
						{this.props.session.guid ? (
							<Navbar session={this.props.session} />
						) : null}
						<div className="container">
							<Route path="/login" exact component={Login} />
							<Route path="/" exact component={DashBoard} />
							<Route
								path="/settings"
								exact
								component={requireAuthentication(Settings)}
							/>
							<Route
								path="/admin"
								exact
								component={requireAuthentication(Admin)}
							/>
							<Route path="/instructions" exact component={Instructions} />
							<Route path="/faq" exact component={requireAuthentication(FAQ)} />

							<Route path="/about" exact component={About} />
							<Route path="/googleoauth" exact component={GoogleOauth} />
							<Route
								path="/usersettings"
								exact
								component={requireAuthentication(UserSettings)}
							/>

							<Route
								path="/extract"
								exact
								component={requireAuthentication(Extract)}
							/>
							<Route
								path="/team"
								exact
								component={requireAuthentication(TeamsPage)}
							/>
						</div>
					</Router>
					<ToastContainer autoClose={3000} />
					<Footer></Footer>
				</Provider>
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
