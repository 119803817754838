import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import AdminService from '../../services/AdminService';
import ExtractService from '../../services/ExtractService';
import ExtractTaskList from './ExtractTaskList';
import Spinner from 'react-bootstrap/Spinner';
import eventBus from '../../services/EventBus';
import { messageTypes } from '../../services/MessageHandler';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import Pagination from '../Pagination';
import UserTableBody from './UserTableBody';

class Admin extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			extractTaskListModalIsOpen: false,
			users: [],
			resources: [],
			userXcustomerType: {},
			blockOrUnblockModalIsOpen: false,
			userToBlock: {},
			blockOrUnblock: {},
			pageOfItems: [],
			usersPerPage: 10,
			currentPage: 1,
		};
		this._handleCustomerTypeChange = this._handleCustomerTypeChange.bind(this);
		this._openExtractTaskListModal = this._openExtractTaskListModal.bind(this);
		this._openConfirmBlockOrUnblockModal =
			this._openConfirmBlockOrUnblockModal.bind(this);
		this._closeBlockOrUnblockModal = this._closeBlockOrUnblockModal.bind(this);
		this._closeExtractTaskListModal =
			this._closeExtractTaskListModal.bind(this);
		this._updateUserToBlockedOrUnblocked =
			this._updateUserToBlockedOrUnblocked.bind(this);
		this.wsUpdateUser = this.wsUpdateUser.bind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	//For websockets and re-rendering
	wsUpdateUser = (data) => {
		const taskList = this.state.users;
		const taskIndex = taskList.findIndex((user) => {
			return user.id === this.state.userToBlock.id;
		});
		if (taskIndex > -1) {
			taskList[taskIndex] = {
				...taskList[taskIndex],
				...this.state.blockOrUnblock,
			};
			if (this._isMounted) {
				this.setState({ users: taskList });
			}
		}
	};

	componentDidMount() {
		this._isMounted = true;
		let promises = [];
		promises.push(AdminService.getUsers(this.props.session));
		promises.push(ExtractService.getExtractableResources(this.props.session));
		Promise.all(promises)
			.then((stuff) => {
				let users = stuff[0];
				let resources = stuff[1];

				if (!this._isMounted) {
					return;
				}

				let userXcustomerType = {};
				for (const u of users) {
					userXcustomerType[u.id] = u.customer_type;
				}

				this.setState({
					users: users,
					resources: resources,
					userXcustomerType: userXcustomerType,
					loading: false,
				});
			})
			.catch((err) => {
				this.setState({
					loading: false,
					errorMessage: err.message ? err.message : 'unknown api error', //TODO: display somewhere
				});
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
		eventBus.on(messageTypes.ADMIN_UPDATE_USER, this.wsUpdateUser);
	}

	componentWillUnmount() {
		this._isMounted = false;
		eventBus.on(messageTypes.ADMIN_UPDATE_USER, this.wsUpdateUser);
	}

	_openExtractTaskListModal(event) {
		let userId = event.target.dataset.userid;
		this.setState({
			extractTaskListModalIsOpen: true,
			extractTaskListForUserWithId: userId,
		});
	}

	_openConfirmBlockOrUnblockModal(user, statusObj) {
		this.setState({
			blockOrUnblock: statusObj,
			userToBlock: user,
			blockOrUnblockModalIsOpen: true,
			extractTaskListForUserWithId: user.id,
		});
	}

	_closeExtractTaskListModal() {
		this.setState({
			extractTaskListModalIsOpen: false,
		});
	}

	_closeBlockOrUnblockModal() {
		this.setState({
			blockOrUnblockModalIsOpen: false,
		});
	}

	_renderExtractTaskListModal() {
		return (
			<Modal
				show={this.state.extractTaskListModalIsOpen}
				onHide={this._closeExtractTaskListModal}
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t('admin_list_extract_tasks')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ overflowX: 'auto' }}>
						<ExtractTaskList
							userid={this.state.extractTaskListForUserWithId}
							resources={this.state.resources}
						/>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
	_renderConfirmBlockOrUnBlockModal() {
		return (
			<Modal
				show={this.state.blockOrUnblockModalIsOpen}
				onHide={this._closeBlockOrUnblockModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.state.userToBlock.status === 'ok'
							? i18n.t('admin_block_btn')
							: i18n.t('admin_unblock_btn')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.state.userToBlock.status === 'ok'
						? i18n.t('admin_confirm_block_user_msg')
						: i18n.t('admin_confirm_unblock_user_msg')}
				</Modal.Body>
				<Modal.Footer>
					<input
						className="btn btn-s m-1 gradiantred"
						data-userid={this.state.extractTaskListForUserWithId}
						type="button"
						onClick={() => this._updateUserToBlockedOrUnblocked()}
						value={
							this.state.userToBlock.status === 'ok'
								? i18n.t('admin_block_btn')
								: i18n.t('admin_unblock_btn')
						}
						style={{ marginLeft: 10 }}
					/>
					<input
						className="btn btn-s m-1 gradiant"
						type="button"
						onClick={this._closeBlockOrUnblockModal}
						value={i18n.t('navbar_cancel_logout')}
						style={{ marginLeft: 10 }}
					/>
				</Modal.Footer>
			</Modal>
		);
	}

	_updateUserToBlockedOrUnblocked() {
		AdminService.blockOrUnblockUser(
			this.state.extractTaskListForUserWithId,
			this.props.session
		)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this.wsUpdateUser(this.state.userToBlock);
				this._closeBlockOrUnblockModal();
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	_handleCustomerTypeChange(event) {
		let customerType = event.target.value;
		let userId = event.target.dataset.userid;
		let userXcustomerType = this.state.userXcustomerType;
		userXcustomerType[userId] = customerType;
		this.setState({
			userXcustomerType: userXcustomerType,
		});
		AdminService.updateCustomerType(userId, customerType, this.props.session)
			.then(() => {
				if (this.props.session.userid === parseInt(userId)) {
					this.props.setSession({
						...this.props.session,
						customertype: parseInt(customerType),
					});
				}
				if (!this._isMounted) {
					return;
				}
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_success'),
					i18n.t('admin_changed_customer_type'),
					'success'
				);
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	setCurrentPage(pageNumber) {
		this.setState({
			currentPage: pageNumber,
		});
	}

	_renderUsers() {
		const indexOfLastPost = this.state.currentPage * this.state.usersPerPage;
		const indexOfFirstPost = indexOfLastPost - this.state.usersPerPage;
		const currentItems = this.state.users.slice(
			indexOfFirstPost,
			indexOfLastPost
		);

		return (
			<div style={{ position: 'relative' }}>
				<table>
					<thead>
						<tr>
							<th className="gradiant">
								{i18n.t('admin_name')}{' '}
								<div style={{ float: 'right', fontWeight: 'bold' }}>[id]</div>
							</th>
							<th className="gradiant">{i18n.t('admin_email')}</th>
							<th className="gradiant">{i18n.t('admin_customer_type')}</th>
							<th className="gradiant">{i18n.t('admin_modified')}</th>
							<th className="gradiant"></th>
						</tr>
					</thead>
					<tbody>
						<UserTableBody
							items={currentItems}
							_openExtractTaskListModal={this._openExtractTaskListModal}
							_openConfirmBlockOrUnblockModal={
								this._openConfirmBlockOrUnblockModal
							}
							_handleCustomerTypeChange={this._handleCustomerTypeChange}
							userXcustomerType={this.state.userXcustomerType}
						/>
					</tbody>
				</table>
			</div>
		);
	}

	onChangePage(page) {
		// update state with new page of items
		this.setState({ currentPage: page });
	}

	_renderContent() {
		if (this.state.loading) {
			return <Spinner variant="primary" animation="border" />;
		} else {
			return (
				<>
					{this._renderExtractTaskListModal()}
					{this._renderConfirmBlockOrUnBlockModal()}
					{this._renderUsers()}
				</>
			);
		}
	}

	render() {
		let stylePosition = {
			position: 'relative',
			top: 20,
			float: 'right',
		};
		return (
			<div id="row" style={{ marginTop: 70 }}>
				{this._renderContent()}
				<Pagination
					itemsPerPage={this.state.usersPerPage}
					totalItems={this.state.users.length}
					currentPage={this.state.currentPage}
					paginate={this.onChangePage}
					stylePosition={stylePosition}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
