import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import helper from '../../services/helper';
import AdminService from '../../services/AdminService';
import Spinner from 'react-bootstrap/Spinner';
import { mapStateToProps, mapDispatchToProps } from '../../utils/prop-utils';
import ErrorModalLink from '../shared/ErrorModalLink';

class ExtractTaskList extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			extractTaskList: [],
			currentPage: [],
			postsPerPage: 2,
			exampleItems: [...Array(150).keys()].map((i) => ({
				id: i + 1,
				name: 'Item ' + (i + 1),
			})),
			pageOfItems: [],
		};
		this._renderExtractTaskList = this._renderExtractTaskList.bind(this);

		// an example array of items to be paged
	

		// bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
		this.onChangePage = this.onChangePage.bind(this);
	}

	componentDidMount() {

		AdminService.getExtractTasks(this.props.userid, this.props.session)
			.then((extractTaskList) => {
				this.setState({
					loading: false,
					extractTaskList: extractTaskList,
				});
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onChangePage(pageOfItems) {
		// update state with new page of items
		this.setState({ pageOfItems: pageOfItems });
	}

	_renderExtractTaskList() {

		let list = [];
		for (const e of this.state.extractTaskList) {
			list.push(
				<tr key={e.id}>
					<td>{i18n.t('resource_' + this.props.resources[e.resource])}</td>
					<td>{e.jobname}</td>
					<td>{helper.prettyDateFromJSON(e.lastrun)}</td>
					<td>{e.errormessage && <ErrorModalLink error={e.errormessage} />}</td>
					<td>
						{helper.prettySchedule(e.schedule_interval, e.schedule_subinterval)}
					</td>
					<td>{e.status}</td>

				</tr>
			);
		}
		return (
			<>
				<table className="mb-4">
					<thead>
						<tr>
							<th className="gradiant">{i18n.t('admin_extract_task_list_resource')}</th>
							<th className="gradiant">{i18n.t('admin_extract_task_list_job_name')}</th>
							<th className="gradiant">{i18n.t('admin_extract_task_list_lastrun')}</th>
							<th className="gradiant">{i18n.t('admin_extract_task_list_error_message')}</th>
							<th className="gradiant">{i18n.t('admin_extract_task_list_schedule')}</th>
							<th className="gradiant">Status</th>

						</tr>
					</thead>
					<tbody>{list}</tbody>
				</table>

				<div className="container mt-5">
					<div>
						<div className="container">
							<div className="text-center">
								{this.state.pageOfItems.map((item) => (
									<div key={item.id}>{item.name}</div>
								))}
							
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	render() {
		if (this.state.loading) {
			return <Spinner variant="primary" animation="border" />;
		} else {
			return this._renderExtractTaskList();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtractTaskList);
