import i18next from 'i18next';
import helper from './helper';

i18next.init({
	interpolation: {
		escapeValue: false,
	},
	lng: helper.getCookie('language') || 'sv',
	resources: {
		en: {
			translation: {
				common_extract: 'Extract',
				common_instructions: 'Instructions',
				common_about: 'About',
				common_settings: 'Settings',
				common_home: 'Home',
				common_sign_in: 'Sign in',
				common_sign_out: 'Sign out',
				common_admin: 'Admin',
				navbar_confirm_logout: 'Are you sure you want to log out?',
				navbar_cancel_logout: 'Cancel',
				navbar_start: 'Start',

				sign_in_btn: 'Sign in with your Google account',
				create_account_btn: 'Create account',
				sign_in_help: "I can't sign in",
				sign_in_newseed: 'Developed by NewSeed®',

				landing_page_header_title: 'Fortnox integration to Google Sheets',

				landing_page_column_fortnox_text:
					'Fortnox offers a cloud based platform for financial administration for both small and big companies and organizations. It is easy, scaleable and expandable with many great integrations.',
				landing_page_column_googledocs_text:
					'Create, and edit documents via Googles web based tools. The simplicity and accessibility of Google docs is difficult to beat. It works in any modern web browser. Share documents for free with anyone in a safe and efficent manner.',
				landing_page_column_newseed:
					'NewSeed executes web, app and system development. We integrated Fortnox and Google Docs in such a way that you will easily administer and analyze your own data.',
				landing_page_column_newseed_title: 'Realising Ideas',

				landing_page_title: 'Welcome to Fortnox 2 Google',
				landing_page_text:
					'Thank you for choosing Fortnox2Google, a safe and efficent way to extract your data from Fortnox to Google Sheets. To set up an extraction please connect your account with Fortnox and then create one or more extraction tasks. Follow the instructions below and you will soon have your Fortnox data on your Google account! ',
				landing_page_contact:
					'Is there anything else you need? Contact NewSeed for special adaptiations at ',
				new_user_info:
					'Are you new? Log in with your Google account and add the company you bought a license for.',

				instructions_signed_in_text_part1: 'You have ',
				instructions_company_title: 'Company',
				instructions_signed_in_company_text_part2:
					" compan(ies) connected to your account. Navigate to 'Company' to add, edit or remove companies from your account. ",
				instructions_extract_title: 'Extract',

				instructions_signed_in_extract_text_part2:
					" active extractions. Navigate to 'Extract' to copy data from Fortnox to Google Drive.",

				instructions_not_signed_in_company_title: 'Connect company',
				instructions_not_signed_in_extract_title: 'Create an extract task',
				instructions_not_signed_in_company_text:
					"Click the button below and follow the instructions. You can add or edit companies under 'Companies' later.",
				instructions_not_signed_in_extract_text:
					"Click the button below and follow the instructions. You can add or edit extractions under 'Extract' later.",

				instructions_not_signed_in_company_btn: 'Connect company to Fortnox',
				instructions_not_signed_in_extract_btn: 'Create extraction to Google',

				settings_fortnox_tab: 'Fortnox',
				settings_folder_tab: 'Folders',
				settings_language_tab: 'Language',
				settings_fortnox: 'Fortnox',
				settings_language_settings: 'Language',
				settings_fortnox_add_company: 'Add company',
				settings_fortnox_add_token: 'Add fortnox access token',
				settings_fortnox_edit_token: 'Edit fortnox access token',
				settings_fortnox_scope_url: 'Read more about scopes',
				settings_fortnox_scope_select_all: 'Select all',
				settings_fortnox_scope_deselect_all: 'Deselect all',
				settings_fortnox_choose_scope: 'Choose scopes',
				settings_fortnox_table_header_scope: 'Scope',
				settings_fortnox_table_header_error_message: 'Latest error message',
				settings_fortnox_description:
					'Here you can create, edit and delete companies from Fortnox.',
				settings_fortnox_buy_title: 'Buy service',
				settings_fortnox_buy_description1:
					'You can buy our service directly via ',
				settings_fortnox_buy_description2: ', it will be available soon.',

				delete_task: 'Ta bort',

				instructions_heading1: 'Instructions',
				instructions_part1:
					"Follow the instructions under <a href='settings'>Settings</a> to connect your Fortnox account. At <a href='extract'>Extract</a> you can schedule extractions from Fortnox. Daily, weekly, or monthly. No extractions are possible if you have not connected a Fortnox account.",
				resource_undefined: 'Resource not selected',
				resource_customers: 'Customers',
				resource_invoices: 'Invoices',

				resource_absencetransactions: 'Absence Transactions',
				resource_accounts: 'Accounts',
				resource_articlefileconnections: 'Article File Connections',
				resource_articles: 'Articles',
				resource_assetfileconnections: 'Asset File Connection',
				resource_attendancetransactions: 'Attendance Transactions',
				resource_companyinformation: 'Company Information',
				/* resource_settings/company */
				resource_contractaccruals: 'Contract Accruals',
				resource_contracttemplates: 'Contract Templates',
				resource_contracts: 'Contracts',
				resource_costcenters: 'Cost Centers',
				resource_currencies: 'Currencies',
				resource_employees: 'Employees',
				resource_expenses: 'Expenses',
				resource_financialyears: 'Financial Years',
				resource_invoiceaccruals: 'Invoice Accruals',
				resource_invoicepayments: 'Invoice Payments',
				resource_labels: 'Labels',
				/* resource_settings/lockedperiod */
				resource_modesofpayments: 'Modes Of Payments',
				resource_offers: 'Offers',
				resource_orders: 'Orders',
				resource_predefinedaccounts: 'Predefined Accounts',
				resource_predefinedvoucherseries: 'Predefined Voucher Series',
				resource_pricelists: 'Price Lists',
				resource_prices: 'Prices',
				resource_projects: 'Projects',
				resource_salarytransactions: 'Salary Transactions',
				resource_supplierinvoiceaccruals: 'Supplier Invoice Accruals',
				resource_supplierinvoicefileconnections:
					'Supplier Invoice File Connections',
				resource_supplierinvoicepayments: 'Supplier Invoice Payments',
				resource_supplierinvoices: 'Supplier Invoices',
				resource_suppliers: 'Suppliers',
				resource_taxreductions: 'Tax Reductions',
				resource_termsofpayments: 'Terms Of Payments',
				resource_units: 'Units',
				resource_voucherfileconnections: 'Voucher File Connections',
				resource_voucherseries: 'Voucher Series',
				resource_vouchers: 'Vouchers',
				resource_wayofdeliveries: 'Way Of Deliveries',
				'resource_sie/1': 'SIE 1 - Årssaldon',
				'resource_sie/2': 'SIE 2 - Periodsaldon',
				'resource_sie/3': 'SIE 3 - Objektsaldon',
				'resource_sie/4': 'SIE 4 - Transaktioner',
				'resource_sie/4i': 'SIE 4i - Verifikationsposter',
				'resource_sie/5': 'SIE 5 - XML',
				resource_accountcharts: 'Account Charts',
				resource_archive: 'Archive',
				resource_emailsenders: 'Email Senders',
				resource_inbox: 'Inbox',
				resource_termsofdeliveries: 'Terms Of Deliveries',

				resource_incomestatement: 'Report: Incomestatement',
				resource_incomestatementbycc: 'Report: Incomestatement by Cost Center',
				resource_incomestatementbyccmonthly:
					'Report: Incomestatement by Cost Center by month',
				resource_balancesheet: 'Report: Balancesheet',
				resource_balancesheetbycc: 'Report: Balancesheet by Cost Center',
				resource_balancesheetbyccmonthly:
					'Report: Balancesheet by Cost Center by month',

				financial_years_label: 'Select year',
				financial_years_from: 'from ',
				financial_years_to: ' to ',

				resource_not_chosen: 'None selected',
				scheduler_daily: 'Daily',
				scheduler_weekly: 'Weekly',
				scheduler_monthly: 'Monthly',
				scheduler_none: 'None',
				scheduler_choose_interval: 'Choose interval',
				scheduler_choose_subinterval: 'Choose hour/weekday/day of month',
				scheduler_weekday1: 'Sunday',
				scheduler_weekday2: 'Monday',
				scheduler_weekday3: 'Tuesday',
				scheduler_weekday4: 'Wednesday',
				scheduler_weekday5: 'Thursday',
				scheduler_weekday6: 'Friday',
				scheduler_weekday7: 'Saturday',
				scheduler_hour_pre_string: 'At ',
				scheduler_hour_post_string: " o'clock",
				extract_button_schedule: 'Create task',
				extract_button_stop_editing: 'Stop editing',
				extract_button_save: 'Save',
				extract_button_create: 'Create task',
				extract_button_run_manually: 'Run',
				extract_button_job_history: 'History',
				extract_button_confirm_delete:
					'Delete but leave the document on Google Drive',
				extract_button_confirm_deletion_of_spreadsheet:
					'Also remove the document from Google Drive',
				extract_button_cancel_delete: 'Cancel',
				extract_button_stop: 'Stop',
				extract_title_create: 'Create',
				extract_title_edit: 'Edit',
				extract_title_table_jobname: 'Job Name',
				extract_title_table_resource: 'Resource',
				extract_title_table_document: 'Document',
				extract_title_table_last_run: 'Last run',
				extract_title_confirm_delete: 'Confirm delete',
				extract_job_history_title: 'Job History',
				extract_title_list: 'List of Jobs',
				extract_sheet_name: 'Sheet name',
				extract_task_not_run: 'Not yet run',
				extract_task_async_job_running:
					'Extraction is running in the background. If there is a lot of data it might take a while to complete.',
				extract_task_confirm_deletion:
					'Do you also want to remove the document from Google Drive?',
				extract_task_no_fortnox_token:
					'Warning: You have not yet added any fortnox tokens under settings.',
				extract_task_no_fortnox_token_for_scope:
					'Warning: You do not have a fortnox token that covers the scope of this resource. You need: ',
				extract_label_choose_fortnox_token: 'Company',
				extract_label_fortnox_token_not_selected: 'Select a company',
				extract_label_sheet_name: 'Sheet name',
				extract_label_job_name: 'Job name',
				extract_label_resource: 'Resource',
				extract_task_show_status: 'Extracting data. . .',
				extract_task_show_status_running: 'RUNNING',
				extract_task_show_status_finished: 'FINISHED',
				extract_task_show_status_failed: 'FAILED',

				extract_log_value_id: 'Id',
				extract_log_value_jobid: 'Job id',
				extract_log_value_token: 'Fortnox Token',
				extract_log_value_starttime: 'Starttime',
				extract_log_value_endtime: 'Endtime',
				extract_log_value_status: 'Status',
				extract_log_value_totalpages: 'Total Pages',
				extract_log_value_page: 'Page',
				extract_log_value_progress: 'Progress',
				extract_log_value_lasterror: 'Last Error',

				team_modal_title: 'Create team',
				team_modal_name_placeholder: 'Name of team',
				team_modal_gmail_placeholder: 'Invite via gmail',
				team_modal_groupname_subtitle: 'Team name:',
				team_modal_select_job: 'Select task:',
				team_modal_title_edit: 'Edit team',
				team_placeholder_gmail: 'Press enter to add a member',

				team_data_value_id: 'Id',
				team_data_value_ownerid: 'Owner Id',
				team_data_value_teamname: 'Team Name',
				team_data_value_creationdate: 'Creation Date',
				team_navbar_title: 'Teams',
				team_members_title: 'Members',
				team_tasks_title: 'Tasks',
				team_edit_btn: 'Edit',
				team_delete_btn: 'Delete',
				team_cancel_btn: 'Cancel',
				team_delete_title: 'Delete Team',
				team_confirm_delete_msg: 'Are you sure you want to delete this team?',
				team_dont_edit_job_token:
					'You can not edit the fortnox token of a task you do not own.',

				extract_label_schedule: 'Schedule',
				extract_label_lastrun: 'Last run',

				admin_customer_type_basic: 'Basic',
				admin_customer_type_advanced: 'Advanced',
				admin_name: 'Name',
				admin_email: 'E-mail',
				admin_customer_type: 'Customer type',
				admin_modified: 'Modified',
				admin_list_extract_tasks: 'Extract task list',

				admin_extract_task_list_resource: 'Resource',
				admin_extract_task_list_job_name: 'Job name',
				admin_extract_task_list_lastrun: 'Last run',
				admin_extract_task_list_error_message: 'Error message',
				admin_extract_task_list_cron_schedule: 'Cron schedule',
				admin_extract_task_list_schedule: 'Schedule',
				admin_extract_task_list_sheet_name: 'Sheet name',
				admin_confirm_block_user_msg:
					'Are you sure you want to block this user? This user will not be able to run any task while blocked.',
				admin_changed_customer_type: 'Customer type successfully changed',

				admin_block_btn: 'Block',
				admin_unblock_btn: 'Unblock',
				execution_log_no_history: 'Job has never been run.',
				error_modal_run_error_message: 'Task extraction failed.',
				error_modal_run_error_title: 'Failed',
				error_modal_run_error_btn: 'OK',

				error_modal_why: 'Why did this happen?',

				error_modal_fortnox_token_not_set_for_task:
					'It seems like your fortnox token is not set for the specific task. Please edit the task and choose a token.',
				error_modal_invalid_refresh_token:
					'It seems like your fortnox token is invalid. Please edit the task and choose a token that is in your scope.',
				error_modal_expired_refresh_token:
					'It seems like your fortnox token has expired. Please edit your Fortnox key to refresh it.',
				error_modal_google_api_quota_exceeded:
					'Something went wrong when we tried connecting with google. The google quota was exceeded. Please wait a minute before trying again.',
				error_modal_google_api_must_specify:
					'Something went wrong when passing the data to the spreadsheet. Please contact support and inform them of the time of error.',
				error_modal_google_api_invalid_value:
					'Something went wrong when passing the data to the spreadsheet. Please contact support and inform them of the time of error.',
				error_modal_google_api_unable_to_parse:
					'Something went wrong when passing the data to the spreadsheet. Please contact support and inform them of the time of error.',
				error_modal_google_api:
					'Something went wrong when we tried connecting with google. Please make sure you have given us access to Google Drive when you sign in with our application.',
				error_modal_unauthorized_for_certain_scope:
					'It seems like you are not authorized to run this resource with the chose fortnox token. Please go to settings and add this resource under scope.',
				error_modal_no_tasks_left:
					'You have exceeded maximum amount of extractions today. To unlock more extractions you can upgrade to the advanced version.',
				error_modal_incorrect_scopes:
					'It seems like there is no data on your fortnox account for chosen resource. ',
				error_modal_unauthorized_for_scope:
					"It seems like you're not authorized to extract this information from Fortnox or you did not select the required scope when you created the Fortnox key",
				error_modal_unknown_error:
					'We do not know what went wrong. Please contact support and we will help you.',
				error_modal_stopped_task: 'You stopped this task.',

				error_modal_user_blocked:
					'Your account has been blocked from using the extractor service. Please contact support.',
				error_modal_type_error: 'Error',
				error_modal_type_warning: 'Warning',
				error_modal_type_success: 'Success',
				error_modal_connecting_to_api:
					'Error connecting to the api. Try again later.',
				error_modal_creating_resource:
					'Unexpected error when creating something.',
				error_modal_updating_resource:
					'Unexpected error when updating something.',
				error_modal_deleting_resource:
					'Unexpected error when deleting something.',
				error_modal_success: 'Success',
				error_modal_fortnox_error: 'Error message from Fortnox:',

				auto_update_token_body_msg:
					'Some of your current tasks are missing a fortnox key. These tasks will not be able to run. Do you want auto associate a key with these tasks?',
				auto_update_token_body_title: 'Some tasks are missing fortnox key',
				auto_update_token_body_select:
					'Choose which key to set for the tasks without current key:',
				auto_update_token_btn_yes: 'Yes, set key',
				auto_update_token_btn_no_not_now: 'Not now',

				you_have_x_tasks_left: 'You have ',
				you_have_x_tasks_left_today: ' tasks left to run today.',

				terms_of_service_title: 'Terms of Service',
				terms_of_service_agree: 'I agree to the ',
				terms_of_service_link: 'terms and conditions.',
				terms_of_service_btn: 'Continue',
				terms_of_service_warningmsg: 'You need to agree to continue.',
				inv_modal_msg_part_1: 'Do you want to accept a team invite from ',
				inv_modal_msg_part_2: ' to team ',
				inv_modal_header: 'You have a team invite!',
				yes: 'Yes',
				no: 'No',
				click_for_more: 'Click for more information.',
				error_title_description: 'Error description',
				error_what_happend: 'What happend?',
				error_user_action: 'What can I do?',
				generic_error_title: 'An error occured.',
				generic_error_description: 'An unexpected error occured.',
				generic_error_action: 'Contact our support to get help.',
				no_tasks_left_title: 'The user has exceeded their daily job quota.',
				no_tasks_left_description:
					'The user has exceeded the limit of jobs that they can run per day.',
				no_tasks_left_action:
					'Upgrade to a payed version or wait until tomorrow to run more jobs.',
				stopped_task_title: 'User manually stopped the job.',
				stopped_task_description:
					'A user has manually stopped the job from running.',
				stopped_task_action: 'Run the job again.',
				no_token_title: 'Job is missing a Fortnox token.',
				no_token_description:
					'There is no Fortnox token associated with the job.',
				no_token_action: 'Add a token to the job and run it again.',
				invalid_refresh_token_title: 'The Fortnox token is invalid.',
				invalid_refresh_token_description:
					'The Fortnox token is no longer valid.',
				invalid_refresh_token_action:
					'Refresh the token by editing it in settings or add a new token, then try to run the job again.',
				expired_refresh_token_title: 'The Fortnox token is invalid.',
				expired_refresh_token_description:
					'The Fortnox token is no longer valid.',
				expired_refresh_token_action:
					'Refresh the token by editing it in settings or add a new token, then try to run the job again.',
				user_blocked_title: 'User is blocked',
				user_blocked_description: 'User is blocked and cannot use the app.',
				user_blocked_action: 'Contact an admin to resolve the issue.',
				unauthorized_for_scope_title: 'Missing permissions in Fortnox token.',
				unauthorized_for_scope_description:
					'The Fortnox token is missing the correct permissions to run this job.',
				unauthorized_for_scope_action:
					'Edit the token in settings or add a new token with the correct permissions.',
				unauthorized_msg_title: 'The Fortnox token is invalid.',
				unauthorized_msg_description:
					'Something is wrong with the Fortnox token and it cannot be used to run a job.',
				unauthorized_msg_action:
					'Refresh the token by editing it in settings or add a new token, then try to run the job again.',
				parameter_is_invalid_title: 'Invalid parameter in API call.',
				parameter_is_invalid_description:
					'There seems to be a invalid parameter in the Fortnox API call.',
				parameter_is_invalid_action: 'Please contact support',

				system_exception_title: 'System exception at Fortnox API',
				system_exception_description:
					'A system exception occurred at the Fortnox API.',
				system_exception_action: 'Please contact support',

				invalid_response_type_title: 'Invalid response type',
				invalid_response_type_description:
					'An invalid response type occurred with the Fortnox API.',
				invalid_response_type_action: 'Please contact support',

				invalid_content_type_title: 'Invalid content type.',
				invalid_content_type_description:
					'Invalid content type to fortnox API.',
				invalid_content_type_action: 'Please contact support',
				invalid_filter_title: 'Invalid filter for the Fortnox API.',
				invalid_filter_description:
					'The filter used for this job is not a valid filter for the Fortnox API.',
				invalid_filter_action: 'Try another filter or contact our support.',
				unknown_fortnox_error_title: 'Unknown Fortnox API error.',
				unknown_fortnox_error_description:
					'An unknown error occured when calling Fortnox API.',
				unknown_fortnox_error_action: 'Please contact our support.',

				error_contact_support:
					'To help you as quickly as possible in a support ticket state the following if you contact the support team:',

				filter_cancelled: 'Cancelled',
				filter_fyllypaid: 'Fully paid',
				filter_unpaid: 'Unpaid',
				filter_unpaid_overdue: 'Unpaid, overdue',
				filter_unbooked: 'Unbooked',
				filter_expired: 'Expired',
				filter_order_created: 'Order created',
				filter_order_not_created: 'Order not created',
				filter_invoice_created: 'Invoice created',
				filter_invoice_not_created: 'Invoice not created',
				filter_invoices: 'Invoices',
				filter_orders: 'Orders',
				filter_offers: 'Offers',
				filter_active: 'Active',
				filter_inactive: 'Inactive',
				filter_finished: 'Finished',
				sortby_total: 'Total',
				sortby_customername: 'Customer name',
				sortby_customernumber: 'Customer number',
				sortby_documentnumber: 'Document number',
				sortby_invoicedate: 'Invoice date',
				none_selected: 'None',
				sortby: 'Sort by',
				select_year: 'Välj år',
				select_filter: 'Välj filter',
				next_page: 'Next',
				previous_page: 'Previous',
				last_page: 'Last',
				first_page: 'First',
			},
		},
		sv: {
			translation: {
				common_extract: 'Överföring',
				common_instructions: 'Instruktioner',
				common_about: 'Om',
				common_settings: 'Inställningar',
				common_home: 'Hem',
				common_sign_in: 'Logga in',
				common_sign_out: 'Logga ut',
				common_admin: 'Admin',
				navbar_confirm_logout: 'Är du säker på att du vill logga ut?',
				navbar_cancel_logout: 'Avbryt',
				navbar_start: 'Startsida',

				sign_in_btn: 'Logga in med ditt Google-konto',
				create_account_btn: 'Skapa konto',
				sign_in_help: 'Jag kan inte logga in',
				sign_in_newseed: 'Utvecklad av NewSeed®',

				landing_page_header_title: 'Fortnox integration till Google Sheets',

				landing_page_column_fortnox_text:
					'Småföretagarens bästa vän - Fortnox erbjuder en molnbaserad plattform för ekonomiadministration för stora som små företag och organisationer. Det är enkelt, skalbart och utbyggbart med många integrationer.',
				landing_page_column_googledocs_text:
					'Skapa, redigera och ändra dokument via Googles webbaserade verktyg. Enkelheten och tillgängligheten av Google Docs är svårslagen, det fungerar i vilken modern webbläsare som helst. Dela dokument fritt med vem som helst på ett säkert sätt.',
				landing_page_column_newseed:
					'NewSeed utför webb-, app- och systemutveckling. Vi förde samman Fortnox och Google Docs på ett sätt så att du med enkelhet ska kunna administrera och analysera din egen information. Läs mer om integrationen på https://fortnox2google.newseed.se/',
				landing_page_column_newseed_title: 'Föreverkligar idéer',

				landing_page_title: 'Välkommen till Fortnox 2 Google',
				landing_page_text:
					'Tack för att ni valde Fortnox2google, ett säkert sätt att överföra din data från Fortnox till Google Sheets. För att komma igång med era överföringar måste ni först koppla upp er mot Fortnox och sedan skapa en eller flera överföringar. Följ instruktionerna nedan och ni har snart er data på ert googlekonto.',
				landing_page_contact:
					'Finns inte det du behöver? Kontakta NewSeed för specialanpassningar på ',
				new_user_info:
					'Är du ny? Logga in med ditt Google-konto och lägg till det företag du köpt licens för.',

				instructions_signed_in_text_part1: 'Du har ',
				instructions_company_title: 'Företag',
				instructions_signed_in_company_text_part2:
					' företag kopplat till ditt konto. Gå till företag för att lägga till eller ta bort företag.',
				instructions_extract_title: 'Överföring',
				instructions_signed_in_extract_text_part2:
					' aktiva överföringar. Gå till överföring för att kopiera data från Fortnox till Google Drive.',

				instructions_not_signed_in_company_title: 'Koppla företag',
				instructions_not_signed_in_extract_title: 'Sätt upp överföring',
				instructions_not_signed_in_company_text:
					'Tryck på knappen nedan och följ instruktionerna. Du kan lägga till eller ändra företag under länken Företag senare.',
				instructions_not_signed_in_extract_text:
					'Tryck på knappen nedan och följ instruktionerna. Du kan lägga till eller ändra överföringar under länken Överföringar senare.',

				instructions_not_signed_in_company_btn: 'Koppla företag från Fortnox',
				instructions_not_signed_in_extract_btn:
					'Sätt upp överföring till Google',

				settings_fortnox: 'Fortnox',
				settings_fortnox_tab: 'Fortnox',
				settings_folder_tab: 'Kataloger',
				settings_language_tab: 'Språk',
				settings_language_settings: 'Välj språk',
				settings_fortnox_add_company: 'Lägg till företag',
				settings_fortnox_add_token: 'Lägg till fortnoxnyckel',
				settings_fortnox_edit_token: 'Redigera fortnoxnyckel',
				settings_fortnox_scope_url:
					'Läs mer om vilka omfattningar som olika resurser kräver',
				settings_fortnox_scope_select_all: 'Markera alla',
				settings_fortnox_scope_deselect_all: 'Avmarkera alla',
				settings_fortnox_choose_scope: 'Välj omfattning',
				settings_fortnox_table_header_scope: 'Omfattning',

				settings_fortnox_description:
					'Här kan du lägga till, redigera och ta bort företag.',
				settings_fortnox_buy_title: 'Köp tjänst',
				settings_fortnox_buy_description1: 'Du köper vår tjänst direkt genom ',
				settings_fortnox_buy_description2:
					', den kommer vara tillgänglig inom kort.',

				settings_fortnox_table_header_error_message: 'Senaste felmeddelandet',
				delete_task: 'Delete',
				instructions_heading1: 'Instruktioner',
				instructions_part1:
					"Följ instruktionerna under <a href='settings'>Inställningar</a> för att koppla ett Fortnox konto. Under <a href='extract'>Överföring</a> kan du sedan schemalägga utdrag ur Fortnox. Alternativen är daglien, veckovis och månadsvis. Ingen uthämtning från Fortnox är möjlig om du inte har kopplat ett Fortnox konto.",
				resource_undefined: 'Ingen resurs vald',
				resource_customers: 'Kunder',
				resource_invoices: 'Fakturor',

				resource_absencetransactions: 'Frånvarotransaktioner',
				resource_accounts: 'Konto',
				resource_articlefileconnections: 'Artikelkoppling till fil',
				resource_articles: 'Artiklar',
				resource_assetfileconnections: 'Tillgångskopplingar till filer',
				resource_attendancetransactions: 'Närvarotransaktioner',
				resource_companyinformation: 'Företagsinformation',
				/* resource_settings/company */
				resource_contractaccruals: 'Kontraktsperiodiseringar',
				resource_contracttemplates: 'Kontraktsmallar',
				resource_contracts: 'Kontrakt',
				resource_costcenters: 'Kostnadsställe',
				resource_currencies: 'Valutor',
				resource_employees: 'Anställda',
				resource_expenses: 'Utlägg',
				resource_financialyears: 'Budgetår',
				resource_invoiceaccruals: 'Fakturaperiodiseringar',
				resource_invoicepayments: 'Fakturainbetalningar',
				resource_labels: 'Etiketter',
				/* resource_settings/lockedperiod */
				resource_modesofpayments: 'Betalningsmetoder',
				resource_offers: 'Erbjudande',
				resource_orders: 'Orders',
				resource_predefinedaccounts: 'Fördefinierade konto',
				resource_predefinedvoucherseries: 'Fördefinierade verifikationsserier',
				resource_pricelists: 'Prislistor',
				resource_prices: 'Priser',
				resource_projects: 'Projekt',
				resource_salarytransactions: 'Lönetransaktioner',
				resource_supplierinvoiceaccruals: 'Leverantörsfakturaperiodiseringar',
				resource_supplierinvoicefileconnections:
					'Leverantörsfakturakoppling till fil',
				resource_supplierinvoicepayments: 'Leverantörsfakturabetalning',
				resource_supplierinvoices: 'Leverantörsfaktura',
				resource_suppliers: 'Leverantörer',
				resource_taxreductions: 'Skattereduktion',
				resource_termsofpayments: 'Betalningsvillkor',
				resource_units: 'Enheter',
				resource_voucherfileconnections: 'Verifikationskopplingar till fil',
				resource_voucherseries: 'Verifikationsserier',
				resource_vouchers: 'Verifikationer',
				resource_wayofdeliveries: 'Leveransmetoder',
				'resource_sie/1': 'SIE 1 - Årssaldon',
				'resource_sie/2': 'SIE 2 - Periodsaldon',
				'resource_sie/3': 'SIE 3 - Objektsaldon',
				'resource_sie/4': 'SIE 4 - Transaktioner',
				'resource_sie/4i': 'SIE 4i - Verifikationsposter',
				'resource_sie/5': 'SIE 5 - XML',
				resource_accountcharts: 'Kontoplan',
				resource_archive: 'Arkivplats',
				resource_emailsenders: 'E-postavsändare',
				resource_inbox: 'Arkivplats - Inbox',
				resource_termsofdeliveries: 'Leveransvillkor',

				resource_incomestatement: 'Rapport: Resultatrapport',
				resource_incomestatementbycc:
					'Rapport: Resultatrapport per kostnadsställe',
				resource_incomestatementbyccmonthly:
					'Rapport: Resultatrapport per kostnadsställe per månad',
				resource_balancesheet: 'Rapport: Balansrapport',
				resource_balancesheetbycc: 'Rapport: Balansrapport per kostnadsställe',
				resource_balancesheetbyccmonthly:
					'Rapport: Balansrapport per kostnadsställe per månad',

				financial_years_label: 'Välj år',
				financial_years_from: 'från ',
				financial_years_to: ' till ',

				resource_not_chosen: 'Ingen vald',
				scheduler_daily: 'Dagligen',
				scheduler_weekly: 'Veckovis',
				scheduler_monthly: 'Månadsvis',
				scheduler_none: 'Upprepa ej',
				scheduler_choose_interval: 'Välj intervall',
				scheduler_choose_subinterval: 'Välj timme/veckodag/dag i månad',
				scheduler_weekday1: 'Söndag',
				scheduler_weekday2: 'Måndag',
				scheduler_weekday3: 'Tisdag',
				scheduler_weekday4: 'Onsdag',
				scheduler_weekday5: 'Torsdag',
				scheduler_weekday6: 'Fredag',
				scheduler_weekday7: 'Lördag',
				scheduler_hour_pre_string: 'Klockan ',
				scheduler_hour_post_string: ':00',
				extract_button_schedule: 'Skapa jobb',
				extract_button_stop_editing: 'Avbryt',
				extract_button_save: 'Spara',
				extract_button_run_manually: 'Kör',
				extract_button_job_history: 'Historik',
				extract_button_confirm_delete: 'Låt dokumentet ligga kvar',
				extract_button_confirm_deletion_of_spreadsheet:
					'Ta även bort dokumentet',
				extract_button_cancel_delete: 'Avbryt',
				extract_button_create: 'Skapa',
				extract_button_stop: 'Stoppa',
				extract_title_create: 'Skapa jobb',
				extract_title_edit: 'Redigera',
				extract_title_table_jobname: 'Jobbnamn',
				extract_title_table_resource: 'Resurs',
				extract_title_table_document: 'Dokument',
				extract_title_table_last_run: 'Senast körd',
				extract_title_list: 'Jobblista',
				extract_title_confirm_delete: 'Bekräfta borttagning',
				extract_job_history_title: 'Jobb Historik',
				extract_sheet_name: 'Namn på dokument',
				extract_task_not_run: 'Ej kört',
				extract_task_async_job_running:
					'Överföringen sker i bakgrunden. Om det är mycket data kan det ta en stund innan det är klart.',
				extract_task_confirm_deletion:
					'Vill du även radera dokumentet från Google Drive?',
				extract_task_no_fortnox_token:
					'Varning: Du har inte lagt till några åtkomstnycklar till fortnox under inställningar. Ingen data kommer att kunna hämtas från Fortnox.',
				extract_task_no_fortnox_token_for_scope:
					'Varning: Du har inte någon fortnox-nyckel som omfattar denna resurs. Du behöver: ',
				extract_label_choose_fortnox_token: 'Företag',
				extract_label_fortnox_token_not_selected: 'Välj ett företag',
				extract_label_sheet_name: 'Dokumentnamn',
				extract_label_job_name: 'Jobbnamn',
				extract_label_resource: 'Resurs',
				extract_task_show_status: 'Överför data. . .',
				extract_task_show_status_running: 'KÖRNING PÅGÅR',
				extract_task_show_status_finished: 'KLAR',
				extract_task_show_status_failed: 'MISSLYCKADES',

				extract_log_value_id: 'Id',
				extract_log_value_jobid: 'Jobb Id',
				extract_log_value_token: 'Fortnox Nyckel',
				extract_log_value_starttime: 'Starttid',
				extract_log_value_endtime: 'Sluttid',
				extract_log_value_status: 'Status',
				extract_log_value_totalpages: 'Totala Sidor',
				extract_log_value_page: 'Sida',
				extract_log_value_progress: 'Progression',
				extract_log_value_lasterror: 'Senaste Fel',

				team_modal_title: 'Skapa grupp',
				team_modal_name_placeholder: 'Namn på gruppen',
				team_modal_gmail_placeholder: 'Bjud in via gmail',
				team_modal_groupname_subtitle: 'Grupp namn:',
				team_modal_select_job: 'Välj jobb:',
				team_modal_title_edit: 'Redigera grupp',
				team_placeholder_gmail: 'Tryck enter för att lägga till medlem',

				team_data_value_id: 'Id',
				team_data_value_ownerid: 'Grupp ägarens id',
				team_data_value_teamname: 'Grupp namn',
				team_data_value_creationdate: 'Skapad',
				team_navbar_title: 'Grupper',
				team_members_title: 'Medlemmar',
				team_tasks_title: 'Jobb',
				team_edit_btn: 'Redigera',
				team_delete_btn: 'Ta bort',
				team_cancel_btn: 'Avbryt',
				team_delete_title: 'Ta bort grupp',
				team_confirm_delete_msg:
					'Är du säker på att du vill ta bort denna grupp?',
				team_dont_edit_job_token:
					'Du kan inte ändra fortnox nyckel på ett jobb du inte äger.',

				extract_label_schedule: 'Schema',
				extract_label_lastrun: 'Senast körd',

				admin_customer_type_basic: 'Bas',
				admin_customer_type_advanced: 'Utökad',
				admin_name: 'Namn',
				admin_email: 'E-post',
				admin_customer_type: 'Kundtyp',
				admin_modified: 'Senast ändrad',
				admin_list_extract_tasks: 'Överföringsjobb',

				admin_extract_task_list_resource: 'Resurs',
				admin_extract_task_list_job_name: 'Jobbnamn',
				admin_extract_task_list_lastrun: 'Senast körd',
				admin_extract_task_list_error_message: 'Felmeddelande',
				admin_extract_task_list_cron_schedule: 'Cron schema',
				admin_extract_task_list_schedule: 'Schema',
				admin_extract_task_list_sheet_name: 'Dokumentnamn',
				admin_confirm_block_user_msg:
					'Är du säker på att du vill blockera denna användaren? Användaren kommer inte att kunna köra några jobb medan den är blockerad.',
				admin_changed_customer_type: 'Kundtypen har ändrats',

				admin_confirm_unblock_user_msg:
					'Är du säker på att du vill ta bort blockeringen på denna användaren? Användaren kommer återigen att kunna köra jobb.',
				admin_block_btn: 'Blockera',
				admin_unblock_btn: 'Avblockera',
				execution_log_no_history: 'Jobbet har aldrig körts.',
				error_modal_run_error_message: 'Körningen misslyckades.',
				error_modal_run_error_title: 'Misslyckades',
				error_modal_run_error_btn: 'OK',
				error_modal_why: 'Varför hände detta?',

				error_modal_fortnox_token_not_set_for_task:
					'Det verkar som att fortnox nyckel inte är vald för det specifika jobbet du körde. Var god att redigera jobbet och välj nyckel.',
				error_modal_invalid_refresh_token:
					'Det verkar som att din fortnox nyckel är ogiltig. Var god att redigera jobbet och välj nyckel.',
				error_modal_expired_refresh_token:
					'Det verkar som att din fortnox nyckel inte längre går att använda. Var god att redigera din nyckel för att reparera den.',
				error_modal_google_api_quota_exceeded:
					'Något gick fel när vi försökte koppla med google. Det verkar som att det blivit för många anrop till Google. Vänligen vänta en minut innan du försöker igen.',
				error_modal_google_api_must_specify:
					'Något gick fel när data skulle skickas till dokumentet. Vänligen kontakta support och berätta när felet uppstod.',
				error_modal_google_api_invalid_value:
					'Något gick fel när data skulle skickas till dokumentet. Vänligen kontakta support och berätta när felet uppstod.',
				error_modal_google_api_unable_to_parse:
					'Något gick fel när data skulle skickas till dokumentet. Vänligen kontakta support och berätta när felet uppstod.',
				error_modal_google_api:
					'Något gick fel när vi försökte koppla med google. Se till att du har gett oss behörighet till Google Drive när du loggar in med vår tjänst.',
				error_modal_unauthorized_for_certain_scope:
					'Det verkar som att du saknar behörighet att köra denna resursen med valda fortnox nyckel. Var god att gå in under inställningar och lägg till resurs under omfattning på din fortnox nyckel.',
				error_modal_no_tasks_left:
					'Du har överstigit max antal körningar per dag. För att låsa upp fler körningar kan du uppgradera till utökad version.',
				error_modal_incorrect_scopes:
					'Det verkar som det inte ligger någon data på ditt fortnox konto för denna resursen. ',
				error_modal_unauthorized_for_scope:
					'Du verkar inte ha tillstånd att hämta ut den här resursen från Fortnox eller så angav du inte rätt omfattning när du skapade Fortnox nyckeln',
				error_modal_unknown_error:
					'Vi vet inte exakt vad som gick fel. Var god att kontakta support så hjälper vi dig.',
				error_modal_stopped_task: 'Du stoppade detta jobbet.',

				error_modal_user_blocked:
					'Ditt konto har blockats från att köra jobb. Var god att kontakta support.',
				error_modal_type_error: 'Fel',
				error_modal_type_warning: 'Varning',
				error_modal_type_success: 'OK',
				error_modal_connecting_to_api:
					'Fel vid anslutning till api:et. Försök igen senare.',
				error_modal_creating_resource:
					'Ett oväntat fel uppstod när något skulle skapas.',
				error_modal_updating_resource:
					'Ett oväntat fel uppstod när något skulle ändras.',
				error_modal_deleting_resource:
					'Ett oväntat fel uppstod när något skulle tas bort.',
				error_modal_success: 'Lyckat',
				error_modal_fortnox_error: 'Felmeddelande från Fortnox:',
				auto_update_token_body_msg:
					'Det verkar som att vissa av dina nuvarande jobb saknar fortnox nyckel. Dessa jobb kommer inte gå att köra. Vill du automatiskt sätta fortnox nyckel på dessa jobben?',
				auto_update_token_body_title: 'Vissa av dina jobb saknar nyckel',
				auto_update_token_body_select:
					'Välj vilken nyckel du vill sätta på dem jobb som saknar nyckel',
				auto_update_token_btn_yes: 'Ja, sätt nyckel',
				auto_update_token_btn_no_not_now: 'Inte nu',
				you_have_x_tasks_left: 'Du har ',
				you_have_x_tasks_left_today: ' jobb kvar att köra idag.',

				terms_of_service_title: 'Användarvillkor',
				terms_of_service_agree: 'Jag godkänner ',
				terms_of_service_link: 'användarvillkoren.',
				terms_of_service_btn: 'Fortsätt',
				terms_of_service_warningmsg:
					'Du måste godkänna för att kunna gå vidare.',
				inv_modal_msg_part_1: 'Vill du acceptera grupp inbjudan från ',
				inv_modal_msg_part_2: ' till team ',
				inv_modal_header: 'Du har en grupp inbjudan!',
				yes: 'Ja',
				no: 'Nej',
				click_for_more: 'Klicka för mer information.',
				error_title_description: 'Felbeskrivning',
				error_what_happend: 'Vad hände?',
				error_user_action: 'Vad kan jag göra?',
				generic_error_title: 'Ett fel inträffade.',
				generic_error_description: 'Ett oväntat fel inträffade.',
				generic_error_action: 'Kontakta vår support för att få hjälp.',
				no_tasks_left_title:
					'Användaren har överskridit sin dagliga jobb kvot.',
				no_tasks_left_description:
					'Användaren har överskridit gränsen för hur många jobb som kan köras per dag.',
				no_tasks_left_action:
					'Uppgradera till en betald version eller vänta tills imorgon för att köra fler jobb.',
				stopped_task_title: 'En användare har manuellt stoppat jobbet.',
				stopped_task_description:
					'En användare har manuellt stoppat körningen av jobbet.',
				stopped_task_action: 'Kör jobbet igen.',
				no_token_title: 'Jobbet saknar en Fortnox-nyckel.',
				no_token_description:
					'Det finns ingen Fortnox-nyckel kopplad till jobbet.',
				no_token_action: 'Lägg till en nyckel och kör jobbet igen.',
				invalid_refresh_token_title: 'Fortnox-nyckeln är ogiltig.',
				invalid_refresh_token_description:
					'Fortnox-nyckeln är inte längre giltig och kan inte användas för att köra ett jobb.',
				invalid_refresh_token_action:
					'Uppdatera nyckeln genom att redigera den i inställningar eller lägg till en ny nyckel, testa sen att köra jobbet igen.',
				expired_refresh_token_title: 'Fortnox-nyckeln är ogiltig.',
				expired_refresh_token_description:
					'Fortnox-nyckeln är inte längre giltig och kan inte användas för att köra ett jobb.',
				expired_refresh_token_action:
					'Uppdatera nyckeln genom att redigera den i inställningar eller lägg till en ny nyckel, testa sen att köra jobbet igen.',
				user_blocked_title: 'Användaren är blockerad.',
				user_blocked_description:
					'Användaren är blockerad och kan inte längre använda appen.',
				user_blocked_action: 'Kontakta en administratör för hjälp.',
				unauthorized_for_scope_title: 'Saknar behörigheter i Fortnox-nyckeln.',
				unauthorized_for_scope_description:
					'Nyckeln saknar de behörigheter som krävs för att kunna köra detta jobbet.',
				unauthorized_for_scope_action:
					'Redigera nyckeln i inställningar eller skapa en ny nyckel med korrekt behörighet.',
				unauthorized_msg_title: 'Fortnox-nyckeln är ogiltig.',
				unauthorized_msg_description:
					'Något är fel med fortnox-nyckeln och den kan inte användas för att köra ett jobb.',
				unauthorized_msg_action:
					'Uppdatera nyckeln genom att redigera den i inställningar eller lägg till en ny nyckel, testa sen att köra jobbet igen.',
				/*15 Februari 2022*/
				parameter_is_invalid_title: 'Ogiltig parameter i anropet.',
				parameter_is_invalid_description:
					'Det finns en ogiltig parameter i anropet till vårt API.',
				parameter_is_invalid_action: 'Var god att kontakta support.',

				system_exception_title: 'Systemfel hos fortnox.',
				system_exception_description:
					'Det blev ett system fel hos fortnox API.',
				system_exception_action: 'Var god att kontakta support.',

				invalid_response_type_title: 'Ogiltlig respons typ.',
				invalid_response_type_description:
					'Det blev en ogiltig respons från fortnox API.',
				invalid_response_type_action: 'Var god att kontakta support.',

				invalid_content_type_title: 'Invalid content type.',
				invalid_content_type_description:
					'Invalid content type to fortnox API.',
				invalid_content_type_action: 'Var god att kontakta support.',
				invalid_filter_title: 'Ogiltigt filter för Fortnox API:et.',
				invalid_filter_description:
					'Filtret som används för detta jobbet är inte giltigt för Fortnoxs API.',
				invalid_filter_action: 'Ändra filtret eller kontakta supporten.',
				unknown_fortnox_error_title: 'Okänt Fortnox API-fel.',
				unknown_fortnox_error_description:
					'Ett okänt fel uppstod när Fortnox API anropades.',
				unknown_fortnox_error_action: 'Var god kontakta supporten.',

				error_contact_support:
					'För att kunna hjälpa dig så snabbt som möjlig vid ett support-ärende uppge följande till support-teamet:',

				filter_cancelled: 'Avbruten',
				filter_fyllypaid: 'Fullt Betald',
				filter_unpaid: 'Obetald',
				filter_unpaid_overdue: 'Obetald, försenad',
				filter_unbooked: 'Obokad',
				filter_expired: 'Utgången',
				filter_order_created: 'Order skapad',
				filter_order_not_created: 'Order inte skapad',
				filter_invoice_created: 'Faktura skapad',
				filter_invoice_not_created: 'Faktura inte skapad',
				filter_invoices: 'Fakturor',
				filter_orders: 'Orders',
				filter_offers: 'Erbjudanden',
				filter_active: 'Aktiv',
				filter_inactive: 'Inaktiv',
				filter_finished: 'Klar',
				sortby_total: 'Totalt',
				sortby_customername: 'Kund namn',
				sortby_customernumber: 'Kund nummer',
				sortby_documentnumber: 'Dokument nummer',
				sortby_invoicedate: 'Faktura datum',
				none_selected: 'Ingen',
				sortby: 'Sortera efter:',
				select_year: 'Välj år',
				select_filter: 'Välj filter',
				next_page: 'Nästa',
				previous_page: 'Förra',
				last_page: 'Sista',
				first_page: 'Första',
			},
		},
	},
});

export default i18next;
