import NetworkService from './NetworkService';

const SettingsService = {
	getSettings: function (session) {
		return NetworkService.getResourceWithAuth('v1/settings/', session);
	},
	createFortnoxToken: function (obj, session) {
		return NetworkService.postResourceWithAuth(
			'authorize/fortnox',
			obj,
			session
		);
	},
	getFortnoxAccessTokens: function (session) {
		return NetworkService.getResourceWithAuth('v1/settings/fortnox/', session);
	},
	getFinancialYears: function (session, tokenid) {
		return NetworkService.getResourceWithAuth(
			'v1/settings/fortnox/financialyears/' + tokenid,
			session
		);
	},
	revokeFortnoxToken: function (session, tokenId) {
		return NetworkService.postResourceWithAuth(
			'v1/settings/fortnox/revoketoken',
			{ fortnoxAccessTokenId: tokenId },
			session
		);
	},

	changeLanguage: function (language, session) {
		return NetworkService.postResourceWithAuth(
			'v1/settings/language',
			{ language: language },
			session
		);
	},
	getFortnoxTest: function (session) {
		return NetworkService.getResourceWithAuth('v1/fortnox/customers', session);
	},
	getExecutionLog: function (id, session) {
		return NetworkService.getResourceWithAuth('v1/executionlog/' + id, session);
	},
	deleteExecutionLog: function (id, session) {
		return NetworkService.deleteResourceWithAuth(
			'v1/executionlog/' + id,
			session
		);
	},
};
export default SettingsService;
