import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { mapDispatchToProps, mapStateToProps } from '../utils/prop-utils';
import i18n from 'i18next';
import AdminService from '../services/AdminService';

class TermsOfServiceModal extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			acceptTermsOfServiceModalIsOpen: true,
			checked: false,
			warningMsg: false,
		};
		this._openAcceptTermsOfServiceModal =
			this._openAcceptTermsOfServiceModal.bind(this);
		this._closeAcceptTermsOfServiceModal =
			this._closeAcceptTermsOfServiceModal.bind(this);
		this._handleCheck = this._handleCheck.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_openAcceptTermsOfServiceModal() {
		this.setState({
			acceptTermsOfServiceModalIsOpen: true,
		});
	}
	_closeAcceptTermsOfServiceModal() {
		this.setState({
			acceptTermsOfServiceModalIsOpen: false,
		});
	}

	_updateLicenseAcceptDate(props) {
		if (this.state.checked) {
			AdminService.updateAcceptDate(props.session.userid, props.session)
				.then(() => {
					if (!this._isMounted) {
						return;
					}
					this._closeAcceptTermsOfServiceModal();
				})
				.catch((err) => {
					this.props.setGlobalErrorMessage(
						i18n.t('error_modal_connecting_to_api'),
						JSON.stringify(err),
						'error'
					);
				});
		} else {
			this.setState({
				warningMsg: true,
			});
		}
	}

	_handleCheck() {
		this.setState({ checked: !this.state.checked });
	}

	_renderAcceptTermsOfServiceModal() {
		return (
			<Modal show={this.state.acceptTermsOfServiceModalIsOpen}>
				<Modal.Header>
					<Modal.Title>{i18n.t('terms_of_service_title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex">
						<input
							className="mr-2"
							type="checkbox"
							onChange={this._handleCheck}
							defaultChecked={this.state.checked}
						/>
						<p class="m-0">
							{i18n.t('terms_of_service_agree')}{' '}
							<a
								href="https://fortnox2google.se/files/2022/10/Allmanna_villkor_och_privacy_policy.pdf"
								target="_blank"
								rel="noopener noreferrer"
							>
								{i18n.t('terms_of_service_link')}
							</a>
						</p>
					</div>

					<div className="ml-2">
						<i className="text-danger">
							{this.state.warningMsg
								? i18n.t('terms_of_service_warningmsg')
								: ''}
						</i>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-primary"
						onClick={() => this._updateLicenseAcceptDate(this.props)}
					>
						{i18n.t('terms_of_service_btn')}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}
	render() {
		return <div> {this._renderAcceptTermsOfServiceModal()}</div>;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TermsOfServiceModal);
