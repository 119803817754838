import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import SettingsService from '../../services/SettingsService';
import { mapStateToProps, mapDispatchToProps } from '../../utils/prop-utils';

class LanguageSettings extends React.Component {
	_isMounted = false;
	languages = {
		sv: 'Svenska',
		en: 'Engelska',
	};
	constructor(props) {
		super(props);
		this.state = {
			settings: this.props.settings,
			language: i18n.language,
		};
		this._handleChange = this._handleChange.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_handleChange(event) {
		let language = event.target.value;
		this.setState({ language: language });
		document.cookie = 'language=' + language + '; path=/';
		window.location.reload();
		i18n.changeLanguage(language);
		SettingsService.changeLanguage(language, this.props.session);
	}

	render() {
		let languages = Object.entries(this.languages).map((item) => {
			let key = item[0];
			let value = item[1];
			return (
				<option value={key} key={key}>
					{value}
				</option>
			);
		});
		return (
			<div className="form-group">
				<h3 className="choose-language-text">
					{i18n.t('settings_language_settings')}
				</h3>
				<select
					value={this.state.language}
					onChange={this._handleChange}
					className="form-control col-md-3 col-sm-6 col-lg-3"
				>
					{languages}
				</select>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettings);
