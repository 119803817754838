import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../services/i18n';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

class ErrorModalLink extends React.Component {
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_getErrorTitleTranslation(errorCode = 'generic_error') {
		const titleKey = errorCode + '_title';
		let translation = i18n.t(titleKey);
		if (translation === titleKey) {
			translation = i18n.t('generic_error_title');
		}
		return translation;
	}

	_openErrorModal(error) {
		this.setState({
			errorModalIsOpen: true,
		});
		this.props.setGlobalErrorMessage(null, null, null, error);
	}

	render() {
		let error;
		try {
			error = JSON.parse(this.props.error);
		} catch {
			error = this.props.error;
		}

		return (
			<div className="clickable" onClick={() => this._openErrorModal(error)}>
				<span>
					{this._getErrorTitleTranslation(error?.errorCode)}{' '}
					{i18n.t('click_for_more')}
				</span>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModalLink);
