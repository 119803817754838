import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import { Accordion, Card, Button } from 'react-bootstrap';

class FAQ extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState({ loading: false });
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	renderAccordion() {
		return (
			<div className="col-md-6 col-sm-12 p-3">
				<h3 className="mb-3">Frågor</h3>

				<Accordion style={{ color: '#515151' }}>
					<Card>
						<Card.Header>
							<Accordion.Toggle
								style={{ color: 'black' }}
								as={Button}
								variant="link"
								eventKey="0"
							>
								Har du kopplat ditt fortnox konto?
							</Accordion.Toggle>
						</Card.Header>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								För att vi ska kunna överföra din data från Fortnox till Google
								sheets kräver vår tjänst att du lägger till ett företag och
								kopplar ditt Fortnox konto.
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Card.Header>
							<Accordion.Toggle
								style={{ color: 'black' }}
								as={Button}
								variant="link"
								eventKey="1"
							>
								Har du tillgång till internet?
							</Accordion.Toggle>
						</Card.Header>
						<Accordion.Collapse eventKey="1">
							<Card.Body>
								Tjänsten kräver att du har tillgång till internet för att
								fungera.
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Card.Header>
							<Accordion.Toggle
								style={{ color: 'black' }}
								as={Button}
								variant="link"
								eventKey="2"
							>
								Har du tillräcklig licens på ditt kopplade fortnox konto?
							</Accordion.Toggle>
						</Card.Header>
						<Accordion.Collapse eventKey="2">
							<Card.Body>
								Tjänsten kräver att du har en fortnox licens
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			</div>
		);
	}

	render() {
		if (this.state.loading) {
			return (
				<div id="page-content-wrapper">
					<Spinner variant="primary" animation="border" />
				</div>
			);
		}
		return (
			<div className="container p-4">
				<div className="row">
					<div className=" text-center">
						<img
							src={require('../../images/loggaf2M.png')}
							className="img-fluid p-2 pb-0"
							alt='Fortnox 2 Google logo'
						/>
						<br></br>
						<h1 className="display-4 mb-2">Upplever du problem med tjänsten?</h1>
						Läst först igenom frågorna. Hjälper inte svaren fyll i formuläret
						nedan.
						<br></br>Vi återkopplar snarast möjligt.
					</div>
					{this.renderAccordion()}

					<div className="col-md-6 col-sm-12 p-3">
						<h3>Supportformulär</h3>
						<form>
							<div className="mb-3">
								<label for="InputName" className="form-label">
									Namn*
								</label>
								<input
									type="name"
									className="form-control"
									id="InputName"
									aria-describedby="emailHelp"
								/>
							</div>
							<div className="mb-3">
								<label for="InputEmail" className="form-label">
									E-postadress*
								</label>
								<input
									type="email"
									className="form-control"
									id="InputEmail"
									aria-describedby="emailHelp"
								/>
							</div>
							<div className="mb-3">
								<label for="InputTele" className="form-label">
									Telefon
								</label>
								<input type="text" className="form-control" id="InputTele" />
							</div>
							<div className="mb-3">
								<label for="Textarea" className="form-label">
									Meddelande*
								</label>
								<textarea
									className="form-control"
									id="Textarea"
									rows="3"
								></textarea>
							</div>
							<button type="submit" className="btn btn-s m-1 gradiant">
								Skicka
							</button>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
