import React from 'react';
import { connect } from 'react-redux';
import '../App.css';
import i18n from '../services/i18n';
import { Modal } from 'react-bootstrap';
import SettingsService from '../services/SettingsService';
import { mapDispatchToProps, mapStateToProps } from '../utils/prop-utils';

//TODO: Få in execution log datan här för att jämföra med last error
//då måste jag också få med taskid så från Extract.js

let errorList = {
	fortnox_token_not_set_for_task:
		'"Fortnox access token not set for extract task ',
	invalid_refresh_token:
		'{"error":"invalid_grant","error_description":"Invalid refresh token"}',
	expired_refresh_token:
		'{"error":"invalid_grant","error_description":"Refresh token has expired"}',
	unauthorized_msg: '{"message":"unauthorized"}',
	unauthorized_for_certain_scope:
		'{"ErrorInformation":{"error":1,"message":"Du saknar behörighet att lista',
	google_api: '"Error connecting to Google',
	google_api_quota_exceeded:
		'"Error connecting to Google api: \\"Quota exceeded',
	google_api_must_specify:
		'"Error connecting to Google api: \\"Must specify at least one request.',
	google_api_invalid_value:
		'"Error connecting to Google api: \\"Invalid value at',
	google_api_unable_to_parse:
		'"Error connecting to Google api: \\"Unable to parse range',
	no_tasks_left: '"This user does not have any tasks left to run',
	incorrect_scopes:
		'{"ErrorInformation":{"error":1,"message":"Det finns ingen aktiv licens för önskat scope.',

	user_blocked: '"This user is blocked',
	unauthorized_for_scope:
		'{"ErrorInformation":{"error":1,"message":"Har inte behörighet för scope.","code":2000663}}',
	stopped_task: '"Stopped',
};

class ErrorModal extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			executionLogData: [],
			errorModalIsOpen: true,
		};
		this._closeErrorModal = this._closeErrorModal.bind(this);
		this._openErrorModal = this._openErrorModal.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		if (this.props.taskId) {
			this.fetchExecutionLogData();
		}
	}

	async fetchExecutionLogData() {
		try {
			const executionLogs = await SettingsService.getExecutionLog(
				this.props.taskId,
				this.props.session
			);
			if (this._isMounted) {
				this.setState({
					executionLogData: executionLogs.reverse(),
					loading: false,
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	_openErrorModal() {
		this.setState({
			errorModalIsOpen: true,
		});
	}
	_closeErrorModal() {
		this.setState({
			errorModalIsOpen: false,
		});
		this.props.setGlobalErrorMessage('', '', '', '');
	}

	_whenToShowRunFailedMessage() {
		let explainErrorMsg = '';

		let runErrorDescription = this.state.executionLogData.find((l) => {
			return l.jobid === this.props.taskId;
		});
		Object.keys(errorList).forEach((key) => {
			if (runErrorDescription?.lasterror.startsWith(errorList[key])) {
				explainErrorMsg = i18n.t('error_modal_' + key);
			}
		});
		if (
			!explainErrorMsg &&
			this.props.errorMessage === i18n.t('error_modal_run_error_message')
		) {
			explainErrorMsg = i18n.t('error_modal_unknown_error');
		}
		return <div>{explainErrorMsg}</div>;
	}

	_parseErrorMessage() {
		const log = this.state.executionLogData[0] || {};

		try {
			const parsedError = JSON.parse(log.lasterror);
			return parsedError?.errorCode ? parsedError : undefined;
		} catch {
			return undefined;
		}
	}

	_renderErrorModal() {
		const classes = ['error-modal'];
		if (this.props.errorType) {
			classes.push(this.props.errorType);
		}

		const errorObject = this.props.errorObject || this._parseErrorMessage();

		if (errorObject?.errorCode) {
			const { errorCode, errorId } = errorObject;

			const keys = {
				title: errorCode + '_title',
				description: errorCode + '_description',
				action: errorCode + '_action',
			};

			const modalContent = {};

			Object.entries(keys).forEach(([key, value]) => {
				modalContent[key] = i18n.t(value);
				if (modalContent[key] === value) {
					modalContent[key] = i18n.t('generic_error_' + key);
				}
			});

			return (
				<Modal
					show={this.state.errorModalIsOpen}
					onHide={this._closeErrorModal}
					className={classes.join(' ')}
				>
					<Modal.Header closeButton>
						<Modal.Title>{i18n.t('error_title_description')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5 className="mb-3">{modalContent.title}</h5>
						<div className="modal-section">
							<h6>{i18n.t('error_what_happend')}</h6>
							<p>{modalContent.description}</p>
						</div>
						<div className="modal-section">
							<h6>{i18n.t('error_user_action')}</h6>
							<p>{modalContent.action}</p>
						</div>
						{errorId && (
							<div className="modal-section">
								<p className="mb-1">{i18n.t('error_contact_support')}</p>
								<p className="mb-0">
									<strong>
										Error Id: [{errorId}] <br></br>Error Code: [{errorCode}]
									</strong>
								</p>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button
							className="btn btn-s m-1 gradiantred"
							onClick={this._closeErrorModal}
						>
							{i18n.t('error_modal_run_error_btn')}
						</button>
					</Modal.Footer>
				</Modal>
			);
		} else {
			let title = i18n.t('error_modal_type_warning');
			let why = '';

			if (this.props.errorMessage === i18n.t('error_modal_run_error_message')) {
				title = i18n.t('error_modal_run_error_title');
				why = i18n.t('error_modal_why');
			}

			if (this.props.errorType) {
				title = i18n.t('error_modal_type_' + this.props.errorType);
			}

			if (this.props.errorMessage)
				return (
					<Modal
						show={this.state.errorModalIsOpen}
						onHide={this._closeErrorModal}
						className={classes.join(' ')}
					>
						<Modal.Header closeButton>
							<Modal.Title>{title}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="col-md-12">
								<b>{this.props.errorDescription}</b>
							</div>
							<div className="col-md-12">{this.props.errorMessage}</div>
							<div
								className="col-md-12 what-can-i-do-title"
								style={{ marginTop: 25 }}
							>
								<b>{why}</b>
							</div>
							<div className="col-md-12 what-can-i-do-title">
								{this._whenToShowRunFailedMessage()}
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button
								className="btn btn-s m-1 gradiantred"
								onClick={this._closeErrorModal}
							>
								{i18n.t('error_modal_run_error_btn')}
							</button>
						</Modal.Footer>
					</Modal>
				);
		}
	}

	render() {
		return <div>{this._renderErrorModal()}</div>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
