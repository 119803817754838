import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Spinner from 'react-bootstrap/Spinner';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

class About extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState({ loading: false });
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		if (this.state.loading) {
			return (
				<div id="page-content-wrapper">
					<Spinner variant="primary" animation="border" />
				</div>
			);
		}
		return (
			<div id="page-content-wrapper">
				<h1>Om Fortnox2Google</h1>
				<div className="row">
					<div className="col-md-6">
						<a href="https://fortnox.se/">
							<img
								className="about-logo"
								src={require('../../images/logo-fortnox.png')}
								alt='Fortnox logo'
							/>
						</a>
						<h3>Fortnox</h3>
						<p>
							Småföretagarens bästa vän - Fortnox erbjuder en molnbaserad
							plattform för ekonomiadministration för stora som små företag och
							organisationer. Det är enkelt, skalbart och utbyggbart med många
							integrationer.
						</p>
					</div>
					<div className="col-md-6">
						<a href="https://google.se/">
							<img
								className="about-logo"
								src={require('../../images/logo-google.png')}
								alt='Google logo'
							/>
						</a>
						<h3>Google Docs</h3>
						<p>
							Skapa, redigera och ändra dokument via Googles webbaserade
							verktyg. Enkelheten och tillgängligheten av Google Docs är
							svårslagen, det fungerar i vilken modern webbläsare som helst.
							Dela dokument fritt med vem som helst på ett säkert sätt.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<a href="https://newseed.se/">
							<img
								className="about-logo"
								src={require('../../images/logo-newseed.png')}
								alt='Newseed logo'
							/>
						</a>
						<h3>NewSeed®</h3>
						<p>
							Föreverkligar idéer - NewSeed utför webb-, app- och
							systemutveckling. Vi förde samman Fortnox och Google Docs på ett
							sätt så att du med enkelhet ska kunna administrera och analysera
							din egen information.
						</p>
						<p>
							Läs mer om integrationen på{' '}
							<a href="https://fortnox2google.newseed.se/">
								https://fortnox2google.newseed.se/
							</a>
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
