import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import TeamService from '../../services/TeamService';
import ExtractService from '../../services/ExtractService';
import { Modal } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eventBus from '../../services/EventBus';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';
import { messageTypes } from '../../services/MessageHandler';
import Pagination from '../Pagination';

//2 September:
//TODO 3 september: value=jobid och du kan skriva ut jobnamnet i option
//TODO 3 september: fixa så du kan
//REACT select för jobnames, ha value som extractid
//TODO: Gör

class Teams extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			teamData: [],
			data: [],
			createOrUpdateModalIsOpen: false,
			jobNameList: [],
			showConfirmDelete: false,
			ownerid: 0,
			teamName: [],
			gmail: '',
			selectValue: 'default',
			taskData: [],
			teamToDelete: 0,
			submitButtonCount: 0,
			teamNameUpdate: [],
			gmailUpdate: '',
			selectValueUpdate: 'defaultUpdate',
			selectedJobs: [],
			tags: [],
			tagsUpdate: [],
			team_x_userData: [],
			allMembersOfATeam: [],
			allTeamData: [],
			tagToRemove: [],
			forgotToPressEnter: [],
			teamsPerPage: 3,
			currentPage: 1,
		};
		this.changeHandler = this.changeHandler.bind(this);
		this.changeHandlerUpdate = this.changeHandler.bind(this);
		this.submitHandler = this.submitHandler.bind(this);
		this.submitHandlerUpdate = this.submitHandlerUpdate.bind(this);
		this.buttonClicked = this.buttonClicked.bind(this);
		this.openConfirmDeleteModal = this.openConfirmDeleteModal.bind(this);
		this.closeConfirmDeleteModal = this.closeConfirmDeleteModal.bind(this);
		this._closeCreateOrUpdateModal = this._closeCreateOrUpdateModal.bind(this);
		this._openCreateOrUpdateModal = this._openCreateOrUpdateModal.bind(this);
		this._confirmDeletion = this._confirmDeletion.bind(this);
		this.onChangePage = this.onChangePage.bind(this);

		//this.wsUpdateTeam = this.wsUpdateTeam.bind(this);
		//this.wsCreateTeam = this.wsCreateTeam.bind(this);
		this.inputRef = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState({
			isLoaded: true,
			data: this.props.executionLogDataFromExtract,
		});
		let that = this;
		this.fetchAllExtractTasks();
		this.fetchAllTeam_x_User();
		setTimeout(function () {
			that.fetchAllTeam_x_User();
		}, 2000);
		setTimeout(function () {
			that.fetchAllMembersOfATeam();
		}, 2000);
		setTimeout(function () {
			that.fetchAllTeamSpecificInfo();
		}, 2000);

		var promises = [];
		eventBus.on(messageTypes.TEAM_CREATE, this.wsCreateTeam);
		//eventBus.on(messageTypes.TEAM_UPDATE, this.wsUpdateTeam);
		promises.push(ExtractService.getExtractTasks(this.props.session));
	}

	componentDidUpdate(pP, pS) {
		if (this.state.submitButtonCount !== pS.submitButtonCount) {
			this.fetchAllTeamSpecificInfo();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		//eventBus.remove(messageTypes.TEAM_CREATE, this.wsCreateTeam);
	}

	buttonClicked(event) {
		this.setState({ submitButtonCount: this.state.submitButtonCount + 1 });
		let that = this;
		setTimeout(function () {
			that.fetchAllTeam_x_User();
		}, 1000);
	}

	wsCreateTeam = (data) => {
		const taskList = this.state.list;
		const isInList = taskList.some((t) => t.id === data.id);

		if (this._isMounted && !isInList) {
			this.setState({ list: [...taskList, data] });
		}
	};

	_confirmDeletion(event) {
		event.preventDefault();
		let that = this;
		let id = event.currentTarget.dataset.id;
		this.setState({
			showConfirmDelete: false,
			teamToDelete: id,
			submitButtonCount: this.state.submitButtonCount + 1,
		});
		this.deleteTeam(id).then((t) => {
			this.fetchAllTeam_x_User();
			setTimeout(() => {
				that.fetchAllTeamSpecificInfo();
			}, 500);
			//that.fetchAllTeamSpecificInfo();
			//TODO: Make this return a promise then fetch below data after it is finished
		});
	}

	fetchAllExtractTasks() {
		ExtractService.getExtractTasks(this.props.session)
			.then((taskResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					taskData: taskResponse,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	//*** Call these functions to get/create/update team obj data  *****
	fetchAllTeams() {
		let userid = this.props.session.userid;
		TeamService.getTeam(userid, this.props.session)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					teamData: dataFromResponse,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	fetchAllTeam_x_User() {
		return new Promise(() => {
			let userid = this.props.session.userid;
			TeamService.getTeam_x_User(userid, this.props.session)
				.then((dataFromResponse) => {
					if (!this._isMounted) {
						return;
					}
					this.setState({
						isLoaded: true,
						team_x_userData: dataFromResponse,
					});
				})
				.then(this.fetchAllTeamSpecificInfo())
				.catch((err) => {
					alert(err);
				});
		});
	}

	fetchAllMembersOfATeam(teamid) {
		TeamService.getTeamMembers(teamid, this.props.session)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					allMembersOfATeam: dataFromResponse,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	//Fetches team, members of team, extracttasks of team info
	fetchAllTeamSpecificInfo() {
		let dict = [];
		let promises = [];
		this.state.team_x_userData.forEach((t) => {
			promises.push(TeamService.getTeamExtractTasks(t.id, this.props.session));
			promises.push(TeamService.getTeamMembers(t.id, this.props.session));
			promises.push(t);
			promises.push(t.id);
		});
		Promise.all(promises)
			.then((dataFromResponse) => {
				for (let i = 0; i < dataFromResponse.length; i += 4) {
					dict[dataFromResponse[i + 3]] = {};
					dict[dataFromResponse[i + 3]].extractTasks = dataFromResponse[i];
					dict[dataFromResponse[i + 3]].members = dataFromResponse[i + 1];
					dict[dataFromResponse[i + 3]].team = dataFromResponse[i + 2];
				}
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					allTeamData: dict,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	//Post a team name to create a new team object
	createTeam(obj) {
		return TeamService.createTeam(obj, this.props.session);
	}

	updateTeam(teamid, obj) {
		TeamService.updateTeam(teamid, obj, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this.fetchAllTeam_x_User();
			})
			.catch((err) => {});
	}

	deleteTeam(id) {
		//takes team id to delete & session
		return TeamService.deleteTeam(id, this.props.session);
	}

	invUserToTeam(teamid, gmail) {
		TeamService.addUserToTeam(teamid, gmail, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this.fetchAllTeamSpecificInfo();
			})
			.catch((err) => {});
	}

	removeExtractTaskFromTeam(teamid, extractIdArray) {
		TeamService.deleteExtractTaskFromTeam(
			teamid,
			extractIdArray,
			this.props.session
		)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this.fetchAllTeamSpecificInfo();
			})
			.catch((err) => {});
	}

	removeUserFromTeam(teamid, gmailArray) {
		TeamService.deleteUserFromTeam(teamid, gmailArray, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this.fetchAllTeamSpecificInfo();
			})
			.catch((err) => {});
	}

	addExtractTaskToTeam(teamid, extractIdArray) {
		TeamService.addExtractTaskToTeam(teamid, extractIdArray, this.props.session)
			.then(() => {
				if (!this._isMounted) {
					return;
				}
				this.fetchAllTeamSpecificInfo();
			})
			.catch((err) => {});
	}

	//This checkbox handler puts every checked value in a state array
	handleCheckboxChange = (event) => {
		let newArray = [...this.state.selectedJobs, event.target.id];
		if (this.state.selectedJobs.includes(event.target.id)) {
			newArray = newArray.filter((job) => job !== event.target.id);
		}
		this.setState({
			selectedJobs: newArray,
		});
	};

	//**Handlers to submit user data from form */
	removeTag = (i) => {
		const newTags = [...this.state.tags];
		newTags.splice(i, 1);
		this.setState({ tags: newTags });
	};

	inputKeyDown = (e) => {
		const val = e.target.value;
		this.setState({
			forgotToPressEnter: this.tagInput.value,
		});
		if (e.key === 'Enter' || e.key === ' ' || (e.key === ',' && val)) {
			if (
				this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
			) {
				return;
			}
			this.setState({ tags: [...this.state.tags, val] });
			this.tagInput.value = null;
		} else if (e.key === 'Backspace' && !val) {
			this.removeTag(this.state.tags.length - 1);
		}
	};

	removeTagUpdate = (i) => {
		const newTags = [...this.state.tagsUpdate];
		let removedTag = this.state.tagsUpdate[i];
		newTags.splice(i, 1);
		this.setState({
			tagsUpdate: newTags,
			tagToRemove: removedTag,
		});
	};

	inputKeyDownUpdate = (e) => {
		const val = e.target.value;
		if (e.key === 'Enter' || e.key === ' ' || (e.key === ',' && val)) {
			if (
				this.state.tagsUpdate.find(
					(tag) => tag.toLowerCase() === val.toLowerCase()
				)
			) {
				return;
			}
			this.setState({ tagsUpdate: [...this.state.tagsUpdate, val] });
			this.tagInput.value = null;
		} else if (e.key === 'Backspace' && !val) {
			this.removeTag(this.state.tagsUpdate.length - 1);
		}
	};

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			selectValue: event.target.value,
		});
	};

	changeHandlerUpdate = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			selectValueUpdate: event.target.value,
		});
	};

	//Handles create team
	submitHandler = (event) => {
		event.preventDefault();
		let that = this;

		//Send in teamname to make POST req
		let teamNameObj = {
			teamName: this.state.teamName,
		};
		//this.wsCreateTeam();
		that
			.createTeam(teamNameObj)
			.then((team) => {
				//If user forgot to press enter when writing his tags,
				//then send the writings anyways
				let userForgotToPressEnter = [this.tagInput.value];
				if (userForgotToPressEnter === '') {
					let gmailObj = {
						gmail: [...this.state.tags],
					};
					that.invUserToTeam(team.id, gmailObj);
					// eslint-disable-next-line
				} else if (userForgotToPressEnter.length == 1) {
					let gmailObj = {
						gmail: [...this.state.tags, userForgotToPressEnter.join('')],
					};
					that.invUserToTeam(team.id, gmailObj);
				}

				let extractIdObj = {
					extractid: this.state.selectedJobs,
				};
				that.addExtractTaskToTeam(team.id, extractIdObj);
			})
			.then((t) => {
				that.fetchAllTeamSpecificInfo();
				that.fetchAllTeam_x_User();
			})
			.catch((err) => console.log(err, 'Error msg:'));
		//Re-render update and clear input form
		setTimeout(function () {
			//that.fetchAllTeam_x_User();
			document
				.querySelectorAll('input[type=checkbox]')
				.forEach((el) => (el.checked = false));
			that.setState({
				teamName: '',
				tags: [],
			});
		}, 1100);
	};

	submitHandlerUpdate = (event) => {
		event.preventDefault();
		let that = this;
		let teamid = this.state.teamToDelete;

		//Send in teamname to PUT request
		let teamNameObjUpdate = {
			teamName: this.state.teamNameUpdate,
		};
		that.updateTeam(teamid, teamNameObjUpdate);

		let addExtractTaskToNrs = this.state.selectedJobs.map((i) => Number(i));
		//Send extracttasks to PUT request
		let addExtractIdObj = {
			extractid: addExtractTaskToNrs,
		};
		that.addExtractTaskToTeam(teamid, addExtractIdObj);

		//Send extracttasks to DELETE request
		let A = this.state.taskData.map((item) => item.id);
		let B = this.state.selectedJobs;
		let C = B.map((i) => Number(i));
		let extractTasksToBeRemoved = A.filter((n) => !C.includes(n));

		let removeExtractIdObj = {
			extractid: extractTasksToBeRemoved,
		};
		that.removeExtractTaskFromTeam(teamid, removeExtractIdObj);

		//Send in gmail array to make POST req
		let gmailObj = {
			gmail: this.state.tagsUpdate,
		};
		that.invUserToTeam(teamid, gmailObj);

		//Send user gmail to DELETE request
		let removeGmailObj = {
			gmail: this.state.tagToRemove,
		};
		that.removeUserFromTeam(teamid, removeGmailObj);

		setTimeout(function () {
			that.fetchAllTeamSpecificInfo();
		}, 3000);
	};

	//**Render methods to render the modal and its contents */
	_openCreateOrUpdateModal(event) {
		let teamId = event.currentTarget.dataset.id;
		let stateName = event.currentTarget.dataset.order;
		let stateTag = event.target.attributes.getNamedItem('data-tag').value;
		let stateTagArr = stateTag.split(',');
		let stateCheckedJobs =
			event.target.attributes.getNamedItem('data-jobs').value;
		let stateCheckedJobsArr = stateCheckedJobs.split(',');

		if (stateCheckedJobsArr.includes('')) {
			stateCheckedJobsArr = [];
		}

		this.setState({
			createOrUpdateModalIsOpen: true,
			teamToDelete: teamId,
			teamNameUpdate: stateName,
			tagsUpdate: stateTagArr,
			selectedJobs: stateCheckedJobsArr,
		});
	}

	_closeCreateOrUpdateModal() {
		this.setState({
			createOrUpdateModalIsOpen: false,
		});
		let that = this;
		setTimeout(function () {
			that.fetchAllTeamSpecificInfo();
		}, 1100);
	}

	openConfirmDeleteModal(event) {
		let teamId = event.currentTarget.dataset.id;
		this.setState({
			showConfirmDelete: true,
			teamToDelete: teamId,
		});
	}

	closeConfirmDeleteModal() {
		this.setState({ showConfirmDelete: false });
		let that = this;
		setTimeout(function () {
			that.fetchAllTeamSpecificInfo();
		}, 1100);
	}

	renderEditTeamModal() {
		return (
			<Modal
				show={this.state.createOrUpdateModalIsOpen}
				onHide={this._closeCreateOrUpdateModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t('team_modal_title_edit')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-md-12">{this.renderUserInputFormUpdate()}</div>
				</Modal.Body>
				<Modal.Footer>
					<form className="form-group" onSubmit={this.submitHandlerUpdate}>
						<button
							className="btn btn-s m-1 gradiant"
							type="submit"
							onClick={this._closeCreateOrUpdateModal}
						>
							{i18n.t('team_modal_title_edit')}
						</button>
					</form>
				</Modal.Footer>
			</Modal>
		);
	}

	_renderConfirmDeleteModal() {
		return (
			<Modal
				show={this.state.showConfirmDelete}
				onHide={this.closeConfirmDeleteModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{i18n.t('team_delete_title')} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-md-12">
						<>{i18n.t('team_confirm_delete_msg')} </>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-s m-1 gradiantred"
						type="button"
						key="formbutton1"
						data-id={this.state.teamToDelete}
						onClick={this._confirmDeletion}
						value=""
					>
						{i18n.t('team_delete_btn')}
					</button>

					<button
						className="btn btn-s m-1 gradiant"
						type="button"
						key="formbutton2"
						onClick={this.closeConfirmDeleteModal}
						value=""
					>
						{i18n.t('team_cancel_btn')}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}

	//**Render the contents of the modal body */
	_renderYourCurrentTeamsData() {
		const indexOfLastPost = this.state.currentPage * 3;
		const indexOfFirstPost = indexOfLastPost - 3;
		const currentItems = this.state.allTeamData.slice(
			indexOfFirstPost,
			indexOfLastPost
		);

		let stylePosition = { position: 'absolute', bottom: -150, right: 120 };
		return (
			<div>
				{this.renderEditTeamModal()}
				{this._renderConfirmDeleteModal()}
				{currentItems.map((t) => (
					<table key={t.team.id} className="team-table-item">
						<thead></thead>
						<tbody className="tbody-team-item">
							<tr>
								<th className="gradiant"></th>
								<th className="gradiant"></th>
							</tr>
							<tr>
								<td>
									<b>{i18n.t('team_data_value_teamname')}</b>
									{}
								</td>
								<td>
									{' '}
									{t.team.teamname} ({t.team.id}){' '}
								</td>
							</tr>
							<tr>
								<td>
									<b>{i18n.t('team_data_value_creationdate')}</b>
								</td>
								<td>{t.team.creationdate}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={2}>
									<div className="team-members-name">
										<b>{i18n.t('team_members_title')}</b>{' '}
										{t.members.map((m) => m.email + ' ')}
									</div>
									<div className="team-members-tasks">
										<b>{i18n.t('team_tasks_title')}</b>{' '}
										{t.extractTasks.map((et) => et.jobname + ' ')}{' '}
									</div>

									<div className="team-members-owner">
										<b>Owner</b>
										{(() => {
											const owner = t.members.find(
												({ id }) => id === t.team.ownerid
											);
											return owner.email;
										})()}
									</div>

									{(() => {
										return (
											this.props.session.userid === t.team.ownerid && (
												<div>
													<div className="remove-team-btn">
														<button
															className="btn btn-s m-1 gradiantred"
															data-id={t.team.id}
															onClick={this.openConfirmDeleteModal}
														>
															<FontAwesomeIcon icon={faTrashAlt} />
														</button>
													</div>
													<div className="edit-team-btn">
														<button
															className="btn btn-s m-1 gradiant"
															type="button"
															key="updateTeams"
															data-id={t.team.id}
															data-order={t.team.teamname}
															data-tag={t.members.map((m) => m.email)}
															data-jobs={t.extractTasks.map((et) => et.id)}
															onClick={this._openCreateOrUpdateModal}
															value={i18n.t('resource_not_chosen')}
														>
															{i18n.t('team_edit_btn')}
														</button>
													</div>
												</div>
											)
										);
									})()}
								</td>
							</tr>
						</tfoot>
					</table>
				))}
				<Pagination
					itemsPerPage={this.state.teamsPerPage}
					totalItems={this.state.team_x_userData}
					currentPage={this.state.currentPage}
					paginate={this.onChangePage}
					stylePosition={stylePosition}
				/>
			</div>
		);
	}

	//**Render the contents of the create team user input form */
	renderUserInputForm() {
		let { teamName, taskData, tags } = this.state;
		//this.state.radioBtnGroup
		var radioBtnGroup = [];
		radioBtnGroup.push(
			taskData.map((jobData) => {
				return (
					<label key={jobData.id} className="add-task-label">
						<input
							className="add-task-to-team"
							type="checkbox"
							id={jobData.id}
							value={jobData.id}
							onChange={this.handleCheckboxChange}
						/>
						{jobData.jobname}
					</label>
				);
			})
		);

		return (
			<div>
				<form className="form-group" onSubmit={this.submitHandler}>
					<label htmlFor="">{i18n.t('team_modal_groupname_subtitle')}</label>
					<input
						className="form-control col-md-6 col-sm-12 col-lg-6"
						id="teamName"
						type="text"
						name="teamName"
						value={teamName}
						placeholder={i18n.t('team_modal_name_placeholder')}
						onChange={this.changeHandler}
					></input>
					<label htmlFor="">Gmail:</label>

					<div className="input-tag">
						<ul className="input-tag__tags">
							{tags.map((tag, i) => (
								<li key={tag}>
									{tag}
									<button
										type="button"
										onClick={() => {
											this.removeTag(i);
										}}
									>
										+
									</button>
								</li>
							))}
							<li className="input-tag__tags__input">
								<input
									type="text"
									placeholder={i18n.t('team_placeholder_gmail')}
									onKeyDown={this.inputKeyDown}
									ref={(c) => {
										this.tagInput = c;
									}}
									name="tags"
								/>
							</li>
						</ul>
					</div>
					<label htmlFor="">{i18n.t('team_modal_select_job')}</label>
					<br></br>
					<div className="add-task-container">{radioBtnGroup}</div>
				</form>
			</div>
		);
	}

	//**TODO: You can refactor this and use above function instead, no need to do 2 user inputforms
	//for seperate create & update forms
	renderUserInputFormUpdate() {
		let { teamNameUpdate, taskData, tagsUpdate } = this.state;
		let allInfo = this.state.allTeamData;
		//change to handlecheckboxchange UPdate
		var radioBtnGroup = [];
		radioBtnGroup.push(
			taskData.map((jobData) => {
				let toIntArray = this.state.selectedJobs.map((i) => Number(i));
				return (
					<label key={jobData.id} className="add-task-label">
						<input
							className="add-task-to-team"
							type="checkbox"
							id={jobData.id}
							checked={toIntArray.indexOf(jobData.id) !== -1}
							value={allInfo.map((t) => t.extractTasks.map((et) => et.id + ''))}
							onChange={this.handleCheckboxChange}
							data-name={allInfo.map((t) =>
								t.extractTasks.map((et) => et.id + '')
							)}
						/>
						{jobData.jobname}
					</label>
				);
			})
		);
		return (
			<div>
				<form
					className="form-group"
					id="create-form"
					onSubmit={this.submitHandlerUpdate}
				>
					<label htmlFor="">{i18n.t('team_modal_groupname_subtitle')}</label>
					<input
						className="form-control col-md-6 col-sm-12 col-lg-6"
						id="teamNameUpdate"
						type="text"
						name="teamNameUpdate"
						value={teamNameUpdate}
						placeholder={i18n.t('team_modal_name_placeholder')}
						onChange={this.changeHandlerUpdate}
					></input>

					<label htmlFor="">Gmail:</label>
					<div className="input-tag">
						<ul className="input-tag__tags">
							{tagsUpdate.map((tag, i) => (
								<li key={tag}>
									{tag}
									<button
										type="button"
										onClick={() => {
											this.removeTagUpdate(i);
										}}
									>
										+
									</button>
								</li>
							))}
							<li className="input-tag__tags__input">
								<input
									type="text"
									placeholder={i18n.t('team_placeholder_gmail')}
									onKeyDown={this.inputKeyDownUpdate}
									ref={(c) => {
										this.tagInput = c;
									}}
									name="tags"
								/>
							</li>
						</ul>
					</div>
					<label htmlFor="">{i18n.t('team_modal_select_job')}</label>
					<br></br>
					<div className="add-task-container">{radioBtnGroup}</div>
				</form>
			</div>
		);
	}

	onChangePage(page) {
		// update state with new page of items
		this.setState({ currentPage: page });
	}

	render() {
		return (
			<div>
				{this.renderUserInputForm()}
				<form
					className="form-group"
					id="create-form"
					onSubmit={this.submitHandler}
				>
					<button
						className="btn btn-s m-1 gradiant"
						type="submit"
						onClick={this.buttonClicked}
					>
						{i18n.t('team_modal_title')}
					</button>
				</form>
				{this._renderYourCurrentTeamsData()}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
