import React from 'react';
import i18n from '../services/i18n';

class Pagination extends React.Component {
	constructor(props) {
		super(props);
		this.state = { loading: false, currentActivePageId: 1 };
	}

	render() {
		const { itemsPerPage, totalItems, paginate, currentPage, stylePosition } =
			this.props;

		const pageNumbers = [];

		for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
			pageNumbers.push(i);
		}

		let styleDisableIfFirstPage =
			currentPage === 1
				? { backgroundColor: 'rgb(119 123 126 / 20%)', pointerEvents: 'none' ,color: '#8ea141'}
				: {color: '#8ea141'};
		let styleDisableIfLastPage =
			currentPage === pageNumbers.length
				? { backgroundColor: 'rgb(119 123 126 / 20%)', pointerEvents: 'none', color: '#8ea141' }
				: { backgroundColor: 'white' ,color: '#8ea141'};

		if (totalItems > 0 && totalItems > itemsPerPage) {
			return (
				<nav style={stylePosition}>
					<ul className="pagination">
						<li className="">
							<span
								className="page-link"
								style={styleDisableIfFirstPage}
								onClick={() => paginate(1)}
							>
							{i18n.t('first_page')}
							</span>
						</li>
						<li className="">
							<span
								className="page-link"
								style={styleDisableIfFirstPage}
								onClick={() => paginate(currentPage - 1)}
							>
							{i18n.t('previous_page')}
							</span>
						</li>

						{pageNumbers.map((number) => (
							<li
								key={number}
								className={
									currentPage === number ? 'page-item active' : 'page-item'
								}
							>
								<span
									onClick={() => paginate(number)}
									className="page-link active"
								>
									{number}
								</span>
							</li>
						))}

						<li key="next" className="">
							<span
								className="page-link"
								style={styleDisableIfLastPage}
								onClick={() => paginate(currentPage + 1)}
							>
							{i18n.t('next_page')}
							</span>
						</li>
						<li className="">
							<span
								className="page-link"
								style={styleDisableIfLastPage}
								onClick={() => paginate(pageNumbers.length)}
							>
							{i18n.t('last_page')}
							</span>
						</li>
					</ul>
				</nav>
			);
		}
		else return <div></div>;
	}
}

export default Pagination;
